/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { loadCharts } from "./utils/loadCharts";
import { formatDate } from "utils/formatDate";
import { apiCalls } from "./utils/apiCalls";

import { useStorePersist } from "store/context";
import { AuthService } from "services/AuthService";

import useRequest from "hooks/useRequest";
import { useBarChart } from "./hooks/UseBarChart";

import User from "models/User";

import ChartTitle from "components/atoms/ChartTitle";

import "./styles.scss";
import Filter from "./components/Filter";
import ChartWrapper from "components/organisms/ChartWrapper";
import { isMobile } from 'helpers/Mobile';
import RemotePagination from "components/organisms/RemotePagination";

const RankingView = () => {
  const [{}, dispatch] = useStorePersist();
  const history = useHistory();

  const currentUser = new User(AuthService.currentUserValue());
  const activeSubscription = currentUser?.activeSubscription();

  const [filter, setFilter] = useState(false);
  const [filterMode, setFilterMode] = useState(false);

  const [endDate, setEndDate] = useState(new Date("11/26/2021"));
  const [startDate, setStartDate] = useState(
    new Date(Date.parse("01/01/2016"))
  );

  const [asuntoFilter, setAsuntoFilter] = useState();
  const [asuntosFilter, setAsuntosFilter] = useState([]);
  const [materiaFilter, setMateriaFilter] = useState();
  const [materiasFilter, setMateriasFilter] = useState([]);
  const [procedimientoFilter, setProcedimientoFilter] = useState();
  const [procedimientosFilter, setProcedimientosFilter] = useState([]);
  const [provinciaFilter, setProvinciaFilter] = useState();
  const [provinciasFilter, setProvinciasFilter] = useState([]);
  const [cantonFilter, setCantonFilter] = useState();
  const [cantonsFilter, setCantonsFilter] = useState([]);
  const [dependenciaFilter, setDependenciaFilter] = useState();
  const [dependenciasFilter, setDependenciasFilter] = useState([]);
  const [cantAbogados, setCantAbogados] = useState(null);

  const yAxis = { title: "" }; // Cantidad

  const {
    optionsBarChart: optionsBarChartDep,
    setOptionsBarChart: setOptionsBarChartDep,
  } = useBarChart("", yAxis); // Ranking Abogados (top 10)

  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();

  const getErrorfromBack = (error) => {
    dealWithError(error, "Error");
    afterSubmit();
  };

  const statesLoadChart = {
    setAsuntosFilter: setAsuntosFilter,
    setMateriasFilter: setMateriasFilter,
    setProcedimientosFilter: setProcedimientosFilter,
    setProvinciasFilter: setProvinciasFilter,
    setCantonsFilter: setCantonsFilter,
    setDependenciasFilter: setDependenciasFilter,
    optionsBarChartDep: optionsBarChartDep,
    setOptionsBarChartDep: setOptionsBarChartDep,
    getErrorfromBack: getErrorfromBack,
    afterSubmit: afterSubmit,
  };

  useEffect(() => {
    (procedimientoFilter ||
      materiaFilter ||
      startDate ||
      endDate ||
      cantonFilter ||
      provinciaFilter ||
      dependenciaFilter) &&
      setFilterMode(false);
  }, [
    procedimientoFilter,
    materiaFilter,
    cantonFilter,
    startDate,
    endDate,
    provinciaFilter,
    dependenciaFilter,
  ]);

  useEffect(() => {
    filterMode && optionsBarChartDep && afterSubmit();
  }, [optionsBarChartDep]);

  useEffect(() => {
    setProcedimientoFilter("-1"); // reseteo filtro de materia
    setAsuntoFilter("-1"); // reseteo filtro de asunto
    apiCalls.getProcedimientosFilter(
      setProcedimientosFilter,
      materiaFilter === "-1" ? 0 : materiaFilter,
      getErrorfromBack
    );
  }, [materiaFilter]);

  useEffect(() => {
    setAsuntoFilter(); // reseteo filtro de asunto
    apiCalls.getAsuntosFilter(
      setAsuntosFilter,
      materiaFilter === "-1" ? 0 : materiaFilter,
      procedimientoFilter === "-1" ? 0 : procedimientoFilter,
      getErrorfromBack
    );
  }, [procedimientoFilter]);

  useEffect(() => {
    setCantonFilter("-1"); // reseteo filtro de asunto
    setDependenciaFilter("-1"); // reseteo filtro de dependencia
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    apiCalls.getCantonsFilter(setCantonsFilter, prov, getErrorfromBack);
  }, [provinciaFilter]);

  useEffect(() => {
    setDependenciaFilter("-1"); // reseteo filtro de asunto
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    const canton = cantonFilter === "-1" ? 0 : cantonFilter;
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      prov ? prov : 0,
      canton ? canton : 0,
      getErrorfromBack
    );
  }, [cantonFilter]);

  useEffect(() => {
    dispatch({
      type: "setBanner",
      payload: {
        title: "Ranking Abogados",
      },
    });

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }

    apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
    apiCalls.getProcedimientosFilter(
      setProcedimientosFilter,
      0,
      getErrorfromBack
    );
    apiCalls.getAsuntosFilter(setAsuntosFilter, 0, 0, getErrorfromBack);
    apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
    apiCalls.getCantonsFilter(setCantonsFilter, 0, getErrorfromBack);
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      0,
      0,
      getErrorfromBack
    );
  }, []);

  const doFilter = async (page = null, doesCount = false) => {
    beforeSubmit();
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      page: page || 1,
    };

    let promise;
    try {
      promise = await loadCharts({
        ...statesLoadChart,
        filters: newFilters,
      });
    } catch {
      promise = new Promise((resolve, reject) => reject());
    }
    setFilter(true);
    setFilterMode(true);
    if (doesCount) {
      apiCalls.getCountAbogados(
        setCantAbogados,
        newFilters,
        afterSubmit,
        getErrorfromBack
      );
    }

    return promise;
  };

  const chartsRanking = [
    {
      title: "Ranking Abogados (Top 10)",
      options: optionsBarChartDep,
      backgroundTitle: "#542f61",
      fontSize: 18,
      xs: 12,
    },
  ];

  return (
    <div className="caso-container caso-container-size">
      <Filter
        asuntoFilter={asuntoFilter}
        asuntosFilter={asuntosFilter}
        cantonFilter={cantonFilter}
        cantonsFilter={cantonsFilter}
        dependenciasFilter={dependenciasFilter}
        dependenciaFilter={dependenciaFilter}
        doFilter={() => doFilter(1, true)}
        endDate={endDate}
        materiaFilter={materiaFilter}
        materiasFilter={materiasFilter}
        procedimientoFilter={procedimientoFilter}
        procedimientosFilter={procedimientosFilter}
        provinciaFilter={provinciaFilter}
        provinciasFilter={provinciasFilter}
        setAsuntoFilter={setAsuntoFilter}
        setCantonFilter={setCantonFilter}
        setEndDate={setEndDate}
        setMateriaFilter={setMateriaFilter}
        setProcedimientoFilter={setProcedimientoFilter}
        setProvinciaFilter={setProvinciaFilter}
        setStartDate={setStartDate}
        startDate={startDate}
        setDependenciaFilter={setDependenciaFilter}
      />
      {filter && cantAbogados !== null && (
        <div className={"mt-4 pt-4 mb-4"}>
          <ChartTitle
            title={"Análisis estadístico de resolución"}
            fontSize={isMobile ? 14 : 24}
            showLine
            textTransform={"uppercase"}
            customClass={"mb-4"}
          />
          <ChartWrapper charts={chartsRanking} />
          {cantAbogados ? (
            <div className="container-pagination">
              <RemotePagination
                totalPages={Math.ceil(cantAbogados / 10)}
                onPageChanged={doFilter}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default RankingView;
