export const formatDate = (date) => {
  var d = new Date(date),
    day = "" + d.getDate(),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear();
  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return [year, month, day].join("-");
};


export const formatDateYYYMMMDDD = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};

