import { useState } from "react";

export const useStackedColumChart = (title, yAxis) => {
  const [optionsStackedColumnChart, setOptionsStackedColumnChart] = useState({
    chart: {
      type: "column",
    },
    title: {
      text: null
    },
    xAxis: {
      categories: ["Femenino", "Masculino"],
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [],
  });

  return { optionsStackedColumnChart, setOptionsStackedColumnChart };
};
