import React, { useEffect, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { AuthService } from "services/AuthService";

import SpinnerJudit from "components/atoms/SpinnerJudit";
import Footer from "components/molecules/Footer";
import Menu from "components/molecules/Menu";

import "./styles.scss";
import Banner from "components/molecules/Banner";
import { useHistory } from "react-router-dom";
import User from "models/User";

const Layout = ({ children }) => {
  const history = useHistory();

  const currentUser = new User(AuthService.currentUserValue());

  useLayoutEffect(() => {
    if (!currentUser) {
      history.replace("/login");
    }

    AuthService.loadUser().catch((error) => {
      AuthService.logout();
      history.push("/login");
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"layout-container"}>
      <div className="header-container">
        <Menu />
        <Banner />
      </div>
      <div className={`main-container`}>{children}</div>
      <div className="footer-container">
        <Footer />
      </div>
      <SpinnerJudit />
    </div>
  );
};

export default withTranslation()(Layout);
