import Environment from "environment";

import Api from "services/ApiService";

export const DataService = {
  getProcess: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/`,
      "GET",
      params
    );
    return dataResult;
  },
  getIndicadores: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/indicadores/`,
      "GET"
    );
    return dataResult;
  },
  getProcessDetalles: async (idProcess, page) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/${idProcess}/detalles/`,
      "GET",
      page
    );
    return dataResult;
  },
  getProcessPerProvinces: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/provinces/`,
      "GET"
    );
    return dataResult;
  },
  getProcessPerYear: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year/`,
      "GET"
    );
    return dataResult;
  },
  getProcessPerSubject: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-subject/`,
      "GET"
    );
    return dataResult;
  },
  getPerEvolutionYear: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-evolution-year/`,
      "GET"
    );
    return dataResult;
  },
  getProcessPerAverageDurationSubjectYear: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-average-duration-subject-year/`,
      "GET"
    );
    return dataResult;
  },
  getProcessPerEvolutionYearSentencias: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-evolution-year-sentencias/`,
      "GET"
    );
    return dataResult;
  },
  getAsuntosOrdenados: async () => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/asuntos/ordenados/`,
      "GET"
    );
    return dataResult;
  },

  getActuacionesDetalles: async (idDetalle, page) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/actuaciones/detalles/${idDetalle}/`,
      "GET",
      page
    );
    return dataResult;
  },
  getDurationMedia: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-media/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMax: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-max/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMin: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-min/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMediaNacional: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-media/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMaxNacional: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-max/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMinNacional: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-min/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMediaJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-media-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMaxJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-max-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMinJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-min-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMediaNacionalJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-media-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMaxNacionalJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-max-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMinNacionalJuzgado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-nacional-min-juzgado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getSentenciasRecurridas: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/sentencias-recurridas/`,
      "GET",
      params
    );
    return dataResult;
  },
  getRecursosEstimados: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/recursos-estimados/`,
      "GET",
      params
    );
    return dataResult;
  },
  getSentidoFallo: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/sentido-fallo/`,
      "GET",
      params
    );
    return dataResult;
  },

  getTemaFalloTop5: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/temas-fallo-top-5/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerProvincesCaso: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/provinces-caso/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerYearCaso: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year-caso/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDependenciasTop5: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/dependencias-top5/`,
      "GET",
      params
    );
    return dataResult;
  },
  getMagistradosTop5: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/magistrados-top5/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerYearState: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year-state/`,
      "GET",
      params
    );
    return dataResult;
  },

  getProcessPerYearMagis: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year-magis/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerFailureMagis: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-failure-magis/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerYearStateDependencia: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year-state-dependencia/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerEvolutionYearDependencia: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-evolution-year-dependencia/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerAsuntoStateDependencia: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-asunto-state-dependencia/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDurationMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/duration-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getSentidoFalloMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/sentido-fallo-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getSentenciasRecurridasMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/sentencias-recurridas-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getRecursosEstimadosMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/recursos-estimados-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getTemaFalloTop5Magistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/tema-fallo-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerYearMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-year-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerEvolutionYearMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-evolution-year-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcessPerAsuntoStateMagistrado: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procesos/per-asunto-state-magistrado/`,
      "GET",
      params
    );
    return dataResult;
  },
  getJuez: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/magistrado/juez/`,
      "GET",
      params
    );
    return dataResult;
  },
  getHojaRuta: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/magistrado/hoja-ruta/`,
      "GET",
      params
    );
    return dataResult;
  },
  getResoluciones: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/magistrado/resoluciones/`,
      "GET",
      params
    );
    return dataResult;
  },
  getAsuntosFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/asuntos/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getMateriasFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/materias/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProcedimientosFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/procedimientos/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getProvinciasFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/provincias/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getCantonsFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/cantons/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getDependenciasFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/dependencias/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getJuecesFilter: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/jueces/filtros/`,
      "GET",
      params
    );
    return dataResult;
  },
  getRankingLawyers: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/ranking/abogados/`,
      "GET",
      params
    );
    return dataResult;
  },
  getCountAbogados: async (params) => {
    const dataResult = await Api.fetch(
      `${Environment.api}api/data-service/ranking/count-abogados/`,
      "GET",
      params
    );
    return dataResult;
  },
};
