import React from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";

const LoginHeader = ({ title = "Inicio de sesión" }) => {
  const history = useHistory();
  return (
    <div className={"login-header-container"}>
      <img
        className={"login-header-image"}
        src={require("../../../assets/images/judit.svg")}
        alt={"Logo JudIT"}
        onClick={() => history.push("/home")}
      />
      <span className={"login-header-title"}>{title}</span>
    </div>
  );
};

export default LoginHeader;
