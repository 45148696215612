import React from "react";
import "./styles.scss";
const Box = ({ children, maxWidth = false, customClass }) => {
  return (
    <div
      className={`box-container ${maxWidth ? "box-max-width" : ""} ${
        customClass || ""
      }`}
    >
      {children}
    </div>
  );
};

export default Box;
