import { DataService } from "services/DataService";
var _ = require("lodash");
const sexOrder = {
  M: 0,
  F: 1,
};

/**
 *
 * BORRAR COMENTARIO CUANDO SE AGREGUE EL FILTRADO
 * @todo FALTA AGREGAR LOS FILTROS A CADA API CALL Y DESPUES ENVIARLO AL SERVICIO Ejemplo de como quedaria con los filtros
 *
 * getProvincesCaso:(mapOptions, setMapOptions, getErrorfromBack, filters) => {
 *    DataService.getProcessPerProvincesCaso(filters).then((data)=>{...}).catch((error)=>{...})
 * }
 *
 */
export const apiCalls = {
  getRankingLawyers: (
    optionsStackedColumnChart,
    setOptionsStackedColumnChart,
    getErrorfromBack,
    filters
  ) => {
    return DataService.getRankingLawyers(filters)
      .then((data) => {
        let abogados = data
          .filter((d) => d.Abogado !== "" && d.Abogado != null)
          .map((d) => d.Abogado.replace(";", ""));
        let cantidades = data
          .filter((d) => d.Abogado !== "" && d.Abogado != null)
          .map((d) => d.cantidad);
        setOptionsStackedColumnChart({
          ...optionsStackedColumnChart,
          xAxis: {
            categories: abogados,
            title: {
              text: null,
            },
          },
          series: [
            {
              name: "Casos",
              data: cantidades,
            },
          ],
        });
        return new Promise((resolve, reject) => resolve(true));
      })
      .catch((error) => {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      });
  },
  getAsuntosFilter: (
    setAsuntosFilter,
    materiaFilter,
    procedimientoFilter,
    getErrorfromBack
  ) => {
    const filters = {};
    materiaFilter && Object.assign(filters, { materia: materiaFilter });
    procedimientoFilter &&
      Object.assign(filters, { procedimiento: procedimientoFilter });
    DataService.getAsuntosFilter(filters)
      .then((data) => {
        setAsuntosFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getMateriasFilter: (setMateriasFilter, getErrorfromBack) => {
    DataService.getMateriasFilter()
      .then((data) => {
        setMateriasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcedimientosFilter: (
    setProcedimientosFilter,
    materiaFilter,
    getErrorfromBack
  ) => {
    const filters = {};
    materiaFilter && Object.assign(filters, { materia: materiaFilter });
    DataService.getProcedimientosFilter(filters)
      .then((data) => {
        setProcedimientosFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProvinciasFilter: (setProvinciasFilter, getErrorfromBack) => {
    DataService.getProvinciasFilter()
      .then((data) => {
        setProvinciasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getCantonsFilter: (setCantonsFilter, provinciaFilter, getErrorfromBack) => {
    const filters = {};
    provinciaFilter && Object.assign(filters, { provincia: provinciaFilter });
    DataService.getCantonsFilter(filters)
      .then((data) => {
        setCantonsFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDependenciasFilter: (
    setDependenciasFilter,
    provincia,
    canton,
    getErrorfromBack
  ) => {
    const filters = {};
    provincia && Object.assign(filters, { provincia: provincia });
    canton && Object.assign(filters, { canton: canton });
    DataService.getDependenciasFilter(filters)
      .then((data) => {
        setDependenciasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getJuecesFilter: (setJuecesFilter, getErrorfromBack) => {
    DataService.getJuecesFilter()
      .then((data) => {
        setJuecesFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getCountAbogados: (
    setCantAbogados,
    filters,
    afterSubmit,
    getErrorfromBack
  ) => {
    DataService.getCountAbogados(filters)
      .then((data) => {
        setCantAbogados(data.length);
        afterSubmit();
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
};
