import { useState } from "react";

function getPointCategoryName(point, dimension) {
  var series = point.series,
    isY = dimension === "y",
    axis = series[isY ? "yAxis" : "xAxis"];
  return axis.categories[point[isY ? "y" : "x"]];
}

export const useBubbleChartSentencias = () => {
  const [
    optionsBubbleChartSentencias,
    setOptionsBubbleChartSentencias,
  ] = useState({
    chart: {
      type: "heatmap",
      marginTop: 40,
      marginBottom: 80,
      plotBorderWidth: 1,
    },

    title: {
      text: null,
    },

    xAxis: {
      categories: [],
    },

    yAxis: {
      categories: [],
      title: null,
      reversed: true,
    },

    accessibility: {
      point: {
        descriptionFormatter: function(point) {
          var ix = point.index + 1,
            xName = getPointCategoryName(point, "x"),
            yName = getPointCategoryName(point, "y"),
            val = point.value;
          return ix + ". " + xName + " sales " + yName + ", " + val + ".";
        },
      },
    },

    colorAxis: {
      min: 0,
      minColor: "#FFFFFF",
      maxColor: "#7cb5ec",
    },

    legend: {
      align: "right",
      layout: "vertical",
      margin: 0,
      verticalAlign: "top",
      y: 25,
      symbolHeight: 280,
    },

    tooltip: {
      formatter: function() {
        return (
          "<b>" +
          getPointCategoryName(this.point, "y") +
          "</b> <br>" +
          "año: <b>" +
          getPointCategoryName(this.point, "x") +
          "</b><br>" +
          "cantidad: <b>" +
          this.point.value +
          "</b>"
        );
      },
    },

    series: [
      {
        borderWidth: 1,
        data: [],
        dataLabels: {
          enabled: true,
          color: "#000000",
        },
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            yAxis: {
              labels: {
                formatter: function() {
                  return this.value.charAt(0);
                },
              },
            },
          },
        },
      ],
    },
  });

  return { optionsBubbleChartSentencias, setOptionsBubbleChartSentencias };
};
