import { useState } from "react";

export const usePieChartMagistrado = () => {
  const [optionsPieChartMagistrado, setOptionsPieChartMagistrado] = useState({
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      height: 290,
      width: 500,
    },
    title: {
      text: "", //"Magistrados",
    },
    tooltip: {
      pointFormat: "{point.y}",
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y} ",
          connectorColor: "silver",
        },
      },
    },
    series: [],
  });

  return { optionsPieChartMagistrado, setOptionsPieChartMagistrado };
};
