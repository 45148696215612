import React from "react";
import "./styles.scss";
import Chart from "../../molecules/Chart";
import ChartContainer from "../ChartContainer";
import ChartTitle from "../../atoms/ChartTitle";
import MapChart from "../../molecules/MapChart";
import { Col, Container, Row } from "react-bootstrap";
import { isMobile } from 'helpers/Mobile';

const ChartWrapper = ({
  title,
  charts = [],
  ChartComponents = null,
  chartTitle = {},
  customClass,
}) => {
  return (
    <Container fluid style={{ marginBottom: "30px" }} className={customClass}>
      {title || Object.keys(chartTitle).length ? (
        <ChartTitle fontSize={20} title={title} {...chartTitle} />
      ) : (
        ""
      )}
      <Row md={12}>
        {charts?.map((chart, index) => {
          const { ChartCustomComponent } = chart || {};
          return (
            <Col md={chart?.xs} xs={12} className={isMobile ? "mt-3" : ""} key={index}>
              {ChartCustomComponent ? (
                <ChartCustomComponent />
              ) : (
                <ChartContainer
                  title={chart?.title}
                  key={index}
                  childrenClassName={"p-3"}
                  footerText={chart.footerText}
                  backgroundTitle={chart?.backgroundTitle}
                  fontFamily={"YaroCut"}
                >
                  {chart.chart ? (
                    <chart.chart {...chart} />
                  ) : (
                    <Chart {...chart} />
                  )}
                </ChartContainer>
              )}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ChartWrapper;
