import { useState } from "react";

export const useBubbleChartMagYear = () => {
  const [optionsBubbleChartMagYear, setOptionsBubbleChartMagYear] = useState({
    chart: {
      type: "bar",
    },

    legend: {
      enabled: true,
    },

    title: {
      text: "", //Evolución de asusntos por año/sexo magistrado
    },
    plotOptions: {
      series: {
        stacking: 'normal'
      }
    },
    yAxis: {
      title: {
        text: null
      },
      labels: {
        formatter: function () {
          return Math.abs(this.value);
        }
      },
      accessibility: {
        description: 'Cantidad',
      }
    },

    tooltip: {
      formatter: function () {
        return '<b>' + this.series.name + ', Año ' + this.point.category + '</b><br/>' +
          'Cantidad: ' + Math.abs(this.point.y);
      }
    },

    series: [
      {
        data: [],
      },
    ],
    credits: false,
  });

  return { optionsBubbleChartMagYear, setOptionsBubbleChartMagYear };
};
