import { DataService } from "../services/DataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import Environment from "../environment";
import React from "react";
import useRequest from "./useRequest";

export const useOpenDetail = () => {
  const { afterSubmit, beforeSubmit } = useRequest();

  const handleOpenDetail = async ({
    event,
    p,
    setProcessActual,
    setProcessDetalle,
    handleOpen,
    getErrorfromBack,
  }) => {
    event.preventDefault();
    beforeSubmit();
    handleOpen();
    await DataService.getProcessDetalles(p.Proceso)
      .then((data) => {
        setProcessActual(p.Proceso);
        const datosDetalle = data.map((d) => {
          return {
            id: d.id,
            proceso: d.proceso,
            no_ingreso: d.no_ingreso,
            actor_ofendido: d.actor_ofendido,
            demandado_procesado: d.demandado_procesado,
            ciudad: d.ciudad,
            dependencia_juridiccional: d.dependencia_juridiccional,
            fecha: d.fecha,
            pdf: (
              <FontAwesomeIcon
                icon={faDownload}
                style={{ cursor: "pointer",color: "#542F61" }}
                fontSize={20}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    `${Environment.api}api/data-service/procesos/${d.id}/detalles/reporte/`,
                    "_blank"
                  );
                }}
              />
            ),
          };
        });
        setProcessDetalle(datosDetalle);
        afterSubmit();
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  };

  return {
    handleOpenDetail,
  };
};
