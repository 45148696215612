import React from "react";
import { Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import TimeLineChart from "components/molecules/TimeLineChart";
import "./styles.scss";
import ChartContainer from "components/organisms/ChartContainer";
import Input from "../../../../../components/atoms/Input";
import Chart from "../../../../../components/molecules/Chart";

const formatNumberInput = (value) => {
  //1.222.333
  const number = value.replace(/\./g, "");
  const numberFormated = number.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numberFormated;
};
const List = ({ item }) => {
  return (
    <ListGroup.Item
      style={{
        width: "342px",
        border: "0px",
        borderBottom: "2px solid #7CCEC0",
        borderRadius: "0%",
        color: "#707070",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontFamily: "normal normal normal 18px/22px Lato",
          fontSize: "18px",
        }}
      >
        {item?.title}
      </div>
      <div style={{ fontSize: "20px" }}>{formatNumberInput(item?.number)}</div>
    </ListGroup.Item>
  );
};

const MagistradosAnalyticContainer = ({
  title = "Magistrados",
  indicadores,
  options,
}) => {
  const listObj = [
    {
      title: "Procesos",
      number: `${indicadores?.Procesos}`,
    },
    {
      title: "Actuaciones",
      number: `${indicadores?.Actuaciones}`,
    },
    {
      title: "Asuntos",
      number: `${indicadores?.Asuntos}`,
    },
    {
      title: "Dependencias",
      number: `${indicadores?.Dependencias}`,
    },
    {
      title: "Materias",
      number: `${indicadores?.Materias}`,
    },
    {
      title: "Procedimientos",
      number: `${indicadores?.Procedimientos}`,
    },
  ];

  return (
    <ChartContainer
      childrenClassName={"justify-content-center align-items-center"}
      title={"Indicadores Generales"}
      backgroundTitle={"#18A48C"}
    >
      <Row style={{ paddingTop: "48px" }}>
        <Col md={5} xs={12} style={{ paddingLeft: "28px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListGroup>
              {listObj?.map((item, i) => (
                <List key={i} item={item} />
              ))}
            </ListGroup>
          </div>
        </Col>
        <Col md={7} xs={12}>
          <Row>
            <Col md={12}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontFamily: "normal normal bold 20px/23px Yaro Cut",
                }}
              >
                {title}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div
                style={{
                  marginBottom: "62px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Chart options={options} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ChartContainer>
  );
};

export default MagistradosAnalyticContainer;
