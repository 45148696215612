import React from "react";

import useRequest from "hooks/useRequest";

import juditLogo from "assets/images/judit.svg";

import Spinner from "react-bootstrap/Spinner";

import "./styles.scss";

const SpinnerJudit = () => {
  const { loadingSpinner } = useRequest();

  return (
    <div
      id="spinner-container"
      className={
        loadingSpinner ? "spinner-container show" : "spinner-container hide"
      }
    >
      <Spinner animation="border" role="status" />
      <img src={juditLogo} alt="juditLogo" />
    </div>
  );
};

export default SpinnerJudit;
