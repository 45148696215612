import { useState } from "react";

export const useFixedPlacementColumns = () => {
  const [min, setMin] = useState(0);
  const [med, setMed] = useState(0);
  const [max, setMax] = useState(0);

  const [minNacion, setMinNacion] = useState(0);
  const [medNacion, setMedNacion] = useState(0);
  const [maxNacion, setMaxNacion] = useState(0);

  const optionsFixedPlacementColumns = {
    chart: {
      type: "column",
    },
    title: null,
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ["Tiempo mínimo", "Tiempo medio", "Tiempo máximo"],
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: "Días",
        },
      },
      {
        title: {
          text: "Días Nación",
        },
        opposite: true,
      },
    ],
    legend: {
      shadow: false,
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Días",
        color: "rgba(165,170,217,1)",
        data: [min, med, max],
        pointPadding: 0.3,
        pointPlacement: -0.2,
      },
      {
        name: "Días Nación",
        color: "rgba(126,86,134,.9)",
        data: [minNacion, medNacion, maxNacion],
        pointPadding: 0.4,
        pointPlacement: -0.2,
      },
    ],
  };

  const resetValues = () => {
    setMin(0);
    setMed(0);
    setMax(0);
    setMinNacion(0);
    setMedNacion(0);
    setMaxNacion(0);
  };

  return {
    optionsFixedPlacementColumns,

    min,
    setMin,
    med,
    setMed,
    max,
    setMax,
    minNacion,
    setMinNacion,
    medNacion,
    setMedNacion,
    maxNacion,
    setMaxNacion,

    resetValues,
  };
};
