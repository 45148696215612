import { useRef } from "react";
import Environment from "environment";

export const useFacebookAuth = () => {
  const formFacebookRef = useRef(null);
  const facebookAuthUrl = `${Environment.api}api/auth/social/facebook/login/`;
  const handleClickFacebookAuth = (e) => formFacebookRef.current.submit();

  const facebookAuth = {
    handleClickFacebookAuth,
    formFacebookRef,
    facebookAuthUrl,
  };

  return { formFacebookRef, facebookAuth };
};
