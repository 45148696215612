import React, { useEffect } from "react";
import "./styles.scss";
import { useStorePersist } from "store/context";
import Search from "./../../atoms/Search";

const Banner = (props) => {
  const [{ banner }, dispatch] = useStorePersist();

  useEffect(() => {
    const defaultBanner = {
      color: "success",
      title: "Bienvenido a JudIT!",
      hide: false,
    };
    if (!banner) {
      dispatch({ type: "setBanner", payload: defaultBanner });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`banner-expire banner-${banner?.color || "success"}-bg ${
        banner?.hide ? "hide" : "show"
      }`}
    >
      <div>
        <p className="banner-expire-title">{banner?.title}</p>
      </div>
      <div className={"banner-search"}>
        <Search />
      </div>
    </div>
  );
};

export default Banner;
