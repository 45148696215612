import React, { useState } from "react";

import { withTranslation } from "react-i18next";

import { AuthService } from "services/AuthService";

import { Alert } from "react-bootstrap";

import CardButton from "components/molecules/CardButton";
import Box from "components/organisms/Box";
import Modal from "components/molecules/Modal";

import BodyProfile from "./components/BodyProfile";
import BodySubscription from "./components/BodySubscription";

import "./styles.scss";
import BodyChangePassword from "./components/BodyChangePassword";

const Profile = ({
  buttonProfile,
  buttonSubscription,
  buttonChangePassword,
  bodyChangePassword,
  bodySubscription,
  bodyProfile,
  cardActive,
  activeSubscription,
  inputField,
  modal,
  handleResendVerificationMail,
}) => {
  const currentUser = AuthService.currentUserValue();
  const [show, setShow] = useState(true);

  const emailConfirmed = currentUser.is_confirmed;

  const bodyAlertConfirmEmail = (
    <>
      Debe confirmar su correo electronico. Si no ha recibido el correo de
      confirmación{" "}
      <span
        onClick={handleResendVerificationMail}
        className={"card-profile-link"}
      >
        {"puede solicitarlo nuevamente."}
      </span>
    </>
  );

  return (
    <div className={"profile-container"}>
      <div className={"profile-menu"}>
        <CardButton {...buttonProfile} />
        <CardButton {...buttonSubscription} />
        <CardButton {...buttonChangePassword} />
      </div>
      <div className={"profile-card"}>
        <Box customClass={"profile-box"}>
          <div className={"profile-subscription-container"}>
            {!emailConfirmed && (
              <Alert
                variant={"warning"}
                className={"profile-alert"}
                onClose={() => setShow(false)}
                show={show}
                dismissible
              >
                {bodyAlertConfirmEmail}
              </Alert>
            )}
            {bodyProfile && cardActive === bodyProfile.name && (
              <BodyProfile bodyProfile={bodyProfile} />
            )}
            {bodySubscription && cardActive === bodySubscription.name && (
              <BodySubscription
                bodySubscription={bodySubscription}
                activeSubscription={activeSubscription}
              />
            )}
            {bodyChangePassword && cardActive === bodyChangePassword.name && (
              <BodyChangePassword bodyChangePassword={bodyChangePassword} />
            )}
          </div>
        </Box>
        <Modal {...modal} inputField={inputField} />
      </div>
    </div>
  );
};

export default withTranslation()(Profile);
