import React from "react";

import "./styles.scss";

const ChartTitle = ({
  title,
  margin = 4,
  padding = 4,
  fontSize = 18,
  align = "center",
  color = "#2B2B2BAA",
  textTransform = "unset",
  opacity = "1",
  showLine = false,
  subtitle,
  fontFamily = "Montserrat",
  customClass,
}) => {
  const divStyle = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  };
  return (
    <div className={customClass}>
      <div style={showLine ? divStyle : {}}>
        {showLine && (
          <hr
            style={{
              width: "96%",
              height: "0px",
              border: "3px solid #B897C4",
              opacity: "1",
              position: "absolute",
            }}
          />
        )}
        <p
          className={`mt-${margin} mb-${margin} pt-${padding} pb-${padding} graphic-title text-${align} `}
          style={{ fontSize: fontSize, color, zIndex: 1 }}
        >
          <span
            style={{
              textTransform: textTransform,
              background: showLine ? "white" : "",
              padding: showLine ? "0 32px" : "0",
              fontFamily,
            }}
          >
            {title}
          </span>
        </p>
      </div>
      {subtitle && (
        <div className={"graphic-subtitle-container"}>
          <p className={"graphic-chart-subtitle"}>
            <span>{subtitle}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ChartTitle;
