import { useRef } from "react";
import Environment from "environment";

export const useGoogleAuth = () => {
  const formGoogleRef = useRef(null);
  const googleAuthUrl = `${Environment.api}api/auth/social/google/login/`;
  const handleClickGoogleAuth = (e) => formGoogleRef.current.submit();

  const googleAuth = {
    handleClickGoogleAuth,
    formGoogleRef,
    googleAuthUrl,
  };

  return { formGoogleRef, googleAuth };
};
