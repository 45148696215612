import { useState } from "react";
import Highcharts from "highcharts";

export const useColumnChart = (title, yAxis) => {
  const [optionsColumnChart, setOptionsColumnChart] = useState({
    credits: false,
    chart: {
      type: "column",
    },
    title: {
      text: title,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxis.title,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            // theme
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
      },
    },
    legend: {
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },

    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [],
  });

  return { optionsColumnChart, setOptionsColumnChart };
};
