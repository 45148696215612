import React from "react";

import { Alert } from "react-bootstrap";

import DetailModal from "components/molecules/DetailModal";
import RemoteTable from "components/molecules/RemoteTable";

import "./styles.scss";
import ChartTitle from "components/atoms/ChartTitle";

const ProcessTable = ({
  processDetalle,
  processActual,
  handleCloseModal,
  cantProcess,
  data,
  page,
  sizePerPage,
  handleTableChange,
  show,
}) => {
  const columns = [
    { dataField: "FechaIngreso", text: "Fecha Ingreso", sort: true },
    { dataField: "Proceso", text: "N° Proceso", sort: true },
    { dataField: "AccionInfraccion", text: "Acción/Infracción", sort: true },
    { dataField: "pdf", text: "Detalle" },
  ];

  return (
    <div className="container-table mt-5">
      {processDetalle && (
        <DetailModal
          title={`Movimientos del proceso N°: ${processActual}`}
          open={show}
          customClass={"detalle-modal"}
          handleClose={handleCloseModal}
          data={processDetalle}
        />
      )}
      <ChartTitle
        title={`Procesos encontrados: ${cantProcess}`}
        fontSize={24}
        textTransform={"uppercase"}
        showLine={true}
        margin={4}
        padding={4}
        customClass={"mt-4 pt-4 mb-4 pb-4"}
      />
      <RemoteTable
        data={data}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={cantProcess}
        onTableChange={handleTableChange}
        columns={columns}
      />
    </div>
  );
};

export default ProcessTable;
