import { DataService } from "services/DataService";
import _ from "lodash";

const sexOrder = {
  M: 0,
  F: 1,
};

/**
 *
 * BORRAR COMENTARIO CUANDO SE AGREGUE EL FILTRADO
 * @todo FALTA AGREGAR LOS FILTROS A CADA API CALL Y DESPUES ENVIARLO AL SERVICIO Ejemplo de como quedaria con los filtros
 *
 * getProvincesCaso:(mapOptions, setMapOptions, getErrorfromBack, filters) => {
 *    DataService.getProcessPerProvincesCaso(filters).then((data)=>{...}).catch((error)=>{...})
 * }
 *
 */
export const apiCalls = {
  getProvincesCaso: (mapOptions, setMapOptions, getErrorfromBack, filters) => {
    DataService.getProcessPerProvincesCaso(filters)
      .then((data) => {
        const array = mapOptions;
        array.series[0].data.map((d) => {
          d.value = null;
        });
        data.forEach((dato) => {
          array.series[0].data.map((d) => {
            if (d.name === _.startCase(_.toLower(dato.Provincia))) {
              d.value = dato.cantidad;
            }
          });
        });
        setMapOptions({
          ...mapOptions,
          series: [{ ...mapOptions.series[0], data: array.series[0].data }],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getPorcessPerYear: async (
    optionsPackedBubble,
    setOptionsPackedBubble,
    getErrorfromBack,
    filters
  ) => {
    await DataService.getProcessPerYearMagistrado(filters)
      .then((data) => {
        setOptionsPackedBubble({
          ...optionsPackedBubble,
          series: data.map((process) => {
            return {
              name: process.year,
              data: [
                {
                  name: process.year,
                  value: process.cantidad,
                },
              ],
            };
          }),
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getWordCloudAsuntosMasTratados: (
    wordCloudAsuntos,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerEvolutionYearDependencia(filters)
      .then((data) => {
        const d = data.map((process) => ({
          name: `${process.Asunto} (${process.year})`,
          weight: process.cantidad,
        }));
        var top10 = d?.sort(function (a, b) {
          return b.weight - a.weight;
        }
        ).slice(0, 10);
        wordCloudAsuntos.setData(top10);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerYearMagis: (
    optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerYearMagis(filters)
      .then((data) => {
        setOptionsBubbleChartMagYear({
          ...optionsBubbleChartMagYear,

          series: [
            {
              data: data.map((process) => {
                return {
                  x: process.year,
                  y: sexOrder[process.Sexo],
                  z: process.cantidad,
                };
              }),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getAsuntos: async (setAsuntos, getErrorfromBack) => {
    await DataService.getAsuntosOrdenados()
      .then((data) => {
        setAsuntos(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerFailureMagis: (
    optionsBubbleChartMagFailure,
    setOptionsBubbleChartMagFailure,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerFailureMagis(filters)
      .then((data) => {
        const dataParsed = data.map((d) => {
          return [
            {
              x: 0,
              y: sexOrder[d.Sexo],
              z: d.cantFavorable,
            },
            { x: 1, y: sexOrder[d.Sexo], z: d.cantDesfavorable },
            { x: 2, y: sexOrder[d.Sexo], z: d.cantAcuerdo },
          ];
        });
        dataParsed.length &&
          setOptionsBubbleChartMagFailure({
            ...optionsBubbleChartMagFailure,
            series: [
              {
                data: [...dataParsed[0], ...dataParsed[1]],
              },
            ],
          });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getSentenciasRecurridas: async (
    setSentenciasRecurridas,
    getErrorfromBack,
    filters
  ) => {
    await DataService.getSentenciasRecurridasMagistrado(filters)
      .then((data) => {
        setSentenciasRecurridas(data[0].cantidad);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getRecursosEstimados: async (
    setReucrsosEstimados,
    getErrorfromBack,
    filters
  ) => {
    await DataService.getRecursosEstimadosMagistrado(filters)
      .then((data) => {
        setReucrsosEstimados(data[0].cantidad);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerYearState: (
    optionsColumnChart,
    setOptionsColumnChart,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerYearStateDependencia(filters)
      .then((data) => {
        setOptionsColumnChart({
          ...optionsColumnChart,
          xAxis: {
            categories: data.map((d) => d.year),
          },
          series: [
            {
              name: "Iniciados",
              data: data.map((d) => d.Iniciados),
            },
            {
              name: "Resueltos",
              data: data.map((d) => d.Resueltos),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getMagistradosTop5: (
    optionsBarChartMag,
    setOptionsBarChartMag,
    getErrorfromBack,
    filters
  ) => {
    DataService.getMagistradosTop5(filters)
      .then((data) => {
        setOptionsBarChartMag({
          ...optionsBarChartMag,
          xAxis: {
            categories: data.map((d) => d.Magistrado),
          },
          series: [
            {
              name: "A favor del Ofendido",
              data: [data.map((d) => d.cantFavorable)],
            },
            {
              name: "A favor del Demandado",
              data: data.map((d) => d.cantDesfavorable),
            },
            {
              name: "Acuerdo entre partes",
              data: data.map((d) => d.cantAcuerdo),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerAsuntoStateJuez: async (
    optionsBarChartDep,
    setOptionsBarChartDep,
    getErrorfromBack,
    filters
  ) => {
    await DataService.getProcessPerAsuntoStateMagistrado(filters)
      .then((data) => {
        setOptionsBarChartDep({
          ...optionsBarChartDep,
          xAxis: {
            categories: data.map((d) => d.Asunto),
          },
          series: [
            {
              name: "Iniciados",
              data: data.map((d) => d.Iniciados),
            },
            {
              name: "Resueltos",
              data: data.map((d) => d.Resueltos),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getTemaFalloTop5: async (setEnfavor, getErrorfromBack, filters) => {
    await DataService.getTemaFalloTop5Magistrado(filters)
      .then((data) => {
        setEnfavor(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getSentidoFallo: async (setEnfavor, getErrorfromBack, filters) => {
    await DataService.getSentidoFalloMagistrado(filters)
      .then((data) => {
        setEnfavor(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMax: async (setMax, getErrorfromBack, filters) => {
    await DataService.getDurationMaxJuzgado(filters)
      .then((data) => {
        setMax(data[0].max || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMedia: async (setMedia, getErrorfromBack, filters) => {
    await DataService.getDurationMediaJuzgado(filters)
      .then((data) => {
        setMedia(data[0].avg || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMin: async (setMin, getErrorfromBack, filters) => {
    await DataService.getDurationMinJuzgado(filters)
      .then((data) => {
        setMin(data[0].min || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },

  getDurationNacionalMin: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    min,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMinNacionalJuzgado(filters)
      .then((data) => {
        const minNacional = data[0].min_nacional;
        min = min || 0;
        const total = minNacional + min;
        const nacionalPercent = Math.ceil((minNacional * 100) / total);
        const magistradoPercent = Math.ceil((min * 100) / total);

        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              name: "Share",
              data: [
                { name: "Nacional", y: nacionalPercent },
                { name: "Magistrado", y: magistradoPercent },
              ],
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMedia: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    media,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMediaNacionalJuzgado(filters)
      .then((data) => {
        const mediaNacional = data[0].avg_nacional;
        media = media || 0;
        const total = mediaNacional + media;
        const nacionalPercent = Math.ceil((mediaNacional * 100) / total);
        const magistradoPercent = Math.ceil((media * 100) / total);

        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              name: "Share",
              data: [
                { name: "Nacional", y: nacionalPercent },
                { name: "Magistrado", y: magistradoPercent },
              ],
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMax: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    max,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMaxNacionalJuzgado(filters)
      .then((data) => {
        const maxNacional = data[0].max_nacional || 0;
        max = max || 0;
        const total = maxNacional + max;
        const nacionalPercent = Math.ceil((maxNacional * 100) / total);
        const magistradoPercent = Math.ceil((max * 100) / total);

        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              name: "Share",
              data: [
                { name: "Nacional", y: nacionalPercent },
                { name: "Magistrado", y: magistradoPercent },
              ],
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getPerEvolutionYear: (
    optionsBubbleChartAsuntos,
    setOptionsBubbleChartAsuntos,
    asuntos,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerEvolutionYearMagistrado(filters)
      .then((data) => {
        setOptionsBubbleChartAsuntos({
          ...optionsBubbleChartAsuntos,
          series: [
            {
              data: data.map((process) => {
                return {
                  x: process.year,
                  y: asuntos[process.Asunto],
                  z: process.cantidad,
                  asunto: process.Asunto,
                };
              }),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getWordCloudAsuntosMasTratados: (
    wordCloudAsuntos,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerEvolutionYearMagistrado(filters)
      .then((data) => {
        var top10 = data.sort(function (a, b) {
          return b.weight - a.weight;
        }
        ).slice(0, 10);
        wordCloudAsuntos.setData(top10);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getJuez: async (setJuez, getErrorfromBack, id_juez) => {
    await DataService.getJuez({
      id_juez: id_juez,
    })
      .then((data) => {
        setJuez(data[0]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getHojaRuta: async (
    optionsTimeLine,
    setOptionsTimeLine,
    getErrorfromBack,
    filters
  ) => {
    await DataService.getHojaRuta(filters)
      .then((data) => {
        const result = {};

        /** Obtengo todas las unidades para un mismo año  */
        data.years.forEach((year) => {
          let unidades = [];
          let puestos = []

          data.results.forEach((d) => {
            if (year === parseInt(d.Periodo.substring(0, 4))) {
              !unidades.includes(d.Unidad) && unidades.push(d.Unidad);
              !puestos.includes(d.PuestoInstitucional) && puestos.push(d.PuestoInstitucional);
            }
          });
          const label = `${puestos.join(" || ")} - ${unidades.join(" || ")} - ${year}`;
          result[year] = label
          unidades = [];
        });

        setOptionsTimeLine({
          ...optionsTimeLine,
          series: [
            {
              ...optionsTimeLine.series[0],
              data: data.years.map(
                (year) =>
                  data.results
                    .map((d) => {
                      return (
                        year === parseInt(d.Periodo.substring(0, 4)) && {
                          name: year,
                          description: result[year],
                        }
                      );
                    })
                    .filter((year, i, a) => a.indexOf(year) === i)
                    .filter((year) => year !== false)[0]
              ),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getResoluciones: async (setResolucion, getErrorfromBack, filters) => {
    await DataService.getResoluciones(filters)
      .then((data) => {
        setResolucion(data[0]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getJuecesFilter: (setJuecesFilter, getErrorfromBack) => {
    DataService.getJuecesFilter()
      .then((data) => {
        setJuecesFilter(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
};
