import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { useHistory, useLocation } from "react-router-dom";

import { Spinner } from "react-bootstrap";

import { AuthService } from "services/AuthService";
import useRequest from "hooks/useRequest";

import Box from "components/organisms/Box";
import LoginLayout from "components/templates/LoginLayout";
import LoginHeader from "components/atoms/LoginHeader";
import ActionButton from "components/atoms/ActionButton";

import "./styles.scss";
import i18n from "i18n";

const LoginGoogleCallback = (props) => {
  const history = useHistory();
  const search = useLocation().search;

  const state = new URLSearchParams(search).get("state");
  const code = new URLSearchParams(search).get("code");
  const scope = new URLSearchParams(search).get("scope");
  const authuser = new URLSearchParams(search).get("authuser");
  const prompt = new URLSearchParams(search).get("prompt");

  const params = [state, code, scope, authuser, prompt];

  const { beforeSubmit, afterSubmit, message, dealWithError } = useRequest();

  const [loaded, setLoaded] = useState(false);
  const [loginMessage, setLoginMessage] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [actionButton, setActionButton] = useState({
    legend: "Volver",
    handleClick: () => {
      history.push("/login");
    },
  });

  const validateParams = () =>
    params.filter((p) => Boolean(p)).length === params.length;

  useEffect(() => {
    beforeSubmit();
    if (validateParams()) {
      AuthService.loginGoogle(code)
        .then((data) => {
          AuthService.loadUser()
            .then((data) => {
              setLoginMessage(
                `Bienvenido, ${data.first_name}! Haz clic en el siguiente botón para continuar`
              );
              setActionButton({
                legend: "Continuar",
                handleClick: () => {
                  history.push("/home");
                },
              });
              setLoaded(true);
            })
            .catch((error) => {
              dealWithError(error, "Error");
              setLoginError(true);
            });
        })
        .catch((error) => {
          dealWithError(error, "Error");
          setLoginError(true);
        });
      afterSubmit();
      return;
    }
    setLoginError(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginError) {
      setLoginMessage(
        Boolean(message)
          ? message
          : "Error al validar la sesión de Google. Intente nuevamente"
      );
      setLoginError(false);
      setLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);

  useEffect(() => setLoginMessage(Boolean(message) && message), [message]);

  return (
    <LoginLayout>
      <Box maxWidth={true}>
        <div className={"login-box-container login-google-callback-container"}>
          <div className={"login-box-form"}>
            <LoginHeader />
          </div>
          {!loaded && (
            <Spinner
              animation="border"
              role="status"
              variant="success"
              className={"login-box-spinner"}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}

          <div>
            {Boolean(loginMessage)
              ? i18n.t(loginMessage)
              : "Validando inicio de sesión con Google..."}
          </div>
          {loaded && <ActionButton {...actionButton} />}
        </div>
      </Box>
    </LoginLayout>
  );
};

export default withTranslation()(LoginGoogleCallback);
