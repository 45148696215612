import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useGoogleAuth } from "hooks/UseGoogleAuth";
import { useFacebookAuth } from "hooks/UseFacebookAuth";

import {
  handleChangeDay,
  handleChangeMonth,
  handleChangeYear,
} from "helpers/Dates";

import { AuthService } from "services/AuthService";
import { RecaptchaService } from "services/RecaptchaService";

import useRequest from "hooks/useRequest";
import useModal from "hooks/UseModal";

import Modal from "components/molecules/Modal";
import LoginBox from "components/organisms/LoginBox";
import LoginLayout from "components/templates/LoginLayout";

import "./styles.scss";

const RegisterView = (props) => {
  const history = useHistory();

  const { modal, openModal } = useModal();

  const {
    beforeSubmit,
    afterSubmit,
    errors,
    setErrors,
    dealWithError,
    loadingSpinner,
  } = useRequest();

  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [phone, setPhone] = useState("");
  const [shareData, setShareData] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true);

  useEffect(() => {
    if (AuthService.currentUserValue()) {
      history.push("/home");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangeEmailConfirm = (e) => setEmailConfirm(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleChangeFirstName = (e) => setFirstName(e.target.value);
  const handleChangeLastName = (e) => setLastName(e.target.value);
  const handleChangePhone = (e) => setPhone(e.target.value);
  const handleChangeShareData = (e) => setShareData(!shareData);
  const handleChangeAcceptTerms = (e) => setAcceptTerms(!acceptTerms);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setDisabled(true);
    const birth_date = `${year}-${month}-${day}`;
    const data = {
      email,
      email_confirm: emailConfirm,
      password,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      birth_date,
      share_data: shareData,
    };
    beforeSubmit();
    AuthService.register(data)
      .then((data) => {
        afterSubmit();
        openModal({
          title: "Ya estás registrado",
          message:
            "Tienes 30 días de subscripción gratuita.\n\nLuego podrás elegir el plan que se adapte mejor a tus necesidades",
          handleClose: (e) => history.replace("/login", { email: email }),
        });
      })
      .catch((error) => {
        afterSubmit();
        setDisabled(false);
        dealWithError(error, "Error");
      });
  };

  const fields = [
    {
      name: "email",
      placeholder: "Correo electrónico",
      value: email,
      onChange: handleChangeEmail,
      fullWidth: true,
      error: errors.email,
      disabled: disabled,
      type: "email",
      autocomplete: true,
    },
    {
      name: "email-confirm",
      placeholder: "Confirma tu correo electrónico",
      value: emailConfirm,
      onChange: handleChangeEmailConfirm,
      fullWidth: true,
      error: errors.email_confirm,
      disabled: disabled,
      type: "email",
    },
    {
      name: "password",
      placeholder: "Crea una contraseña",
      helperText:
        "Al menos 8 caracteres, al menos 1 minúscula, al menos 1 mayuscula, y al menos 1 numero",
      value: password,
      type: "password",
      onChange: handleChangePassword,
      fullWidth: true,
      error: errors.password,
      disabled: disabled,
    },
    {
      name: "first_name",
      placeholder: "Escribe tu nombre",
      value: firstName,
      type: "text",
      onChange: handleChangeFirstName,
      fullWidth: true,
      error: errors.first_name,
      disabled: disabled,
    },
    {
      name: "last_name",
      placeholder: "Escribe tu apellido",
      value: lastName,
      type: "text",
      onChange: handleChangeLastName,
      fullWidth: true,
      error: errors.last_name,
      disabled: disabled,
    },
    {
      date: true,
      error: errors.birth_date,
      fields: [
        {
          name: "day",
          placeholder: "DD",
          value: day,
          type: "number",
          onChange: (e) => setDay(handleChangeDay(e)),
          fullWidth: false,
          error: errors.day,
          disabled: disabled,
        },
        {
          name: "month",
          placeholder: "MM",
          value: month,
          type: "number",
          onChange: (e) => setMonth(handleChangeMonth(e)),
          fullWidth: false,
          error: errors.month,
          disabled: disabled,
        },
        {
          name: "year",
          placeholder: "YYYY",
          value: year,
          type: "number",
          onChange: (e) => setYear(handleChangeYear(e)),
          fullWidth: false,
          error: errors.year,
          disabled: disabled,
        },
      ],
    },
    {
      name: "phone",
      placeholder: "Número de teléfono",
      value: phone,
      type: "number",
      onChange: handleChangePhone,
      fullWidth: false,
      error: errors.phone_number,
      disabled: disabled,
    },
    {
      type: "checkbox",
      legend:
        "Compartir mis datos de registro con los proveedores de contenidos de JudIT para fines de marketing.",
      value: shareData,
      onChange: handleChangeShareData,
      disabled: disabled,
    },
    {
      type: "checkbox",
      legend:
        "Al hacer click en Registrarte, aceptas los terminos y condiciones de uso.",
      href: "/terms",
      target: "_blank",
      value: acceptTerms,
      onChange: handleChangeAcceptTerms,
      disabled: disabled,
    },
  ];

  const loginButton = {
    label: "REGISTRARME",
    disabled: registerButtonDisabled || !acceptTerms || disabled,
    handleClick: handleSubmit,
    loading: loadingSpinner,
    reCaptcha: {
      sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      onChange: (value) => {
        RecaptchaService.validateRecaptcha(value)
          .then(() => setRegisterButtonDisabled(false))
          .catch(() => {
            setRegisterButtonDisabled(true);
          });
      },
    },
  };

  const loginForm = {
    fields,
    loginButton,
    error: errors.non_field_errors,
  };

  const { googleAuth } = useGoogleAuth();
  const { facebookAuth } = useFacebookAuth();

  return (
    <LoginLayout>
      <LoginBox
        loginForm={loginForm}
        googleAuth={googleAuth}
        facebookAuth={facebookAuth}
      />
      <Modal {...modal} />
    </LoginLayout>
  );
};

export default withTranslation()(RegisterView);
