import React, { useEffect, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Home from "components/templates/Home";

import "./styles.scss";
import User from "../../models/User";
import { AuthService } from "../../services/AuthService";

const HomeView = (props) => {
  const history = useHistory();

  useLayoutEffect(() => {
    const currentUser = new User(AuthService.currentUserValue());
    const activeSubscription = currentUser?.activeSubscription();

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }
    history.push("/analiticas");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Home />;
};

export default withTranslation()(HomeView);
