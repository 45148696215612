import React from "react";

import ActionButton from "components/atoms/ActionButton";
import Input from "components/atoms/Input";

import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";

const CardFormPassword = ({
  fields = {
    actualPassword: {},
    passwordConfirm: {},
    newPasswordConfirm: {},
  },
  changePasswordButton,
  errors = {},
}) => {
  return (
    <Container fluid className={"card-profile-password"}>
      {fields.map((field, index) => (
        <Row key={index}>
          <Col>
            <Input {...field} />
          </Col>
        </Row>
      ))}{" "}
      {errors?.non_field_errors && (
        <Row>
          <Col>
            <span className={"input-error pt-2"}>
              {errors.non_field_errors}
            </span>
          </Col>
        </Row>
      )}
      <Row>
        <Col className={"button-col"}>
          <ActionButton {...changePasswordButton} />
        </Col>
      </Row>
    </Container>
  );
};

export default CardFormPassword;
