import { useState } from "react";

export const usePackedBubble = () => {
  const [optionsPackedBubble, setOptionsPackedBubble] = useState({
    credits: false,
    chart: {
      type: "packedbubble",
    },
    title: {
      text: "",
    },
    tooltip: {
      useHTML: true,
      pointFormat: "{point.value} ",
    },
    legend: {
      itemStyle: {
        fontSize: "15px",
      },
    },
    plotOptions: {
      packedbubble: {
        zMin: 0,
        zMax: 1000,
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.02,
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
          style: {
            color: "black",
            textOutline: "none",
            fontWeight: "normal",
          },
        },
      },
    },
    series: [],
  });

  return { optionsPackedBubble, setOptionsPackedBubble };
};
