const { apiCalls } = require("./apiCalls");

export const loadCharts = ({
  getErrorfromBack,
  optionsBarChartDep,
  setOptionsBarChartDep,
  afterSubmit,
  filters,
}) => {
  /** Analitica del CASO: Evolucion de fallos por sexo de magistrado */
  return apiCalls.getRankingLawyers(
    optionsBarChartDep,
    setOptionsBarChartDep,
    getErrorfromBack,
    filters
  );
};
