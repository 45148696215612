import { useState } from "react";

export const useTimeLineChart = () => {
  const [optionsTimeLine, setOptionsTimeLine] = useState({
    credits: false,
    chart: {
      type: "timeline",
      height: "112px",
    },

    title: {
      text: "", // "Período de tiempo con información",
    },

    xAxis: {
      visible: false,
    },

    yAxis: {
      visible: false,
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        dataLabels: {
          connectorColor: "silver",
          alternate: false,
          distance: -50,
        },

        data: [],
      },
    ],
  });
  return { optionsTimeLine, setOptionsTimeLine };
};
