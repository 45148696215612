import React from "react";

import ActionButton from "components/atoms/ActionButton";
import Input from "components/atoms/Input";

import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";

const CardForm = ({
  // title,
  fields = {
    firstName: {},
    lastName: {},
    phone: {},
    email: {},
    day: {},
    month: {},
    year: {},
    password: {},
  },
  updateButton,
  passwordCreateButton,
  errors = {},
}) => {
  return (
    <Container fluid className={"card-profile"}>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <h5 className="card-profile-title">{title}</h5>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      <Row>
        <Col>
          <Input {...fields.firstName} />
        </Col>
        <Col>
          <Input {...fields.lastName} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Input {...fields.phone} />
        </Col>
        <Col>
          <Input {...fields.email} />
        </Col>
      </Row>
      <Row className={"mt-4 mb-4"}>
        <Row>
          <Col>
            <h6 className={"birthday-title"}>Fecha de Nacimiento</h6>
          </Col>
        </Row>
        <Row md={4} lg={6}>
          <Col xs>
            <Input {...fields.day} />
          </Col>
          <Col>
            <Input {...fields.month} />
          </Col>
          <Col>
            <Input {...fields.year} />
          </Col>
        </Row>
        {errors?.birth_date && (
          <Row>
            <Col>
              <span className={"input-error"}>{errors.birth_date}</span>
            </Col>
          </Row>
        )}
      </Row>
      {fields?.passwordCreate && (
        <Row>
          <Col>
            <p>
              <span className={"password-create-title"}>
                Genera una contraseña para poder editar tu perfil
              </span>
            </p>
          </Col>
        </Row>
      )}
      <Row>
        {fields?.password && (
          <Col className={"password-field"}>
            <Input {...fields?.password} />
          </Col>
        )}
        {fields?.passwordCreate && (
          <Col className={"password-field"}>
            <Input {...fields?.passwordCreate} />
          </Col>
        )}
        {fields?.passwordCreateConfirm && (
          <Col className={"password-field"}>
            <Input {...fields?.passwordCreateConfirm} />
          </Col>
        )}
        {fields?.passwordCreate && (
          <Col className={"button-col"}>
            <ActionButton {...passwordCreateButton} />
          </Col>
        )}
        {fields?.password && (
          <Col className={"button-col"}>
            <ActionButton {...updateButton} />
          </Col>
        )}
      </Row>
      {errors?.non_field_errors && (
        <Row>
          <Col>
            <span className={"input-error pt-2"}>
              {errors.non_field_errors}
            </span>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default CardForm;
