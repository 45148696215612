import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsTimeLine from "highcharts/modules/timeline";

const TimeLineChart = ({ options }) => {
  highchartsTimeLine(Highcharts);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TimeLineChart;
