import Plan from "./Plan";

class Subscription {
  activated_at = null;
  created_at = null;
  remaining_days = 0;
  finish_date = null;
  plan = null;
  user = null;
  legend = "";
  status = null;
  is_active = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) {
      if (prop === "plan") {
        this[prop] = new Plan(obj[prop]);
      } else {
        this[prop] = obj[prop];
      }
    }
  }
}

export default Subscription;
