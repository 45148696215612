import Environment from "../environment";

import Api from "./ApiService";

export const PlanService = {
  plans: (filters) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(`${Environment.api}api/plans/`, "GET", filters)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  requestSubscription: (data) => {
    return new Promise((resolve, reject) => {
      return Api.fetch(
        `${Environment.api}api/auth/me/request-subscription/`,
        "POST",
        data
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
