import { useState } from "react";

export const useLineChart = () => {
  const [optionsLineChart, setOptionsLineChart] = useState({
    credits: false,
    chart: {
      type: "line",
    },

    title: {
      text: "", //"Duración media por materia/año",
    },

    yAxis: {
      title: {
        text: "Año",
      },
      labels: {
        enabled: false,
      },
    },

    tooltip: {
      pointFormat: "{series.name}:  <b>{point.y}</b><br/>",
    },

    series: [],
  });

  return {
    optionsLineChart,
    setOptionsLineChart,
  };
};
