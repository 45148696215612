/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { loadCharts } from "./utils/loadCharts";
import { formatDate } from "utils/formatDate";
import { apiCalls } from "./utils/apiCalls";

import { useStorePersist } from "store/context";
import { AuthService } from "services/AuthService";

import { fetchTableData, initialState } from "helpers/Table";

import { useOpenDetail } from "hooks/UseOpenDetail";
import useRequest from "hooks/useRequest";
import { useMapChart } from "views/Analytics/pages/Analytic/hooks/UseMapChart";
import { useColumnChart } from "./hooks/UseColumnChart";
import { useBarChart } from "./hooks/UseBarChart";
import { usePieChart } from "./hooks/UsePieChart";
import { usePackedBubble } from "./hooks/UsePackedBubble";
import { useBubbleChartMagYear } from "./hooks/UseBubbleChartMagYear";
import { useStackedColumChart } from "./hooks/useStackedColumChart";

import User from "models/User";

import MapChart from "components/molecules/MapChart";
import ChartTitle from "components/atoms/ChartTitle";
import ChartGroup from "components/organisms/ChartGroup";
import ProcessTable from "views/Analytics/components/organisms/ProcessTable";

import "./styles.scss";
import Filter from "./components/Filter";
import ChartWrapper from "components/organisms/ChartWrapper";
import { isMobile } from 'helpers/Mobile';

const CasoView = () => {
  const [{}, dispatch] = useStorePersist();
  const history = useHistory();

  const currentUser = new User(AuthService.currentUserValue());
  const activeSubscription = currentUser?.activeSubscription();

  const [filter, setFilter] = useState(false);

  const [endDate, setEndDate] = useState(new Date("11/26/2021"));
  const [startDate, setStartDate] = useState(
    new Date(Date.parse("01/01/2016"))
  );

  const [acuerdo, setAcuerdo] = useState();
  const [favorable, setFavorable] = useState();
  const [desFavorable, setDesFavorable] = useState();

  const [durationMax, setDurationMax] = useState(0);
  const [durationMin, setDurationMin] = useState(0);
  const [durationMedia, setDurationMedia] = useState(0);

  const { mapOptions, setMapOptions } = useMapChart();

  const [dataTable, setDataTable] = useState([]);

  const [acuerdoTop5, setAcuerdoTop5] = useState();
  const [favorableTop5, setFavorableTop5] = useState();
  const [desFavorableTop5, setDesFavorableTop5] = useState();

  const [recursosEstimados, setReucrsosEstimados] = useState("");
  const [sentenciasRecurridas, setSentenciasRecurridas] = useState("");

  const [asuntoFilter, setAsuntoFilter] = useState();
  const [asuntosFilter, setAsuntosFilter] = useState([]);
  const [materiaFilter, setMateriaFilter] = useState();
  const [materiasFilter, setMateriasFilter] = useState([]);
  const [procedimientoFilter, setProcedimientoFilter] = useState();
  const [procedimientosFilter, setProcedimientosFilter] = useState([]);
  const [provinciaFilter, setProvinciaFilter] = useState();
  const [provinciasFilter, setProvinciasFilter] = useState([]);
  const [cantonFilter, setCantonFilter] = useState();
  const [cantonsFilter, setCantonsFilter] = useState([]);
  const [dependenciaFilter, setDependenciaFilter] = useState();
  const [dependenciasFilter, setDependenciasFilter] = useState([]);

  const [processDetalle, setProcessDetalle] = useState([]);
  const [processActual, setProcessActual] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setProcessDetalle([]);
  };
  const handleOpen = () => setShow(true);

  const { optionsBubbleChartMagYear, setOptionsBubbleChartMagYear } =
    useBubbleChartMagYear();
  const { optionsStackedColumnChart, setOptionsStackedColumnChart } =
    useStackedColumChart();

  const { optionsPackedBubble, setOptionsPackedBubble } = usePackedBubble();

  const [cantProcess, setCantProcess] = useState(0);

  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();

  const getErrorfromBack = (error) => {
    dealWithError(error, "Error");
    afterSubmit();
  };

  const {
    optionsPieChart: optionsPieChartSentidoFallo,
    setOptionsPieChart: setOptionsPieChartSentidoFallo,
  } = usePieChart(""); // Sentido del fallo

  const {
    optionsPieChart: optionsPieChartFavorableTop5,
    setOptionsPieChart: setOptionsPieChartFavorableTop5,
  } = usePieChart(""); // Favorable

  const {
    optionsPieChart: optionsPieChartDesFavorableTop5,
    setOptionsPieChart: setOptionsPieChartDesFavorableTop5,
  } = usePieChart(""); // Desfavorable

  const {
    optionsPieChart: optionsPieChartAcuerdoTop5,
    setOptionsPieChart: setOptionsPieChartAcuerdoTop5,
  } = usePieChart(""); // Acuerdo

  const yAxis = { title: "" }; // Cantidad

  const { optionsColumnChart, setOptionsColumnChart } = useColumnChart(
    "", // xxxx
    yAxis
  );
  const {
    optionsBarChart: optionsBarChartDep,
    setOptionsBarChart: setOptionsBarChartDep,
  } = useBarChart("", yAxis); // Dependencias que más resoluciones dictaron (top 5)

  const { optionsPieChart, setOptionsPieChart } = usePieChart(
    "", // Duración
    null,
    "dias"
  );

  const {
    optionsBarChart: optionsBarChartMag,
    setOptionsBarChart: setOptionsBarChartMag,
  } = useBarChart("", yAxis); // Magistrados que más sentencias dictaron (top 5)

  const statesLoadChart = {
    setAsuntosFilter: setAsuntosFilter,
    setMateriasFilter: setMateriasFilter,
    setProcedimientosFilter: setProcedimientosFilter,
    setProvinciasFilter: setProvinciasFilter,
    setCantonsFilter: setCantonsFilter,
    setDependenciasFilter: setDependenciasFilter,
    setFavorableTop5: setFavorableTop5,
    setDesFavorableTop5: setDesFavorableTop5,
    setAcuerdoTop5: setAcuerdoTop5,
    setFavorable: setFavorable,
    setDesFavorable: setDesFavorable,
    setAcuerdo: setAcuerdo,
    getErrorfromBack: getErrorfromBack,
    setDurationMax: setDurationMax,
    setDurationMedia: setDurationMedia,
    setDurationMin: setDurationMin,
    optionsStackedColumnChart: optionsStackedColumnChart,
    setOptionsStackedColumnChart: setOptionsStackedColumnChart,
    optionsBubbleChartMagYear: optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear: setOptionsBubbleChartMagYear,
    optionsBarChartMag: optionsBarChartMag,
    setOptionsBarChartMag: setOptionsBarChartMag,
    setSentenciasRecurridas: setSentenciasRecurridas,
    setReucrsosEstimados: setReucrsosEstimados,
    optionsColumnChart: optionsColumnChart,
    setOptionsColumnChart: setOptionsColumnChart,
    optionsBarChartDep: optionsBarChartDep,
    optionsPackedBubble: optionsPackedBubble,
    setOptionsPackedBubble: setOptionsPackedBubble,
    setOptionsBarChartDep: setOptionsBarChartDep,
    mapOptions: mapOptions,
    setMapOptions: setMapOptions,
    afterSubmit: afterSubmit,
  };

  const [state, setState] = useState(initialState);

  const { data, sizePerPage, page } = state;

  const handleTableChange = async (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      page: page,
    };
    beforeSubmit();
    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });
    afterSubmit();
  };

  useEffect(() => {
    setProcedimientoFilter("-1"); // reseteo filtro de materia
    setAsuntoFilter("-1"); // reseteo filtro de asunto
    apiCalls.getProcedimientosFilter(
      setProcedimientosFilter,
      materiaFilter === "-1" ? 0 : materiaFilter,
      getErrorfromBack
    );
  }, [materiaFilter]);

  useEffect(() => {
    setAsuntoFilter(); // reseteo filtro de asunto
    apiCalls.getAsuntosFilter(
      setAsuntosFilter,
      materiaFilter === "-1" ? 0 : materiaFilter,
      procedimientoFilter === "-1" ? 0 : procedimientoFilter,
      getErrorfromBack
    );
  }, [procedimientoFilter]);

  useEffect(() => {
    setCantonFilter("-1"); // reseteo filtro de asunto
    setDependenciaFilter("-1"); // reseteo filtro de dependencia
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    apiCalls.getCantonsFilter(setCantonsFilter, prov, getErrorfromBack);
  }, [provinciaFilter]);

  useEffect(() => {
    setDependenciaFilter("-1"); // reseteo filtro de asunto
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    const canton = cantonFilter === "-1" ? 0 : cantonFilter;
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      prov ? prov : 0,
      canton ? canton : 0,
      getErrorfromBack
    );
  }, [cantonFilter]);

  useEffect(() => {
    dispatch({
      type: "setBanner",
      payload: {
        title: "Caso",
      },
    });

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }

    apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
    apiCalls.getProcedimientosFilter(
      setProcedimientosFilter,
      0,
      getErrorfromBack
    );
    apiCalls.getAsuntosFilter(setAsuntosFilter, 0, 0, getErrorfromBack);
    apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
    apiCalls.getCantonsFilter(setCantonsFilter, 0, getErrorfromBack);
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      0,
      0,
      getErrorfromBack
    );
  }, []);

  useEffect(() => {
    if (favorable && desFavorable && acuerdo) {
      setOptionsPieChartSentidoFallo({
        ...optionsPieChartSentidoFallo,
        series: [
          {
            name: "Share",
            data: [
              { name: "A favor del Ofendido", y: favorable[0].cantidad },
              { name: "A favor del Demandado", y: desFavorable[0].cantidad },
              { name: "Acuerdo entre partes", y: acuerdo[0].cantidad },
            ],
          },
        ],
      });
    }
  }, [favorable, desFavorable, acuerdo]);

  useEffect(() => {
    if (favorableTop5) {
      setOptionsPieChartFavorableTop5({
        ...optionsPieChartFavorableTop5,
        series: [
          {
            name: "Share",
            data: favorableTop5.map((fav) => {
              return { name: fav.TemaFallo, y: fav.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5]);

  useEffect(() => {
    if (desFavorableTop5) {
      setOptionsPieChartDesFavorableTop5({
        ...optionsPieChartDesFavorableTop5,
        series: [
          {
            name: "Share",
            data: desFavorableTop5.map((desfav) => {
              return { name: desfav.TemaFallo, y: desfav.cantidad };
            }),
          },
        ],
      });
    }
  }, [desFavorableTop5]);
  useEffect(() => {
    if (acuerdoTop5) {
      setOptionsPieChartAcuerdoTop5({
        ...optionsPieChartAcuerdoTop5,
        series: [
          {
            name: "Share",
            data: acuerdoTop5.map((ac) => {
              return { name: ac.TemaFallo, y: ac.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5, desFavorableTop5, acuerdoTop5]);

  useEffect(() => {
    setOptionsPieChart({
      ...optionsPieChart,
      series: [
        {
          name: "Share",
          data: [
            { name: "Máxima", y: durationMax },
            { name: "Media", y: durationMedia },
            { name: "Minima", y: durationMin },
          ],
        },
      ],
    });
  }, [durationMax, durationMedia, durationMin]);

  const { handleOpenDetail } = useOpenDetail();

  const doFilter = (e) => {
    e.preventDefault();
    beforeSubmit();
    setDurationMax();
    setDurationMedia();
    setDurationMin();
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      page: 1,
    };

    fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });

    loadCharts({ ...statesLoadChart, filters: newFilters });
    setFilter(true);
  };

  const chartsDuracionSentido = [
    {
      title: "Duración",
      options: optionsPieChart,
      backgroundTitle: "#542f61",
      xs: 6,
    },
    {
      title: "Sentido del Fallo",
      options: optionsPieChartSentidoFallo,
      backgroundTitle: "#542f61",
      xs: 6,
    },
  ];

  const chartsTemasPorSentido = [
    {
      title: "A favor del Ofendido",
      options: optionsPieChartFavorableTop5,
      backgroundTitle: "#18A48C",
      xs: 4,
    },
    {
      title: "A favor del Demandado",
      options: optionsPieChartDesFavorableTop5,
      backgroundTitle: "#18A48C",
      xs: 4,
    },
    {
      title: "Acuerdo entre partes",
      options: optionsPieChartAcuerdoTop5,
      backgroundTitle: "#18A48C",
      xs: 4,
    },
  ];

  const chartsResolucioneSentencias = [
    {
      title: "Dependencias que más sentencias dictaron (Top 5)",
      options: optionsBarChartDep,
      backgroundTitle: "#542f61",
      fontSize: 18,
    },
    {
      title: "Magistrados que más sentencias dictaron (top 5)",
      options: optionsBarChartMag,
      backgroundTitle: "#542f61",
      fontSize: 20,
    },
  ];

  const chartsProcess = [
    {
      title: "Procesos por provincias",
      options: mapOptions,
      chart: MapChart,
      backgroundTitle: "#18A48C",
      fontSize: 20,
    },
    {
      title: "Procesos por año",
      options: optionsColumnChart,
      backgroundTitle: "#18A48C",
      fontSize: 20,
    },
  ];

  const chartProcessYear = [
    {
      title: "Procesos por año y estado",
      options: optionsColumnChart,
      backgroundTitle: "#542f61",
      fontSize: 20,
    },
  ];

  const chartsEvolutionMagistrado = [
    {
      title: "Evolución de procesos por año y sexo de magistrado",
      options: optionsBubbleChartMagYear,
      backgroundTitle: "#542F61",
      fontSize: 20,
    },
    {
      title: "Evolución de fallos por sentido de fallo/sexo del magistrado",
      options: optionsStackedColumnChart,
      backgroundTitle: "#542F61",
      fontSize: 20,
    },
  ];

  return (
    <div className="caso-container caso-container-size">
      <Filter
        asuntoFilter={asuntoFilter}
        asuntosFilter={asuntosFilter}
        cantonFilter={cantonFilter}
        cantonsFilter={cantonsFilter}
        dependenciasFilter={dependenciasFilter}
        dependenciaFilter={dependenciaFilter}
        doFilter={doFilter}
        endDate={endDate}
        materiaFilter={materiaFilter}
        materiasFilter={materiasFilter}
        procedimientoFilter={procedimientoFilter}
        procedimientosFilter={procedimientosFilter}
        provinciaFilter={provinciaFilter}
        provinciasFilter={provinciasFilter}
        setAsuntoFilter={setAsuntoFilter}
        setCantonFilter={setCantonFilter}
        setEndDate={setEndDate}
        setMateriaFilter={setMateriaFilter}
        setProcedimientoFilter={setProcedimientoFilter}
        setProvinciaFilter={setProvinciaFilter}
        setStartDate={setStartDate}
        startDate={startDate}
        setDependenciaFilter={setDependenciaFilter}
      />
      {filter && (
        <div className={"mt-4 pt-4 mb-4"}>
          <ChartTitle
            title={"Análisis estadístico de resolución"}
            fontSize={isMobile ? 14 : 24}
            showLine
            textTransform={"uppercase"}
            customClass={"mb-4"}
          />
          <ChartWrapper charts={chartsDuracionSentido} />

          <ChartTitle
            title={"Temas con más resoluciones por sentido del fallo (Top 5)"}
            fontSize={isMobile ? 14 : 24}
            showLine
            textTransform={"uppercase"}
            customClass={"mt-4 mb-4"}
          />
          <ChartWrapper charts={chartsTemasPorSentido} />
          <ChartWrapper charts={chartsResolucioneSentencias} title={""} />
          <ChartWrapper charts={chartsProcess} />
          <ChartWrapper charts={chartProcessYear} />
          <ChartWrapper charts={chartsEvolutionMagistrado} />

          <ProcessTable
            data={data}
            page={page}
            cantProcess={cantProcess}
            handleCloseModal={handleClose}
            handleTableChange={handleTableChange}
            processActual={processActual}
            processDetalle={processDetalle}
            sizePerPage={sizePerPage}
            show={show}
          />
        </div>
      )}
    </div>
  );
};

export default CasoView;
