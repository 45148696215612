import { DataService } from "../services/DataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Environment from "../environment";
import React from "react";

export const fetchTableData = async ({
  newFilters,
  setDataTable,
  state,
  setState,
  dealWithError,
  setCantProcess,
  setProcessActual,
  setProcessDetalle,
  handleOpen,
  getErrorfromBack,
  handleOpenDetail,
}) => {
  await DataService.getProcess(newFilters)
    .then((data) => {
      setCantProcess(data.cant);
      const datos = data.results.map((p, i) => {
        return {
          id: i,
          FechaIngreso: p.FechaIngreso,
          Proceso: p.Proceso,
          AccionInfraccion: p.AccionInfraccion?.split(",").join(", "),
          pdf: (
            <FontAwesomeIcon
              icon={faInfoCircle}
              style={{ cursor: "pointer" }}
              onClick={(event) =>
                handleOpenDetail({
                  event,
                  p,
                  setProcessActual,
                  setProcessDetalle,
                  handleOpen,
                  getErrorfromBack,
                })
              }
            />
          ),
        };
      });
      setDataTable(datos);
      setState({
        ...state,
        page: newFilters.page,
        data: datos,
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });
};

export const initialState = {
  page: 1,
  data: [],
  sizePerPage: 20,
};
