import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { useHistory } from "react-router-dom";

import "./App.css";
import "react-datepicker/dist/react-datepicker.css";

import { Context, ContextPersist } from "./store/context";
import { AuthService } from "./services/AuthService";

import HomeView from "./views/Home/HomeView";
import SignInView from "./views/Auth/Login/SignInView";
import LoginGoogleCallback from "./views/Auth/LoginGoogleCallback/LoginGoogleCallback";
import ResetPasswordView from "./views/Auth/ResetPassword/ResetPasswordView";
import ResetPasswordRequestView from "./views/Auth/ResetPassswordRequest/ResetPasswordRequestView";
import RegisterView from "./views/Auth/Register/RegisterView";
import ConfirmAccountView from "./views/Auth/ConfirmAccount/ConfirmAccountView";
import ProfileView from "./views/Profile/ProfileView";

import Layout from "./components/templates/Layout";
import LoginFacebookCallback from "./views/Auth/LoginFacebookCallback/LoginFacebookCallback";
import AnalyticView from "views/Analytics/pages/Analytic/AnalyticView";
import CasoView from "views/Analytics/pages/Caso/CasoView";
import DependenciaView from "views/Analytics/pages/Dependencia/DependenciaView";
import MagistradoView from "views/Analytics/pages/Magistrado/MagistradoView";
import SearchView from "views/Analytics/pages/Search/SearchView";
import RankingView from "views/Analytics/pages/Ranking/RankingView";

function App() {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    AuthService.loadUser()
      .then((user) => {
        setUser(user);
        setLoad(true);
      })
      .catch((error) => {
        AuthService.logout();
        setLoad(true);
      });

    const currentUserObserver = AuthService.currentUser.subscribe(
      (user_event) => {
        if (user != null && user_event === null) {
          history.push("/login");
        }
        // Obligo a renderizar entonces refresca bien los permisos
        setUser(user_event);
      }
    );

    return function cleanup() {
      currentUserObserver.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContextPersist>
      <Context>
        <Switch>
          <Route exact path="/" component={SignInView} />
          <Route exact path="/login" component={SignInView} />
          <Route exact path="/register" component={RegisterView} />
          <Route
            exact
            path="/login/reset-password/:token"
            component={ResetPasswordView}
          />
          <Route
            exact
            path="/account/confirm/:user_id/:confirmation_token"
            component={ConfirmAccountView}
          />
          <Route
            exact
            path="/login/reset-password"
            component={ResetPasswordRequestView}
          />
          <Route
            exact
            path="/login/google-callback"
            component={LoginGoogleCallback}
          />
          <Route
            exact
            path="/login/facebook-callback"
            component={LoginFacebookCallback}
          />
          <Layout>
            {load && user && (
              <>
                <Route exact path="/home" component={HomeView} />
                <Route exact path="/caso" component={CasoView} />
                <Route exact path="/search" component={SearchView} />
                <Route exact path="/profile" component={ProfileView} />
                <Route exact path="/magistrado" component={MagistradoView} />
                <Route exact path="/analiticas" component={AnalyticView} />
                <Route exact path="/dependencia" component={DependenciaView} />
                <Route exact path="/ranking-abogados" component={RankingView} />
              </>
            )}
          </Layout>
        </Switch>
      </Context>
    </ContextPersist>
  );
}

export default App;
