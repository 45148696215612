import { useState } from "react";

export const useGaugeChart = () => {
  const [optionsGaugeChart, setOptionsGaugeChart] = useState({
    chart: {
      type: "solidgauge",
    },
    tooltip: {
      enabled: false,
    },
    title: null,
    credits: {
      enabled: false,
    },
    pane: {
      size: "100%",
      startAngle: -120,
      endAngle: 120,
      background: {
        innerRadius: "75%",
        outerRadius: "100%",
        backgroundColor: "#eeeeee",
        borderWidth: 0,
        shape: "arc",
      },
    },
    plotOptions: {
      solidgauge: {
        innerRadius: "75%",
        radius: "100%",
        dataLabels: {
          enabled: true,
          useHTML: true,
          zIndex: 1000, //added zindex
        },
      },
    },
    yAxis: {
      labels: {
        enabled: false,
      },
      min: 0,
      max: 100,
      gridLineColor: "transparent",
      lineColor: "transparent",
      minorTickLength: 0,
      tickPositions: [],
      tickColor: "#000000",
      tickPosition: "inside",
      tickLength: 50,
      tickWidth: 8,
      zIndex: 100, //added zindex
    },
    series: [
      {
        data: [],
        dataLabels: {
          format:
            '<span style="font-size:15px">{point.provincia} Días <br /> {point.y} Días Nación</span><br/>', // eslint-disable-line no-template-curly-in-string
        },
        tooltip: {
          valueSuffix: " días",
        },
      },
    ],
  });

  return { optionsGaugeChart, setOptionsGaugeChart };
};
