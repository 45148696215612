import React from "react";
import "./styles.scss";
const Title = ({ children }) => {
  return (
    <div className="title-container">
      <div className="title-wrapper">
        <span>{children}</span>
      </div>
    </div>
  );
};

export default Title;
