import React from "react";

import { Col, Container, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";

import SelectSearchS from "components/atoms/SelectSearch";
import ActionButton from "components/atoms/ActionButton";
import Box from "components/organisms/Box";
import FilterContainer from "components/molecules/FilterContainer";

const Filter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,

  provinciasFilter,
  setProvinciaFilter,
  provinciaFilter,

  cantonsFilter,
  cantonFilter,
  setCantonFilter,

  dependenciasFilter,
  setDependenciaFilter,
  dependenciaFilter,

  asuntosFilter,
  setAsuntoFilter,

  doFilter,
}) => {
  return (
    <FilterContainer customClass={"dependencia-filter-bg"}>
      <Box customClass={"caso-filter-box"}>
        <Container fluid>
          <Row>
            <Col md={4} xs={12}>
              <div className="caso-container-input">
                <Form.Group>
                  <Form.Label>*Período desde</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    className={"date-picker-class"}
                    fixedHeight
                    showYearDropdown
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="caso-container-input">
                <Form.Group>
                  <Form.Label>*Período hasta</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd/MM/yyyy"
                    className={"date-picker-class"}
                    fixedHeight
                    showYearDropdown
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4} xs={12}>
              <div className="caso-container-input">
                <Form.Group>
                  <Form.Label>*Provincia</Form.Label>
                  <SelectSearchS
                    options={provinciasFilter.map((item) => ({
                      name: item.label,
                      value: item.id,
                    }))}
                    onChange={(selectedValue) =>
                      setProvinciaFilter(selectedValue)
                    }
                    value={provinciaFilter}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="caso-container-input">
                <Form.Group>
                  <Form.Label>*Cantón</Form.Label>
                  <SelectSearchS
                    options={cantonsFilter.map((item) => ({
                      name: item.label,
                      value: item.id,
                    }))}
                    onChange={(selectedValue) => setCantonFilter(selectedValue)}
                    value={cantonFilter}
                    disabled={!provinciaFilter}
                    placeholder={
                      !provinciaFilter
                        ? "Seleccione una provincia"
                        : "Seleccione una opción"
                    }
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={4} xs={12}>
              <div className="caso-container-input">
                <Form.Group>
                  <Form.Label>*Dependencia</Form.Label>
                  <SelectSearchS
                    options={dependenciasFilter.map((item) => ({
                      name: item.label,
                      value: item.id,
                    }))}
                    onChange={(selectedValue) =>
                      setDependenciaFilter(selectedValue)
                    }
                    value={dependenciaFilter}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          <div className="caso-container-button mt-4">
            <ActionButton
              handleClick={doFilter}
              legend="Filtrar"
              disabled={
                !dependenciaFilter ||
                dependenciaFilter === "-1" ||
                !provinciaFilter ||
                provinciaFilter === "-1" ||
                !cantonFilter ||
                cantonFilter === "-1"
              }
            />
          </div>
        </Container>
      </Box>
    </FilterContainer>
  );
};

export default Filter;
