import React from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as Logo } from "assets/images/judit.svg";
import { AuthService } from "services/AuthService";

import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";

import "./styles.scss";
import {
  faUserCircle as farUserCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Menu = () => {
  const history = useHistory();

  const redirectTo = (path) => history.replace(path);
  const isActive = (path) => Boolean(history.location.pathname === path);
  const routers = [
    {
      label: "ANALÍTICAS",
      path: "/analiticas",
    },
    {
      label: "CASO",
      path: "/caso",
    },
    {
      label: "DEPENDENCIA",
      path: "/dependencia",
    },
    {
      label: "MAGISTRADO",
      path: "/magistrado",
    },
    {
      label: "RANKING ABOGADOS",
      path: "/ranking-abogados",
    },
  ];
  return (
    <div className="menu-container logo">
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Brand to={"/"} onClick={() => redirectTo("/")}>
            <Logo className={"logo"} width={165} height={83} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {routers?.map((router, index) => (
                <Nav.Link
                  key={index}
                  to={router?.path}
                  active={isActive(router?.path)}
                  onClick={() => redirectTo(router?.path)}
                >
                  {`> ${router?.label}`}
                </Nav.Link>
              ))}

              <NavDropdown
                title={
                  <FontAwesomeIcon
                    icon={farUserCircle}
                    className={"person-icon"}
                  />
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item href="/profile">MIS DATOS</NavDropdown.Item>
                <NavDropdown.Item href="/profile?tab=change-password">
                  CAMBIAR CONTRASEÑA
                </NavDropdown.Item>
                <NavDropdown.Item href="/profile?tab=subscription">
                  MI SUBSCRIPCIÓN
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={() => {
                    AuthService.logout();
                    redirectTo("/login");
                  }}
                >
                  CERRAR SESIÓN
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Menu;
