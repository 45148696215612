import { useState } from "react";

export const useWordCloudChart = () => {
  const [data, setData] = useState("");

  const parseData = (data) => {
    return data.map((d) => ({
      name: d.name,
      weight: d.cantidad,
    }));
  };

  const optionsWordCloud = {
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>",
      },
    },
    chart: {
      margin: [0,0,0,0]
    },
    clip: false,
    style: {
        fontSize: "40px"
    },
    series: [
      {
        type: "wordcloud",
        data,
        name: "Ocurrencias",
      },
    ],
    title: {
      text: null,
    },
  };

  return {
    optionsWordCloud,
    data,
    setData,
    parseData,
  };
};
