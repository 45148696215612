import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { useGoogleAuth } from "hooks/UseGoogleAuth";
import { useFacebookAuth } from "hooks/UseFacebookAuth";

import { AuthService } from "services/AuthService";
import useRequest from "hooks/useRequest";
import useSnackbar from "hooks/UseSnackbar";

import Toast from "components/atoms/Toast";
import LoginBox from "components/organisms/LoginBox";
import LoginLayout from "components/templates/LoginLayout";

import "./styles.scss";

const SignInView = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    beforeSubmit,
    afterSubmit,
    errors,
    setErrors,
    dealWithError,
    loadingSpinner,
  } = useRequest();

  const { snackbar } = useSnackbar();

  const [email, setEmail] = useState(location?.state?.email || "");
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (AuthService.currentUserValue()) {
      history.push("/home");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeEmail = (e) => setEmail(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    if (!Boolean(email) || !Boolean(password)) {
      const e = {};
      !Boolean(email) &&
        Object.assign(e, { email: ["Este campo es requerido"] });
      !Boolean(password) &&
        Object.assign(e, { password: ["Este campo es requerido"] });
      setErrors(e);
      return;
    }
    setDisabled(true);
    beforeSubmit();
    AuthService.login(email, password)
      .then((data) => {
        AuthService.loadUser()
          .then((data) => {
            afterSubmit();
            history.push("/home");
          })
          .catch((error) => {
            setDisabled(false);
            dealWithError(error, "Error");
          });
      })
      .catch((error) => {
        setDisabled(false);
        dealWithError(error, "Error");
      });
  };
  //
  // useEffect(() => {
  //   if (Boolean(message)) {
  //     showSnackbar({
  //       title: "Error",
  //       message: message.join(" "),
  //       autohide: true,
  //       delay: 5000,
  //     });
  //   }
  // }, [message]);

  const fields = [
    {
      name: "email",
      placeholder: "Correo",
      value: email,
      onChange: handleChangeEmail,
      onKeyDown: (e) => e.keyCode === 13 && handleSubmit(e),
      type: "email",
      fullWidth: true,
      error: errors.email,
      autocomplete: true,
    },
    {
      name: "password",
      placeholder: "*********",
      value: password,
      type: "password",
      onKeyDown: (e) => e.keyCode === 13 && handleSubmit(e),
      onChange: handleChangePassword,
      fullWidth: true,
      error: errors.password,
    },
  ];

  const loginButton = {
    label: "INGRESAR",
    handleClick: handleSubmit,
    disabled: disabled,
    loading: loadingSpinner,
  };

  const forgotPasswordLink = {
    label: "Olvidé mi contraseña",
    handleClick: (e) => history.push("/login/reset-password"),
  };

  const loginForm = {
    fields,
    loginButton,
    forgotPasswordLink,
    error: errors.non_field_errors,
  };

  const registerButton = {
    legend: "REGISTRATE",
    handleClick: (e) => history.push("/register"),
  };

  const { googleAuth } = useGoogleAuth();
  const { facebookAuth } = useFacebookAuth();

  return (
    <LoginLayout>
      <LoginBox
        loginForm={loginForm}
        registerButton={registerButton}
        googleAuth={googleAuth}
        facebookAuth={facebookAuth}
      />
      <Toast {...snackbar} />
    </LoginLayout>
  );
};

export default withTranslation()(SignInView);
