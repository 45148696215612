import Subscription from "./Subscription";

class User {
  id = null;
  email = null;
  is_staff = null;
  is_active = null;
  is_confirmed = null;
  last_login = null;
  birth_date = null;
  phone_number = null;
  first_name = null;
  last_name = null;
  subscription = null;
  subscription_free = null;
  setup_completed = null;
  is_social_account = null;
  password_must_reset = null;

  confirmed = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) {
      if (prop === "subscription") {
        this[prop] = new Subscription(obj[prop]);
      } else if (prop === "subscription_free") {
        this[prop] = new Subscription(obj[prop]);
      } else {
        this[prop] = obj[prop];
      }
    }
  }

  activeSubscription() {
    if (this.subscription_free?.is_active) {
      return this.subscription_free;
    }
    return this.subscription;
  }

  parseBirthDate() {
    let date = this.birth_date;
    if (typeof date === "object") {
      date = this.birth_date.toJSON().split("T")[0];
    }
    return date.split("-");
  }

  mustSetPassword() {
    return this.is_social_account && this.password_must_reset;
  }
}

export default User;
