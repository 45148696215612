/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useStorePersist } from "store/context";

import { AuthService } from "services/AuthService";

import { fetchTableData, initialState } from "helpers/Table";

import { formatDate } from "utils/formatDate";
import { apiCalls } from "./utils/apiCalls";
import { loadCharts } from "./utils/loadCharts";

import useRequest from "hooks/useRequest";
import { useOpenDetail } from "hooks/UseOpenDetail";
import { useColumnChart } from "./hooks/UseColumnChart";
import { useBarChart } from "./hooks/UseBarChart";
import { usePieChart } from "./hooks/UsePieChart";
import { usePackedBubble } from "./hooks/UsePackedBubble";
import { useBubbleChartMagYear } from "./hooks/UseBubbleChartMagYear";
import { useBubbleChartMagFailure } from "./hooks/UseBubbleChartMagFailure";
import { useGaugeChart } from "./hooks/UseGaugeChart";
import { useBubbleChartAsuntos } from "views/Analytics/pages/Analytic/hooks/UseBubbleChartAsuntos";

import User from "models/User";

import ChartTitle from "components/atoms/ChartTitle";
import SolidGaugeChart from "components/molecules/SolidGaugeChart";
import ChartGroup from "components/organisms/ChartGroup";
import ProcessTable from "views/Analytics/components/organisms/ProcessTable";

import "./styles.scss";
import Filter from "./Components/Filter";
import ChartWrapper from "components/organisms/ChartWrapper";
import { useFixedPlacementColumns } from "./hooks/UseFixedPlacementColumns";
import { useWordCloudChart } from "./hooks/UseWordCloudChart";
import { useStackedColumnChart } from "./hooks/UseStackedColumnChart";

import { isMobile } from 'helpers/Mobile';

const DependenciaView = () => {
  const history = useHistory();

  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStorePersist();

  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();

  const [endDate, setEndDate] = useState(new Date("11/26/2021"));
  const [startDate, setStartDate] = useState(
    new Date(Date.parse("01/01/2016"))
  );

  const yAxis = { title: "Cantidad" };

  const [max, setMax] = useState(0);
  const [media, setMedia] = useState(0);
  const [min, setMin] = useState(0);

  const currentUser = new User(AuthService.currentUserValue());
  const activeSubscription = currentUser?.activeSubscription();

  const getErrorfromBack = (error) => {
    dealWithError(error, "Error");
    afterSubmit();
  };

  const [filter, setFilter] = useState(false);

  const [cantProcess, setCantProcess] = useState(0);

  const [asuntoFilter, setAsuntoFilter] = useState("-1");
  const [asuntosFilter, setAsuntosFilter] = useState([]);
  const [materiaFilter, setMateriaFilter] = useState();
  const [materiasFilter, setMateriasFilter] = useState([]);
  const [procedimientoFilter, setProcedimientoFilter] = useState();
  const [procedimientosFilter, setProcedimientosFilter] = useState([]);
  const [provinciaFilter, setProvinciaFilter] = useState(-1);
  const [provinciasFilter, setProvinciasFilter] = useState([]);
  const [cantonFilter, setCantonFilter] = useState();
  const [cantonsFilter, setCantonsFilter] = useState([]);
  const [dependenciaFilter, setDependenciaFilter] = useState();
  const [dependenciasFilter, setDependenciasFilter] = useState([]);

  const [acuerdo, setAcuerdo] = useState();
  const [favorable, setFavorable] = useState();
  const [desFavorable, setDesFavorable] = useState();

  const [acuerdoTop5, setAcuerdoTop5] = useState();
  const [favorableTop5, setFavorableTop5] = useState();
  const [desFavorableTop5, setDesFavorableTop5] = useState();

  const { optionsPackedBubble, setOptionsPackedBubble } = usePackedBubble();

  const { optionsBubbleChartMagYear, setOptionsBubbleChartMagYear } =
    useBubbleChartMagYear();
  const { optionsBubbleChartMagFailure, setOptionsBubbleChartMagFailure } =
    useBubbleChartMagFailure();

  const { optionsBubbleChartAsuntos, setOptionsBubbleChartAsuntos } =
    useBubbleChartAsuntos();
  const [asuntos, setAsuntos] = useState();

  const [dataTable, setDataTable] = useState([]);

  const {
    optionsGaugeChart: optionsGaugeChartMax,
    setOptionsGaugeChart: setOptionsGaugeChartMax,
  } = useGaugeChart();
  const {
    optionsGaugeChart: optionsGaugeChartMedia,
    setOptionsGaugeChart: setOptionsGaugeChartMedia,
  } = useGaugeChart();
  const {
    optionsGaugeChart: optionsGaugeChartMin,
    setOptionsGaugeChart: setOptionsGaugeChartMin,
  } = useGaugeChart();

  const {
    optionsPieChart: optionsPieChartSentidoFallo,
    setOptionsPieChart: setOptionsPieChartSentidoFallo,
  } = usePieChart("", null, "", true); // Sentido del fallo

  const {
    optionsPieChart: optionsPieChartFavorableTop5,
    setOptionsPieChart: setOptionsPieChartFavorableTop5,
  } = usePieChart(""); // Favorable

  const {
    optionsPieChart: optionsPieChartDesFavorableTop5,
    setOptionsPieChart: setOptionsPieChartDesFavorableTop5,
  } = usePieChart(""); // Desfavorable

  const {
    optionsPieChart: optionsPieChartAcuerdoTop5,
    setOptionsPieChart: setOptionsPieChartAcuerdoTop5,
  } = usePieChart(""); // Acuerdo

  const {
    optionsBarChart: optionsBarChartAsuntoState,
    setOptionsBarChart: setOptionsBarChartAsuntoState,
  } = useBarChart("", yAxis, true); // Pocesos por asunto (top 5)

  const {
    optionsBarChart: optionsBarChartMag,
    setOptionsBarChart: setOptionsBarChartMag,
  } = useBarChart("", yAxis, true); // Magistrados que más sentencias dictaron (top 5)

  const { optionsColumnChart, setOptionsColumnChart } = useColumnChart(
    "", // Procesos por año
    yAxis
  );

  const [state, setState] = useState(initialState);

  const [processDetalle, setProcessDetalle] = useState([]);
  const [processActual, setProcessActual] = useState();
  const [show, setShow] = useState(false);

  const { handleOpenDetail } = useOpenDetail();

  const handleClose = () => {
    setShow(false);
    setProcessDetalle([]);
  };
  const handleOpen = () => setShow(true);

  const { data, sizePerPage, page } = state;

  const handleTableChange = async (type, { page, sizePerPage }) => {
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      page: page,
    };
    beforeSubmit();
    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });
    afterSubmit();
  };

  const stackedColumnMagistrado = useStackedColumnChart();

  const statesLoadChart = {
    setAsuntosFilter: setAsuntosFilter,
    setMateriasFilter: setMateriasFilter,
    setProcedimientosFilter: setProcedimientosFilter,
    setProvinciasFilter: setProvinciasFilter,
    setCantonsFilter: setCantonsFilter,
    setDependenciasFilter: setDependenciasFilter,
    setFavorableTop5: setFavorableTop5,
    setDesFavorableTop5: setDesFavorableTop5,
    setAcuerdoTop5: setAcuerdoTop5,
    setFavorable: setFavorable,
    setDesFavorable: setDesFavorable,
    setAcuerdo: setAcuerdo,
    getErrorfromBack: getErrorfromBack,
    setMax: setMax,
    setMedia: setMedia,
    setMin: setMin,
    optionsBubbleChartMagFailure: optionsBubbleChartMagFailure,
    setOptionsBubbleChartMagFailure: setOptionsBubbleChartMagFailure,
    optionsBubbleChartMagYear: optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear: setOptionsBubbleChartMagYear,
    optionsBarChartMag: optionsBarChartMag,
    setOptionsBarChartMag: setOptionsBarChartMag,
    optionsBarChartAsuntoState: optionsBarChartAsuntoState,
    setOptionsBarChartAsuntoState: setOptionsBarChartAsuntoState,
    optionsColumnChart: optionsColumnChart,
    setOptionsColumnChart: setOptionsColumnChart,
    setAsuntos: setAsuntos,
    optionsPackedBubble: optionsPackedBubble,
    setOptionsPackedBubble: setOptionsPackedBubble,
    afterSubmit: afterSubmit,
    stackedColumnMagistrado,
  };

  const bannerDispatcher = {
    type: "setBanner",
    payload: {
      title: "Dependencia",
    },
  };

  useEffect(() => {
    dispatch(bannerDispatcher);

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }

    apiCalls.getAsuntosFilter(setAsuntosFilter, getErrorfromBack);
    apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
    apiCalls.getProcedimientosFilter(setProcedimientosFilter, getErrorfromBack);
    apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
    apiCalls.getCantonsFilter(setCantonsFilter, 0, getErrorfromBack);
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      0,
      0,
      getErrorfromBack
    );
  }, []);

  // useEffect(() => {
  //   setDependenciaFilter(); // reseteo filtro de asunto
  //   apiCalls.getDependenciasFilter(
  //     setDependenciasFilter,
  //     materiaFilter === "-1" ? 0 : materiaFilter,
  //     procedimientoFilter === "-1" ? 0 : procedimientoFilter,
  //     getErrorfromBack
  //   );
  // }, [materiaFilter, procedimientoFilter]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      added_function: "MAX",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
    };
    // asuntos &&
    //   apiCalls.getPerEvolutionYear(
    //     optionsBubbleChartAsuntos,
    //     setOptionsBubbleChartAsuntos,
    //     asuntos,
    //     getErrorfromBack,
    //     filters
    //   );
    asuntos &&
      apiCalls.getWordCloudAsuntosMasTratados(
        wordCloudAsuntos,
        getErrorfromBack,
        filters
      );
  }, [asuntos]);

  // useEffect(() => {
  //   let filters = {
  //     fecha_desde: formatDate(startDate),
  //     fecha_hasta: formatDate(endDate),
  //     id_asunto: asuntoFilter || "-1",
  //     added_function: "MAX",
  //   };
  //   (max || max === 0) &&
  //     apiCalls.getDurationNacionalMax(
  //       optionsGaugeChartMax,
  //       setOptionsGaugeChartMax,
  //       max,
  //       getErrorfromBack,
  //       filters
  //     );
  // }, [max]);
  //
  // useEffect(() => {
  //   let filters = {
  //     fecha_desde: formatDate(startDate),
  //     fecha_hasta: formatDate(endDate),
  //     id_asunto: asuntoFilter || "-1",
  //     added_function: "AVG",
  //   };
  //   (media || media === 0) &&
  //     apiCalls.getDurationNacionalMedia(
  //       optionsGaugeChartMedia,
  //       setOptionsGaugeChartMedia,
  //       media,
  //       getErrorfromBack,
  //       filters
  //     );
  // }, [media]);
  //
  // useEffect(() => {
  //   let filters = {
  //     fecha_desde: formatDate(startDate),
  //     fecha_hasta: formatDate(endDate),
  //     id_asunto: asuntoFilter || "-1",
  //     added_function: "MIN",
  //   };
  //   (min || min === 0) &&
  //     apiCalls.getDurationNacionalMin(
  //       optionsGaugeChartMin,
  //       setOptionsGaugeChartMin,
  //       min,
  //       getErrorfromBack,
  //       filters
  //     );
  // }, [min]);

  const fixedChartDuracion = useFixedPlacementColumns();

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      added_function: "MAX",
    };
    (max || max === 0) &&
      apiCalls.getDurationNacionalMaxFixed(
        fixedChartDuracion,
        max,
        getErrorfromBack,
        filters
      );
  }, [max]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      added_function: "AVG",
    };
    (media || media === 0) &&
      apiCalls.getDurationNacionalMediaFixed(
        fixedChartDuracion,
        media,
        getErrorfromBack,
        filters
      );
  }, [media]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      added_function: "MIN",
    };
    (min || min === 0) &&
      apiCalls.getDurationNacionalMinFixed(
        fixedChartDuracion,
        min,
        getErrorfromBack,
        filters
      );
  }, [min]);

  useEffect(() => {
    if (favorable && desFavorable && acuerdo) {
      setOptionsPieChartSentidoFallo({
        ...optionsPieChartSentidoFallo,
        series: [
          {
            name: "Share",
            data: [
              { name: "A favor del Ofendido", y: favorable[0].cantidad },
              { name: "A favor del Demandado", y: desFavorable[0].cantidad },
              { name: "Acuerdo entre partes", y: acuerdo[0].cantidad },
            ],
          },
        ],
      });
    }
  }, [favorable, desFavorable, acuerdo]);

  useEffect(() => {
    setCantonFilter("-1"); // reseteo filtro de asunto
    setDependenciaFilter("-1"); // reseteo filtro de dependencia
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    apiCalls.getCantonsFilter(setCantonsFilter, prov, getErrorfromBack);
  }, [provinciaFilter]);

  useEffect(() => {
    setDependenciaFilter("-1"); // reseteo filtro de asunto
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    const canton = cantonFilter === "-1" ? 0 : cantonFilter;
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      prov,
      canton,
      getErrorfromBack
    );
  }, [cantonFilter]);

  useEffect(() => {
    if (favorableTop5) {
      setOptionsPieChartFavorableTop5({
        ...optionsPieChartFavorableTop5,
        series: [
          {
            name: "Share",
            data: favorableTop5.map((fav) => {
              return { name: fav.TemaFallo, y: fav.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5]);

  useEffect(() => {
    if (desFavorableTop5) {
      setOptionsPieChartDesFavorableTop5({
        ...optionsPieChartDesFavorableTop5,
        series: [
          {
            name: "Share",
            data: desFavorableTop5.map((desfav) => {
              return { name: desfav.TemaFallo, y: desfav.cantidad };
            }),
          },
        ],
      });
    }
  }, [desFavorableTop5]);

  useEffect(() => {
    if (acuerdoTop5) {
      setOptionsPieChartAcuerdoTop5({
        ...optionsPieChartAcuerdoTop5,
        series: [
          {
            name: "Share",
            data: acuerdoTop5.map((ac) => {
              return { name: ac.TemaFallo, y: ac.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5, desFavorableTop5, acuerdoTop5]);

  const doFilter = (e) => {
    e.preventDefault();
    beforeSubmit();
    setMax(0);
    setMedia(0);
    setMin(0);
    fixedChartDuracion?.resetValues(); // eslint-disable-line no-unused-expressions
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_asunto: asuntoFilter || "-1",
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_procedimiento: procedimientoFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      page: 1,
    };

    fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });

    loadCharts({ ...statesLoadChart, filters: newFilters });
    setFilter(true);
  };

  const chartsDuracionDependenciaNacional = [
    {
      title: "Maxima",
      options: optionsGaugeChartMax,
      chart: SolidGaugeChart,
      backgroundTitle: "#542f61",
    },
    {
      title: "Media",
      options: optionsGaugeChartMedia,
      chart: SolidGaugeChart,
      backgroundTitle: "#542f61",
    },
    {
      title: "Mínima",
      options: optionsGaugeChartMin,
      chart: SolidGaugeChart,
      backgroundTitle: "#542f61",
    },
  ];

  const chartsDuracionFixed = [
    {
      title: "Duración",
      options: fixedChartDuracion.optionsFixedPlacementColumns,
      backgroundTitle: "#18a48c",
    },
  ];

  const chartSentidoDelFallo = [
    {
      title: "Sentido del Fallo",
      options: optionsPieChartSentidoFallo,
      backgroundTitle: "#18a48c",
    },
  ];

  const chartsTemasSentidoDelFallo = [
    {
      title: "A favor del Ofendido",
      options: optionsPieChartFavorableTop5,
      backgroundTitle: "#18a48c",
    },
    {
      title: "A favor del Demandado",
      options: optionsPieChartDesFavorableTop5,
      backgroundTitle: "#18a48c",
    },
    {
      title: "Acuerdo entre partes",
      options: optionsPieChartAcuerdoTop5,
      backgroundTitle: "#18a48c",
    },
  ];

  const chartsSentenciasProcesos = [
    {
      title: "Magistrados que más sentencias dictaron (top 5)",
      options: optionsBarChartMag,
      backgroundTitle: "#542f61",
    },
    {
      title: "Procesos por asunto y estado (top 5)",
      options: optionsBarChartAsuntoState,
      backgroundTitle: "#542f61",
    },
  ];

  const chartProcesosPorAnio = [
    {
      title: "Procesos por año y estado",
      options: optionsColumnChart,
      backgroundTitle: "#18a48c",
    },
  ];

  const wordCloudAsuntos = useWordCloudChart();

  const chartsProcesosEvolucion = [
    {
      title: "Procesos por año",
      options: optionsPackedBubble,
      backgroundTitle: "#18a48c",
    },
    {
      title: "Asuntos más tratados (Top 10)",
      // options: optionsBubbleChartAsuntos,
      options: wordCloudAsuntos.optionsWordCloud,
      backgroundTitle: "#18a48c",
    },
  ];

  const chartsEvolutionsSentenciasFallo = [
    {
      title: "Evolución de procesos por año y sexo del magistrado",
      options: optionsBubbleChartMagYear,
      backgroundTitle: "#542f61",
    },
    {
      title: "Evolucion de fallos por sentido de fallo/sexo de magistrado",
      // options: optionsBubbleChartMagFailure,
      options: stackedColumnMagistrado.options,
      backgroundTitle: "#542f61",
    },
  ];

  return (
    <div className="caso-container caso-container-size">
      <Filter
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        provinciasFilter={provinciasFilter}
        setProvinciaFilter={setProvinciaFilter}
        provinciaFilter={provinciaFilter}
        cantonsFilter={cantonsFilter}
        cantonFilter={cantonFilter}
        setCantonFilter={setCantonFilter}
        dependenciasFilter={dependenciasFilter}
        setDependenciaFilter={setDependenciaFilter}
        dependenciaFilter={dependenciaFilter}
        doFilter={doFilter}
        asuntosFilter={asuntosFilter}
        setAsuntoFilter={setAsuntoFilter}
      />
      {filter && (
        <div className={"mt-2 pt-2 mb-4"}>
          {/*<ChartWrapper*/}
          {/*  charts={chartsDuracionDependenciaNacional}*/}
          {/*  // title={"Tiempo de resolución de sentencias"}*/}
          {/*/>       */}
          <ChartWrapper
            charts={chartsDuracionFixed}
            chartTitle={{
              title: "Análisis estadístico de resolución",
              subtitle: "Tiempo de resolución de sentencias",
              fontSize: isMobile ? 14 : 24,
              textTransform: "uppercase",
              showLine: true,
              margin: 1,
              padding: 1,
              customClass: "mb-4 pb-4",
            }}
            // title={"Tiempo de resolución de sentencias"}
          />
          <ChartWrapper
            charts={chartSentidoDelFallo}
            chartTitle={{
              title: "Cantidad de sentencias por sentido del fallo.",
              fontSize: isMobile ? 14 : 24,
              textTransform: "uppercase",
              showLine: true,
              margin: 4,
              padding: 4,
              customClass: "mt-4 pt-4 mb-4 pb-4",
            }}
          />
          <ChartWrapper
            charts={chartsTemasSentidoDelFallo}
            chartTitle={{
              title:
                "Temas con más resoluciones por sentido del fallo (Top 5).",
              fontSize: isMobile ? 14 : 24,
              textTransform: "uppercase",
              showLine: true,
              margin: 4,
              padding: 4,
              customClass: "mt-4 pt-4 mb-4 pb-4",
            }}
          />
          <ChartWrapper
            charts={chartsSentenciasProcesos}
            customClass={"mt-4 mb-4"}
          />
          <ChartWrapper
            charts={chartsProcesosEvolucion}
            customClass={"mt-4 mb-4"}
          />
          <ChartWrapper
            charts={chartProcesosPorAnio}
            customClass={"mt-4 mb-4"}
          />
          <ChartWrapper
            charts={chartsEvolutionsSentenciasFallo}
            customClass={"mt-4 mb-4"}
          />

          <ProcessTable
            data={data}
            page={page}
            cantProcess={cantProcess}
            handleCloseModal={handleClose}
            handleTableChange={handleTableChange}
            processActual={processActual}
            processDetalle={processDetalle}
            sizePerPage={sizePerPage}
            show={show}
          />
        </div>
      )}
    </div>
  );
};

export default DependenciaView;
