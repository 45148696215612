import { useState } from "react";

/**
 * Custom Hook para configurar mensajes de tipo snackbar
 * @function useSnackbar
 */
export const useSnackbar = () => {
  const initialState = {
    show: false,
    message: "",
    onClose: () => {},
  };
  const [snackbar, setSnackbar] = useState(initialState);
  const [open, setOpen] = useState(false);

  /**
   * [Utilizar esta función para cerrar las alertas.]
   * @function closeAlert
   * @param {object} oldState - Configuracion de la alerta.
   */
  const closeSnackbar = (oldState) => {
    setSnackbar(Object.assign(oldState, { show: false }));
    setOpen(false);
  };

  /**
   * Funcion base para configurar una alerta snackbar.
   * @function showSnackbar
   * @param {number} [autoHideDuration] - Tiempo en el que se va a cerrar el mensaje (en ms)(opcional)
   * @param {function} [handleClose] - Funcion a ejecutar para cerrar el snackbar. (opcional)
   * Se ejecuta al hacer click sobre la pantalla o transcurridos los ms de {autoHideDuration}
   * @param {string} message - Mensaje a mostrar en el snackbar
   * @param {boolean} [error] - Determina si es un mensaje de error (cambia el startIcon)(opcional)
   * @param {JSX.Element} [startIcon] - Icono a mostrar a la izquierda del mensaje(opcional)
   */
  const showSnackbar = ({
    title,
    message,
    variant = "light",
    animation = true,
    autohide = true,
    delay = 3000,
    onClose,
    position = "top-end",
  }) => {
    const config = {
      title,
      message,
      variant,
      animation,
      autohide,
      delay,
      onClose,
      position,
      show: true,
    };
    Object.assign(config, {
      onClose: onClose || (() => closeSnackbar(config)),
    });
    setSnackbar(config);
    setOpen(true);
  };

  return {
    showSnackbar,

    setOpen,
    snackbar,
    open,
  };
};

export default useSnackbar;
