import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { sleep } from "helpers/sleep";
import { validateEmail } from "helpers/Text";
import { AuthService } from "services/AuthService";

import useRequest from "hooks/useRequest";
import useSnackbar from "hooks/UseSnackbar";
import useModal from "hooks/UseModal";

import Modal from "components/molecules/Modal";
import Toast from "components/atoms/Toast";
import LoginBox from "components/organisms/LoginBox";
import LoginLayout from "components/templates/LoginLayout";

import "./styles.scss";

const ResetPasswordRequestView = () => {
  const { beforeSubmit, afterSubmit, errors, setErrors, loadingSpinner } =
    useRequest();

  const [email, setEmail] = useState("");

  const [disabled, setDisabled] = useState(false);

  const { snackbar, showSnackbar } = useSnackbar();
  const { modal, openModal } = useModal();

  const handleChangeEmail = (e) => setEmail(e.target.value);

  const validateEmailField = () => {
    const e = { email: [] };
    (e.length < 8 || !validateEmail(email)) &&
      Object.assign(e, {
        new_password: e.email.concat(["El correo electrónico no es válido"]),
      });

    setErrors({ ...errors, ...e });

    return validateEmail(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    if (validateEmailField()) {
      setDisabled(true);
      await sleep(500);
      beforeSubmit();
      await AuthService.requestResetPassword({ email: email })
        .then(() => {
          openModal({
            title: "Solicitud exitosa",
            message: `Hemos enviado un correo a ${email}. Debería recibir un correo electrónico con instrucciones para restablecerla`,
            autohide: false,
          });
          afterSubmit();
        })
        .catch(() => {
          setDisabled(false);
          showSnackbar({
            title: "Error",
            autohide: false,
            message:
              "Ha ocurrido un al enviar correo electrónico. Por favor intente nuevamente en unos minutos",
          });
          afterSubmit();
        });
    }
  };

  const fields = [
    {
      label: "Te enviaremos un enlace de recuperación a",
      name: "email",
      placeholder: "Escriba su e-mail",
      value: email,
      type: "email",
      onChange: handleChangeEmail,
      onKeyDown: (e) => e.keyCode === 13 && handleSubmit(e),
      fullWidth: true,
      error: errors.non_field_errors || errors.new_password,
      disabled: disabled,
    },
  ];

  const loginButton = {
    label: "RECUPERAR CLAVE",
    handleClick: async (e) => {
      await handleSubmit(e);
    },
    disabled: disabled,
    loading: loadingSpinner,
  };

  const loginForm = {
    fields,
    loginButton,
    className: "login-form-reset-password-container",
    loginHeader: { title: "Recuperación de Clave" },
    fullWidth: true,
  };

  return (
    <LoginLayout>
      <LoginBox
        loginForm={loginForm}
        showSocial={false}
        className={"reset-password-container"}
      />
      <Toast {...snackbar} />
      <Modal {...modal} adaptHeight />
    </LoginLayout>
  );
};

export default withTranslation()(ResetPasswordRequestView);
