import React from "react";

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import Table from "components/organisms/Table";

const RemoteTable = ({
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        alwaysShowAllBtns: true,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <Table
            remote
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            extraProps={paginationTableProps}
          />
          <div className="table-pagination">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

export default RemoteTable;
