/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useStorePersist } from "store/context";
import { fetchTableData, initialState } from "helpers/Table";
import { apiCalls } from "./utils/apiCalls";
import { formatDate, formatDateYYYMMMDDD } from "utils/formatDate";

import useRequest from "hooks/useRequest";

import { useTimeLineChart } from "./hooks/UseTimeLineChart";
import { useOpenDetail } from "hooks/UseOpenDetail";

import { useBarChart } from "./hooks/UseBarChart";
import { usePieChart } from "./hooks/UsePieChart";
import { usePackedBubble } from "./hooks/UsePackedBubble";
import { useGaugeChart } from "./hooks/UseGaugeChart";
import { useBubbleChartAsuntos } from "views/Analytics/pages/Analytic/hooks/UseBubbleChartAsuntos";

import User from "models/User";

import { AuthService } from "services/AuthService";

import SolidGaugeChart from "components/molecules/SolidGaugeChart";
import TimeLineChart from "components/molecules/TimeLineChart";
import ChartGroup from "components/organisms/ChartGroup";
import MagistradoDetail from "views/Analytics/components/organisms/MagistradoDetail";
import ProcessTable from "views/Analytics/components/organisms/ProcessTable";

import "./styles.scss";
import Filter from "./components/Filter";
import DatesFilter from "./components/DatesFilter";
import ChartWrapper from "components/organisms/ChartWrapper";

import { useWordCloudChart } from "./hooks/UseWordCloudChart";
import { isMobile } from "helpers/Mobile";

const MagistradoView = () => {
  const [{}, dispatch] = useStorePersist();
  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();
  const history = useHistory();

  const getErrorfromBack = (error) => {
    dealWithError(error, "Error");
    afterSubmit();
  };

  const [juez, setJuez] = useState();
  const [resoluciones, setResoluciones] = useState();

  const [endDate, setEndDate] = useState(new Date("Dec 30,2021"));
  const [startDate, setStartDate] = useState(
    new Date(Date.parse("Jan 01, 2016"))
  );

  const [dataTable, setDataTable] = useState([]);
  const [cantProcess, setCantProcess] = useState(0);

  const [juezFilter, setJuezFilter] = useState(1);
  const [juecesFilter, setJuecesFilter] = useState([]);

  const { optionsTimeLine, setOptionsTimeLine } = useTimeLineChart();

  const [sentenciasRecurridas, setSentenciasRecurridas] = useState();
  const [recursosEstimados, setRecursosEstimados] = useState();
  const [acuerdo, setAcuerdo] = useState();
  const [favorable, setFavorable] = useState();
  const [desFavorable, setDesFavorable] = useState();

  const [filter, setFilter] = useState(false);

  const [acuerdoTop5, setAcuerdoTop5] = useState();
  const [favorableTop5, setFavorableTop5] = useState();
  const [desFavorableTop5, setDesFavorableTop5] = useState();

  const [max, setMax] = useState();
  const [media, setMedia] = useState();
  const [min, setMin] = useState();

  const [filtrado, setFiltrado] = useState(false);
  const { optionsPackedBubble, setOptionsPackedBubble } = usePackedBubble();

  const { optionsBubbleChartAsuntos, setOptionsBubbleChartAsuntos } =
    useBubbleChartAsuntos();
  const [asuntos, setAsuntos] = useState();

  const {
    optionsGaugeChart: optionsGaugeChartMax,
    setOptionsGaugeChart: setOptionsGaugeChartMax,
  } = useGaugeChart("");
  const {
    optionsGaugeChart: optionsGaugeChartMedia,
    setOptionsGaugeChart: setOptionsGaugeChartMedia,
  } = useGaugeChart("");
  const {
    optionsGaugeChart: optionsGaugeChartMin,
    setOptionsGaugeChart: setOptionsGaugeChartMin,
  } = useGaugeChart("");

  const {
    optionsPieChart: optionsPieChartSentidoFallo,
    setOptionsPieChart: setOptionsPieChartSentidoFallo,
  } = usePieChart(""); // Sentido del Fallo

  const {
    optionsPieChart: optionsPieChartFavorableTop5,
    setOptionsPieChart: setOptionsPieChartFavorableTop5,
  } = usePieChart(""); // Favorable

  const {
    optionsPieChart: optionsPieChartDesFavorableTop5,
    setOptionsPieChart: setOptionsPieChartDesFavorableTop5,
  } = usePieChart(""); // Desfavorable

  const {
    optionsPieChart: optionsPieChartAcuerdoTop5,
    setOptionsPieChart: setOptionsPieChartAcuerdoTop5,
  } = usePieChart(""); // Acuerdo

  const yAxis = { title: "Cantidad" };

  const {
    optionsBarChart: optionsBarChartAsuntoState,
    setOptionsBarChart: setOptionsBarChartAsuntoState,
  } = useBarChart("", yAxis, true); // Procesos por asunto (top 5)

  const columns = [
    { dataField: "FechaIngreso", text: "Fecha Ingreso", sort: true },
    { dataField: "Proceso", text: "N° Proceso", sort: true },
    { dataField: "AccionInfraccion", text: "Acción/Infracción", sort: true },
    { dataField: "pdf", text: "Detalle" },
  ];

  const [state, setState] = useState(initialState);

  const [processDetalle, setProcessDetalle] = useState([]);
  const [processActual, setProcessActual] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setProcessDetalle([]);
  };
  const handleOpen = () => setShow(true);

  const wordCloudAsuntos = useWordCloudChart();

  const { data, sizePerPage, page } = state;
  const { handleOpenDetail } = useOpenDetail();
  const handleTableChange = async (type, { page, sizePerPage }) => {
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter ? juezFilter : 1,
      added_function: "MAX",
      en_favor: "OFENDIDO",
      page: page,
    };
    beforeSubmit();
    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });
    afterSubmit();
  };

  const loadCharts = async (filters) => {
    /** Analitica JUEZ/A: Duracion hay un use effect mas abajo para consumir la duracion nacional*/
    await apiCalls.getDurationMax(setMax, getErrorfromBack, filters);
    await apiCalls.getDurationMedia(setMedia, getErrorfromBack, filters);
    await apiCalls.getDurationMin(setMin, getErrorfromBack, filters);

    /**  Analitica JUEZ/A: Sentido del Fallo */
    await apiCalls.getSentidoFallo(setFavorable, getErrorfromBack, filters);
    apiCalls.getSentidoFallo(setDesFavorable, getErrorfromBack, {
      ...filters,
      en_favor: "DEMANDADO",
    });
    apiCalls.getSentidoFallo(setAcuerdo, getErrorfromBack, {
      ...filters,
      en_favor: "ACUERDO ENTRE PARTES",
    });

    /** Analitica JUEZ/A: Temas de fallos (Top 5) */
    await apiCalls.getTemaFalloTop5(
      setFavorableTop5,
      getErrorfromBack,
      filters
    );
    await apiCalls.getTemaFalloTop5(setDesFavorableTop5, getErrorfromBack, {
      ...filters,
      en_favor: "DEMANDADO",
    });
    await apiCalls.getTemaFalloTop5(setAcuerdoTop5, getErrorfromBack, {
      ...filters,
      en_favor: "ACUERDO ENTRE PARTES",
    });

    /** Analitica JUEZ/A: Sentencias recurridas y recursos estimados */
    await apiCalls.getSentenciasRecurridas(
      setSentenciasRecurridas,
      getErrorfromBack,
      filters
    );
    await apiCalls.getRecursosEstimados(
      setRecursosEstimados,
      getErrorfromBack,
      filters
    );

    /** Analitica JUEZ/A: Evolucion de asuntos por año hay un useeffect en la linea 170 que tambien es para este */
    await apiCalls.getAsuntos(setAsuntos, getErrorfromBack);

    /** Analitica JUEZ/A: Procesos por año */
    await apiCalls.getPorcessPerYear(
      optionsPackedBubble,
      setOptionsPackedBubble,
      getErrorfromBack,
      filters
    );

    /** Analitica JUEZ/A: Procesos por asunto y estadoi (Top 5) */
    await apiCalls.getProcessPerAsuntoStateJuez(
      optionsBarChartAsuntoState,
      setOptionsBarChartAsuntoState,
      getErrorfromBack,
      filters
    );
  };

  const bannerDispatcher = {
    type: "setBanner",
    payload: {
      title: "Magistrado",
    },
  };
  useEffect(() => {
    dispatch(bannerDispatcher);

    const currentUser = new User(AuthService.currentUserValue());
    const activeSubscription = currentUser?.activeSubscription();

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }
    /** Selects */
    apiCalls.getJuecesFilter(setJuecesFilter, getErrorfromBack);
  }, []);

  useEffect(() => {
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter ? juezFilter : 1,
      added_function: "MAX",
      en_favor: "OFENDIDO",
    };

    asuntos &&
      apiCalls.getWordCloudAsuntosMasTratados(
        wordCloudAsuntos,
        getErrorfromBack,
        newFilters
      );
  }, [asuntos]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter || "-1",
      added_function: "MAX",
    };
    (max || max === 0) &&
      apiCalls.getDurationNacionalMax(
        optionsGaugeChartMax,
        setOptionsGaugeChartMax,
        max,
        getErrorfromBack,
        filters
      );
  }, [max]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter || "-1",
      added_function: "AVG",
    };
    (media || media === 0) &&
      apiCalls.getDurationNacionalMedia(
        optionsGaugeChartMedia,
        setOptionsGaugeChartMedia,
        media,
        getErrorfromBack,
        filters
      );
  }, [media]);

  useEffect(() => {
    let filters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter || "-1",
      added_function: "MIN",
    };
    (min || min === 0) &&
      apiCalls.getDurationNacionalMin(
        optionsGaugeChartMin,
        setOptionsGaugeChartMin,
        min,
        getErrorfromBack,
        filters
      );
  }, [min]);

  useEffect(() => {
    if (favorable && desFavorable && acuerdo) {
      setOptionsPieChartSentidoFallo({
        ...optionsPieChartSentidoFallo,
        series: [
          {
            name: "Share",
            data: [
              { name: "A favor del Ofendido", y: favorable[0].cantidad },
              { name: "A favor del Demandado", y: desFavorable[0].cantidad },
              { name: "Acuerdo entre partes", y: acuerdo[0].cantidad },
            ],
          },
        ],
      });
    }
  }, [favorable, desFavorable, acuerdo]);

  useEffect(() => {
    if (favorableTop5) {
      setOptionsPieChartFavorableTop5({
        ...optionsPieChartFavorableTop5,
        series: [
          {
            name: "Share",
            data: favorableTop5.map((fav) => {
              return { name: fav.TemaFallo, y: fav.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5]);

  useEffect(() => {
    if (desFavorableTop5) {
      setOptionsPieChartDesFavorableTop5({
        ...optionsPieChartDesFavorableTop5,
        series: [
          {
            name: "Share",
            data: desFavorableTop5.map((desfav) => {
              return { name: desfav.TemaFallo, y: desfav.cantidad };
            }),
          },
        ],
      });
    }
  }, [desFavorableTop5]);

  useEffect(() => {
    if (acuerdoTop5) {
      setOptionsPieChartAcuerdoTop5({
        ...optionsPieChartAcuerdoTop5,
        series: [
          {
            name: "Share",
            data: acuerdoTop5.map((ac) => {
              return { name: ac.TemaFallo, y: ac.cantidad };
            }),
          },
        ],
      });
    }
  }, [favorableTop5, desFavorableTop5, acuerdoTop5]);

  const doFilter = async (e) => {
    e.preventDefault();
    setFiltrado(false);
    beforeSubmit();

    await apiCalls.getJuez(
      setJuez,
      getErrorfromBack,
      juezFilter ? juezFilter : 1
    );
    const filters = {
      id_juez: juezFilter ? juezFilter : 1,
    };
    await apiCalls.getHojaRuta(
      optionsTimeLine,
      setOptionsTimeLine,
      getErrorfromBack,
      filters
    );
    /** Analitica JUEZ/A: Resoluciones desde */
    await apiCalls.getResoluciones(setResoluciones, getErrorfromBack, filters);

    afterSubmit();
    setFilter(true);
  };

  const doFilterFull = async (e) => {
    e.preventDefault();
    beforeSubmit();
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      id_juez: juezFilter ? juezFilter : 1,
      added_function: "MAX",
      en_favor: "OFENDIDO",
      page: 1,
    };
    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });
    await loadCharts(newFilters);
    afterSubmit();
    setFilter(true);
    setFiltrado(true);
  };

  const magistradoData = [
    { label: "Juez/a", value: juez && juez.Juez },
    {
      label: "Puesto institucional",
      value: juez && juez.PuestoInstitucional,
    },
    {
      label: "Mail insititucional",
      value: juez && juez.MailInstitucional,
    },
    {
      label: "Unidad a la que pertenece",
      value: juez && juez.Unidad,
    },
    {
      label: "Dirección institucional",
      value: juez && juez.DireccionInstitucional,
    },
    {
      label: "Unidad en la que labora",
      value: juez && juez.CiudadInstitucional,
    },
    {
      label: "Teléfono institucional",
      value: juez && juez.TelefonoInstitucional,
    },
  ];

  const chartsSentenciasProcesos = [
    {
      title: "Trayectoria Registrada",
      options: optionsTimeLine,
      chart: TimeLineChart,
      footerText: {
        title: `Resoluciones desde: ${formatDateYYYMMMDDD(startDate)}`,
        fontSize: 18,
      },
      backgroundTitle: "#542F61",
    },
  ];

  const chartsDuracionDependenciaNacional = [
    {
      title: "Tiempo Mínimo",
      options: optionsGaugeChartMin,
      backgroundTitle: "#18a48c",
      xs: 4,
    },
    {
      title: "Tiempo Media",
      options: optionsGaugeChartMedia,
      backgroundTitle: "#18a48c",
      xs: 4,
    },
    {
      title: "Tiempo Máximo",
      options: optionsGaugeChartMax,
      backgroundTitle: "#18a48c",
      xs: 4,
    },
  ];

  const chartSentidoDelFallo = [
    {
      title: "Sentido del Fallo",
      options: optionsPieChartSentidoFallo,
      backgroundTitle: "#542f61",
    },
  ];

  const chartsTemasSentidoDelFallo = [
    {
      title: "A favor del Ofendido",
      options: optionsPieChartFavorableTop5,
      backgroundTitle: "#18a48c",
    },
    {
      title: "A favor del Demandado",
      options: optionsPieChartDesFavorableTop5,
      backgroundTitle: "#18a48c",
    },
    {
      title: "Acuerdo entre partes",
      options: optionsPieChartAcuerdoTop5,
      backgroundTitle: "#18a48c",
    },
  ];

  const optionBundleNormalize = (optionsPackedBubble) => {
    const { series, ...allValues } = optionsPackedBubble;
    return { ...allValues, series: series?.sort((a, b) => a.name - b.name) };
  };

  const chartsProcesosEvolucion = [
    {
      title: "Procesos por año",
      options: optionBundleNormalize(optionsPackedBubble),
      backgroundTitle: "#542f61",
    },
    {
      title: "Asuntos más tratados (Top 10)",
      options: wordCloudAsuntos.optionsWordCloud,
      backgroundTitle: "#542f61",
    },
  ];

  const chartProcessAsunto = [
    {
      title: "Procesos por asunto y estado (top 5)",
      options: optionsBarChartAsuntoState,
      backgroundTitle: "#18a48c",
    },
  ];

  return (
    <div className="caso-container">
      <Filter
        doFilter={doFilter}
        setJuezFilter={setJuezFilter}
        juezFilter={juezFilter}
        juecesFilter={juecesFilter}
      />
      {filter && (
        <div className={"mt-4"}>
          <MagistradoDetail data={magistradoData} />
          <ChartWrapper charts={chartsSentenciasProcesos} />

          <DatesFilter
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            doFilterFull={doFilterFull}
          />
          <div style={{ display: !filtrado ? "none" : "block" }}>
            <div className="text-left analisis-duracion">
              <ChartWrapper
                charts={chartsDuracionDependenciaNacional}
                title={"Tiempo de resolución de sentencias"}
                chartTitle={{
                  fontSize: isMobile ? 14 : 24,
                  textTransform: "uppercase",
                  showLine: true,
                  margin: 1,
                  padding: 1,
                  customClass: "mb-4 pb-4",
                }}
              />
              <ChartWrapper charts={chartSentidoDelFallo} />
              <ChartWrapper
                charts={chartsTemasSentidoDelFallo}
                title={
                  "Temas con más resoluciones por sentido del fallo (Top 5)"
                }
                chartTitle={{
                  fontSize: isMobile ? 14 : 24,
                  textTransform: "uppercase",
                  showLine: true,
                  margin: 1,
                  padding: 1,
                  customClass: "mb-4 pb-4",
                }}
              />

              <ChartWrapper charts={chartProcessAsunto} />
            </div>

            <ProcessTable
              data={data}
              page={page}
              cantProcess={cantProcess}
              handleCloseModal={handleClose}
              handleTableChange={handleTableChange}
              processActual={processActual}
              processDetalle={processDetalle}
              sizePerPage={sizePerPage}
              show={show}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MagistradoView;
