import { apiCalls } from "./apiCalls";

export const loadCharts = ({
  setAsuntosFilter,
  setMateriasFilter,
  setProcedimientosFilter,
  setProvinciasFilter,
  setCantonsFilter,
  setDependenciasFilter,
  setFavorableTop5,
  setDesFavorableTop5,
  setAcuerdoTop5,
  setFavorable,
  setDesFavorable,
  setAcuerdo,
  getErrorfromBack,
  setMax,
  setMedia,
  setMin,
  optionsBubbleChartMagFailure,
  setOptionsBubbleChartMagFailure,
  optionsBubbleChartMagYear,
  setOptionsBubbleChartMagYear,
  optionsBarChartMag,
  setOptionsBarChartMag,
  optionsBarChartAsuntoState,
  setOptionsBarChartAsuntoState,
  optionsColumnChart,
  setOptionsColumnChart,
  setAsuntos,
  optionsPackedBubble,
  setOptionsPackedBubble,
  afterSubmit,
  filters,
  stackedColumnMagistrado,
}) => {
  /** Selects */
  // apiCalls.getAsuntosFilter(setAsuntosFilter, getErrorfromBack);
  // apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
  // apiCalls.getProcedimientosFilter(setProcedimientosFilter, getErrorfromBack);
  // apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
  // apiCalls.getCantonsFilter(setCantonsFilter, getErrorfromBack);
  // apiCalls.getDependenciasFilter(setDependenciasFilter, getErrorfromBack);

  /** Analitica del DEPENDENCIA: Temas de fallos (Top 5) */
  apiCalls.getTemaFalloTop5(setFavorableTop5, getErrorfromBack, filters);
  apiCalls.getTemaFalloTop5(setDesFavorableTop5, getErrorfromBack, {
    ...filters,
    en_favor: "DEMANDADO",
  });
  apiCalls.getTemaFalloTop5(setAcuerdoTop5, getErrorfromBack, {
    ...filters,
    en_favor: "ACUERDO ENTRE PARTES",
  });

  apiCalls.getSentidoFallo(setFavorable, getErrorfromBack, filters);
  /** Analitica del DEPENDENCIA: Sentido del Fallo */
  apiCalls.getSentidoFallo(setDesFavorable, getErrorfromBack, {
    ...filters,
    en_favor: "DEMANDADO",
  });
  apiCalls.getSentidoFallo(setAcuerdo, getErrorfromBack, {
    ...filters,
    en_favor: "ACUERDO ENTRE PARTES",
  });

  /** Analitica del DEPENDENCIA Duracion hay un use effect mas abajo para consumir la duracion nacional*/
  apiCalls.getDurationMax(setMax, getErrorfromBack, filters);
  apiCalls.getDurationMedia(setMedia, getErrorfromBack, filters);
  apiCalls.getDurationMin(setMin, getErrorfromBack, filters);

  // /** Analitica del DEPENDENCIA: Evolucion de fallos por sexo de magistrado */
  // apiCalls.getProcessPerFailureMagis(
  //   optionsBubbleChartMagFailure,
  //   setOptionsBubbleChartMagFailure,
  //   getErrorfromBack,
  //   filters
  // );
  //
  /** Analitica del DEPENDENCIA: Evolucion de fallos por sexo de magistrado */
  apiCalls.getStackedColumnSentidoDelFalloSexoMagistrado(
    stackedColumnMagistrado,
    getErrorfromBack,
    filters
  );

  /** Analitica del DEPENDENCIA: Evolucion de procesos por año y sexo de magistrado */
  apiCalls.getProcessPerYearMagis(
    optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear,
    getErrorfromBack,
    filters
  );

  /** Analitica del DEPENDENCIA: Magistrados con mas resoluciones dictadas (Top 5) */
  apiCalls.getMagistradosTop5(
    optionsBarChartMag,
    setOptionsBarChartMag,
    getErrorfromBack,
    filters
  );

  /** Analitica del DEPENDENCIA: Evolucion de asuntos por años */
  apiCalls.getProcessPerAsuntoStateDependencia(
    optionsBarChartAsuntoState,
    setOptionsBarChartAsuntoState,
    getErrorfromBack,
    filters
  );

  /** Analitica del DEPENDENCIA: Procesos por año y por estado (iniciado, resuelto) */
  apiCalls.getProcessPerYearState(
    optionsColumnChart,
    setOptionsColumnChart,
    getErrorfromBack,
    filters
  );

  apiCalls.getAsuntos(setAsuntos, getErrorfromBack);

  /** Analitica del DEPENDENCIA: Procesos por año */
  apiCalls.getPorcessPerYearDependencia(
    optionsPackedBubble,
    setOptionsPackedBubble,
    getErrorfromBack,
    filters,
    afterSubmit
  );
};
