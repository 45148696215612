import React from "react";

import { Form } from "react-bootstrap";

import ChartContainer from "components/organisms/ChartContainer";
import Input from "components/atoms/Input";

import "./styles.scss";

const MagistradoDetail = ({ data }) => {
  return (
    <div className="container-table">
      <ChartContainer
        childrenClassName={"justify-content-center align-items-center"}
      >
        <div className={"magistrado-detail-container"}>
          {data.map((field, i) => (
            <div className="magistrado-item" key={i}>
              <Form.Label>{field.label}</Form.Label>
              <Input disabled value={field.value} />
            </div>
          ))}
        </div>
      </ChartContainer>
    </div>
  );
};

export default MagistradoDetail;
