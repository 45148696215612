import React from "react";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const Search = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push("/search");
  };

  return (
    <div className="search-container" onClick={handleOnClick}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: "absolute",
          right: "8px",
          top: "9px",
          fontSize: 32,
        }}
      />
    </div>
  );
};
export default Search;
