import React from "react";
import { withTranslation } from "react-i18next";

import "./styles.scss";

const Home = (props) => {
  return <h1>HOME</h1>;
};

export default withTranslation()(Home);
