import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { Spinner } from "react-bootstrap";

import { AuthService } from "services/AuthService";
import useRequest from "hooks/useRequest";

import useSnackbar from "hooks/UseSnackbar";
import useModal from "hooks/UseModal";

import ActionButton from "components/atoms/ActionButton";
import LoginHeader from "components/atoms/LoginHeader";
import Toast from "components/atoms/Toast";
import Modal from "components/molecules/Modal";
import Box from "components/organisms/Box";
import LoginLayout from "components/templates/LoginLayout";

import "./styles.scss";

const ConfirmAccountView = (props) => {
  const history = useHistory();
  const { user_id, confirmation_token } = useParams();

  const { beforeSubmit, afterSubmit } = useRequest();

  const [loaded, setLoaded] = useState(false);
  const initialBody = "Validando correo electrónico";
  const [body, setBody] = useState(initialBody);

  const { snackbar } = useSnackbar();
  const { modal } = useModal();

  useEffect(() => {
    beforeSubmit();
    AuthService.validateAccount({ user_id, confirmation_token })
      .then((r) => {
        afterSubmit();
        setBody("Su correo electrónico ha sido confirmado exitosamente.");
        setLoaded(true);
      })
      .catch((e) => {
        afterSubmit();
        setBody("El token ha expirado o no es válido.");
        setLoaded(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectLogin = () => history.push("/login");

  const loginHeader = { title: "Confirmación de correo" };

  return (
    <LoginLayout>
      <Box maxWidth={true}>
        <div className={"confirmation-box-container"}>
          <LoginHeader {...loginHeader} />
          <div className={"confirmation-box-body"}>
            <p>{body}</p>
            {!loaded && (
              <Spinner
                animation={"border"}
                className={"confirmation-box-spinner"}
              />
            )}
          </div>
          <div className={"confirmation-box-button"}>
            <ActionButton
              legend={"VOLVER"}
              disabled={!loaded}
              handleClick={() => redirectLogin()}
              className={loaded ? "" : "login-button-disabled"}
            />
          </div>
        </div>
      </Box>
      <Toast {...snackbar} />
      <Modal {...modal} />
    </LoginLayout>
  );
};

export default withTranslation()(ConfirmAccountView);
