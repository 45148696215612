import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { AuthService } from "services/AuthService";
import useRequest from "hooks/useRequest";

import LoginBox from "components/organisms/LoginBox";
import LoginLayout from "components/templates/LoginLayout";
import Toast from "components/atoms/Toast";
import Modal from "components/molecules/Modal";

import useSnackbar from "hooks/UseSnackbar";
import useModal from "hooks/UseModal";

import "./styles.scss";

const ResetPasswordView = (props) => {
  const history = useHistory();
  const { token } = useParams();

  const { beforeSubmit, afterSubmit, errors, setErrors } = useRequest();

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [disabled, setDisabled] = useState(true);

  const { snackbar, showSnackbar } = useSnackbar();
  const { modal, openModal } = useModal();

  useEffect(() => {
    beforeSubmit();
    AuthService.validateTokenResetPassword({ token: token })
      .then((r) => {
        afterSubmit();
        setDisabled(false);
        AuthService.logout();
      })
      .catch((e) => {
        afterSubmit();
        showSnackbar({
          title: "Ha ocurrido un error",
          message: "El token no es válido",
          autohide: false,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeNewPassword = (e) => setNewPassword(e.target.value);
  const handleChangeNewPasswordConfirm = (e) =>
    setNewPasswordConfirm(e.target.value);

  const validatePasswords = () => {
    const e = { new_password: [], confirm_password: [] };
    let valid = true;
    if (newPassword.length < 8) {
      Object.assign(e, {
        new_password: e.new_password.concat([
          "La contraseña debe tener al menos 8 caracteres",
        ]),
      });
      valid = false;
    }
    if (newPasswordConfirm.length < 8) {
      Object.assign(e, {
        confirm_password: e.confirm_password.concat([
          "La contraseña debe tener al menos 8 caracteres",
        ]),
      });
      valid = false;
    }

    if (
      Boolean(newPassword) &&
      Boolean(newPasswordConfirm) &&
      newPassword !== newPasswordConfirm
    ) {
      Object.assign(e, {
        new_password: e.new_password.concat(["Las contraseñas no coinciden"]),
        confirm_password: e.confirm_password.concat([
          "Las contraseñas no coinciden",
        ]),
      });
      valid = false;
    }
    setErrors({ ...errors, ...e });
    return valid;
  };

  const redirectLogin = () => history.push("/login");

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    if (validatePasswords()) {
      beforeSubmit();
      setDisabled(true);
      AuthService.resetPassword(token, newPassword)
        .then((r) => {
          afterSubmit();
          openModal({
            title: "Cambio de contraseña exitoso",
            message:
              "Su contraseña ha sido actualizada exitosamente. Por favor inicié sesión para continuar",
            handleClose: () => redirectLogin(),
          });
        })
        .catch((error) => {
          afterSubmit();
          setDisabled(false);
          error.json().then((data) => {
            if (data.password) {
              setErrors({ ...errors, new_password: data.password });
            } else {
              showSnackbar({
                title: "Ha ocurrido un error",
                message: "El token no es válido",
                autohide: false,
              });
            }
          });
        });
    }
  };

  const fields = [
    {
      label: "Contraseña nueva",
      name: "new-password",
      placeholder: "*********",
      value: newPassword,
      type: "password",
      onChange: handleChangeNewPassword,
      onKeyDown: (e) => e.keyCode === 13 && handleSubmit(e),
      fullWidth: true,
      error: errors.non_field_errors || errors.new_password,
      disabled: disabled,
    },
    {
      label: "Confirma contraseña nueva",
      name: "confirm-password",
      placeholder: "*********",
      value: newPasswordConfirm,
      type: "password",
      onChange: handleChangeNewPasswordConfirm,
      onKeyDown: (e) => e.keyCode === 13 && handleSubmit(e),
      fullWidth: true,
      error: errors.non_field_errors || errors.confirm_password,
      disabled: disabled,
    },
  ];

  const loginButton = {
    label: "ACEPTAR",
    handleClick: handleSubmit,
    disabled: disabled,
  };

  const loginForm = {
    fields,
    loginButton,
    className: "login-form-reset-password-container",
    loginHeader: { title: "Cambio de Clave" },
  };

  return (
    <LoginLayout>
      <LoginBox
        loginForm={loginForm}
        showSocial={false}
        className={"reset-password-container"}
      />
      <Toast {...snackbar} />
      <Modal {...modal} />
    </LoginLayout>
  );
};

export default withTranslation()(ResetPasswordView);
