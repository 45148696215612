import React, { useState } from "react";

import "./styles.scss";
import { FormControl, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

const Input = ({
  label,
  placeholder,
  helperText,
  name,
  type = "text",
  rows = 1,
  onChange,
  onKeyUp,
  onKeyDown,
  onInput,
  readOnly,
  value,
  fullWidth = false,
  error = [],
  disabled,
}) => {
  const [showPass, setShowPass] = useState(false);

  const input = {};
  parseInt(rows) > 1 && Object.assign(input, { rows: rows, as: "textarea" });

  const handleShowPassword = (e) => {
    setShowPass(!showPass);
  };

  return (
    <div className={"input-root"}>
      {label && <span className={"input-label"}>{label}</span>}
      <InputGroup
        className={clsx({
          ["input-group input-group-root"]: true, // eslint-disable-line no-useless-computed-key
          ["input-group-root-password"]: type === "password", // eslint-disable-line no-useless-computed-key
        })}
      >
        <FormControl
          placeholder={placeholder}
          aria-label={name}
          aria-describedby={`text-input-${name}`}
          name={name}
          readOnly={readOnly}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onInput={onInput}
          type={type === "password" ? (showPass ? "text" : type) : type}
          value={value}
          disabled={disabled}
          {...input}
          className={clsx({
            ["input-group-password"]: type === "password", // eslint-disable-line no-useless-computed-key
            ["input-group-fullwidth"]: fullWidth, // eslint-disable-line no-useless-computed-key
          })}
        />
        {type === "password" && (
          <span className="input-group-text input-group-addon">
            {showPass ? (
              <FontAwesomeIcon
                icon={faEye}
                onClick={handleShowPassword}
                className={"fa-w-20"}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                onClick={handleShowPassword}
                className={"fa-w-20"}
              />
            )}
          </span>
        )}
      </InputGroup>
      <p className={"input-helper"}>
        <span className={"input-helper-text"}>{helperText}</span>
        {error.map((e, i) => (
          <span className={" input-error"} key={i}>
            {e}{" "}
          </span>
        ))}
      </p>
    </div>
  );
};

export default Input;
