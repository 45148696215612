import { useState } from "react";
import mapDataEC from "assets/countries/ec/ec-all.geo.json";

export const useMapChart = () => {
  const [mapOptions, setMapOptions] = useState({
    credits: false,
    chart: {
      map: "countries/ec/ec-all",
    },

    title: {
      text: "", // Procesos por provincias
    },

    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },

    colorAxis: {
      min: 0,
    },

    series: [
      {
        data: [],
        mapData: mapDataEC,
        name: "Procesos",
        states: {
          hover: {
            color: "#BADA55",
          },
        },
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
      },
    ],
  });

  return { mapOptions, setMapOptions };
};
