import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import ChartTitle from "components/atoms/ChartTitle";

const ChartContainer = ({
  className,
  title,
  children,
  childrenClassName,
  footerText,
  backgroundTitle,
  fontSize,
  fontFamily,
}) => {
  return (
    <div className={`custom-box-container ${className || ""}`}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "43px",
          backgroundColor: backgroundTitle,
          paddingLeft: 23,
        }}
      >
        {title && (
          <ChartTitle
            title={title}
            margin={0}
            padding={0}
            align={"left"}
            color={"white"}
            fontSize={fontSize}
            fontFamily={fontFamily}
          />
        )}
      </div>
      <div className={childrenClassName}>{children}</div>
      {footerText && <ChartTitle {...footerText} />}
    </div>
  );
};

export default ChartContainer;
