import { DataService } from "services/DataService";
import _ from "lodash";

const sexOrder = {
  M: 0,
  F: 1,
};

/**
 *
 * BORRAR COMENTARIO CUANDO SE AGREGUE EL FILTRADO
 * @todo FALTA AGREGAR LOS FILTROS A CADA API CALL Y DESPUES ENVIARLO AL SERVICIO Ejemplo de como quedaria con los filtros
 *
 * getProvincesCaso:(mapOptions, setMapOptions, getErrorfromBack, filters) => {
 *    DataService.getProcessPerProvincesCaso(filters).then((data)=>{...}).catch((error)=>{...})
 * }
 *
 */
export const apiCalls = {
  getProvincesCaso: (mapOptions, setMapOptions, getErrorfromBack, filters) => {
    DataService.getProcessPerProvincesCaso(filters)
      .then((data) => {
        const array = mapOptions;
        array.series[0].data.map((d) => {
          d.value = null;
        });
        data.forEach((dato) => {
          array.series[0].data.map((d) => {
            if (d.name === _.startCase(_.toLower(dato.Provincia))) {
              d.value = dato.cantidad;
            }
          });
        });
        setMapOptions({
          ...mapOptions,
          series: [{ ...mapOptions.series[0], data: array.series[0].data }],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getPorcessPerYearDependencia: (
    optionsPackedBubble,
    setOptionsPackedBubble,
    getErrorfromBack,
    filters,
    afterSubmit
  ) => {
    DataService.getProcessPerYearCaso(filters)
      .then((data) => {
        const series = data.map((process) => {
          return {
            name: process.year,
            data: [
              {
                name: process.year,
                value: process.cantidad,
              },
            ],
          };
        });
        try {
          const seriesSorted = series.sort(
            (a, b) => a.name.toInteger() - b.name.toInteger()
          );
          setOptionsPackedBubble({
            ...optionsPackedBubble,
            series: seriesSorted,
          });
        } catch {
          setOptionsPackedBubble({
            ...optionsPackedBubble,
            series: series,
          });
        }

        afterSubmit();
      })
      .catch((error) => {
        afterSubmit();
        getErrorfromBack(error);
      });
  },
  getProcessPerYearMagis: (
    optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerYearMagis(filters)
      .then((data) => {
        let dataMale = data.filter(p => (p.Sexo == 'M' && p.Sexo)).map((process) => {
            return process.cantidad;
        })

        let dataFemale = data.filter(p => (p.Sexo == 'F' && p.Sexo)).map((process) => {
            return - process.cantidad;
        })

        let categories = data.filter(p => (p.Sexo)).map((process) => {
            return process.year;
        })
        categories = [...new Set(categories)];
        setOptionsBubbleChartMagYear({
          ...optionsBubbleChartMagYear,

          series: [
            {
              name: 'Masculinos',
              data: dataMale
            },
            {
              name: 'Femeninos',
              data: dataFemale
            },
          ],
          xAxis: [{
            categories: categories,
            reversed: false,
            labels: {
              step: 1
            },
            accessibility: {
              description: 'Año (Masculinos)'
            }
          }, { // mirror axis on right side
            opposite: true,
            reversed: false,
            categories: categories,
            linkedTo: 0,
            labels: {
              step: 1
            },
            accessibility: {
              description: 'Año (Femeninos)'
            }
          }],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getAsuntos: (setAsuntos, getErrorfromBack) => {
    DataService.getAsuntosOrdenados()
      .then((data) => {
        setAsuntos(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerFailureMagis: (
    optionsBubbleChartMagFailure,
    setOptionsBubbleChartMagFailure,
    getErrorfromBack
  ) => {
    DataService.getProcessPerFailureMagis({
      fecha_desde: "01-01-2016",
      fecha_hasta: "01-01-2022",
      id_asunto: 1318,
    })
      .then((data) => {
        const dataParsed = data.map((d) => {
          return [
            {
              x: 0,
              y: sexOrder[d.Sexo],
              z: d.cantFavorable,
            },
            { x: 1, y: sexOrder[d.Sexo], z: d.cantDesfavorable },
            { x: 2, y: sexOrder[d.Sexo], z: d.cantAcuerdo },
          ];
        });
        dataParsed.length &&
          setOptionsBubbleChartMagFailure({
            ...optionsBubbleChartMagFailure,
            series: [
              {
                data: [...dataParsed[0], ...dataParsed[1]],
              },
            ],
          });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerFailureMagisV2: (
    optionsBubbleChartMagFailure,
    setOptionsBubbleChartMagFailure,
    getErrorfromBack
  ) => {
    DataService.getProcessPerFailureMagis({
      fecha_desde: "01-01-2016",
      fecha_hasta: "01-01-2022",
      id_asunto: 1318,
    })
      .then((data) => {
        const cantFavorable = data.map((d) => d.cantFavorable);
        const cantDesfavorable = data.map((d) => d.cantDesfavorable);
        const cantAcuerdo = data.map((d) => d.cantAcuerdo);

        setOptionsBubbleChartMagFailure({
          ...optionsBubbleChartMagFailure,
          series: [
            {
              name: "A favor del Ofendido",
              data: cantFavorable,
            },
            {
              name: "A favor del Demandado",
              data: cantDesfavorable,
            },
            {
              name: "Acuerdo entre partes",
              data: cantAcuerdo,
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getSentenciasRecurridas: (
    setSentenciasRecurridas,
    getErrorfromBack,
    filters
  ) => {
    DataService.getSentenciasRecurridas(filters)
      .then((data) => {
        setSentenciasRecurridas(data[0].cantidad);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getRecursosEstimados: (setReucrsosEstimados, getErrorfromBack, filters) => {
    DataService.getRecursosEstimados(filters)
      .then((data) => {
        setReucrsosEstimados(data[0].cantidad);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerYearState: (
    optionsColumnChart,
    setOptionsColumnChart,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerYearStateDependencia(filters)
      .then((data) => {
        setOptionsColumnChart({
          ...optionsColumnChart,
          xAxis: {
            categories: data.map((d) => d.year),
          },
          series: [
            {
              name: "Iniciados",
              data: data.map((d) => d.Iniciados),
            },
            {
              name: "Resueltos",
              data: data.map((d) => d.Resueltos),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getMagistradosTop5: (
    optionsBarChartMag,
    setOptionsBarChartMag,
    getErrorfromBack,
    filters
  ) => {
    DataService.getMagistradosTop5(filters)
      .then((data) => {
        setOptionsBarChartMag({
          ...optionsBarChartMag,
          xAxis: {
            categories: data.map((d) => d.Magistrado),
          },
          series: [
            {
              name: "A favor del Ofendido",
              data: data.map((d) => d.cantFavorable),
            },
            {
              name: "A favor del Demandado",
              data: data.map((d) => d.cantDesfavorable),
            },
            {
              name: "Acuerdo entre partes",
              data: data.map((d) => d.cantAcuerdo),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcessPerAsuntoStateDependencia: (
    optionsBarChartDep,
    setOptionsBarChartDep,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerAsuntoStateDependencia(filters)
      .then((data) => {
        setOptionsBarChartDep({
          ...optionsBarChartDep,
          xAxis: {
            categories: data.map((d) => d.Asunto),
          },
          series: [
            {
              name: "Iniciados",
              data: data.map((d) => d.Iniciados),
            },
            {
              name: "Resueltos",
              data: data.map((d) => d.Resueltos),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getTemaFalloTop5: (setEnfavor, getErrorfromBack, filters) => {
    DataService.getTemaFalloTop5(filters)
      .then((data) => {
        setEnfavor(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getSentidoFallo: (setEnfavor, getErrorfromBack, filters) => {
    DataService.getSentidoFallo(filters)
      .then((data) => {
        setEnfavor(data);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMax: (setMax, getErrorfromBack, filters) => {
    DataService.getDurationMax(filters)
      .then((data) => {
        setMax(data[0].max || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMedia: (setMedia, getErrorfromBack, filters) => {
    DataService.getDurationMedia(filters)
      .then((data) => {
        setMedia(data[0].avg || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationMin: (setMin, getErrorfromBack, filters) => {
    DataService.getDurationMin(filters)
      .then((data) => {
        setMin(data[0].min || 0);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },

  getDurationNacionalMin: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    min,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMinNacional(filters)
      .then((data) => {
        const minNacional = data[0].min_nacional;
        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              ...optionsGaugeChart.series[0],
              data: [{ y: minNacional || 0, provincia: min || 0 }],
            },
          ],
          yAxis: {
            ...optionsGaugeChart.yAxis,
            max:
              !min && !minNacional
                ? 100
                : min > minNacional
                ? min + min * 0.3
                : minNacional + minNacional * 0.3,
            tickPositions: [min],
          },
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMedia: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    media,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMediaNacional(filters)
      .then((data) => {
        const mediaNacional = data[0].avg_nacional;
        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              ...optionsGaugeChart.series[0],
              data: [{ y: mediaNacional || 0, provincia: media || 0 }],
            },
          ],
          yAxis: {
            ...optionsGaugeChart.yAxis,
            max:
              !media && !mediaNacional
                ? 100
                : media > mediaNacional
                ? media + media * 0.3
                : mediaNacional + mediaNacional * 0.3,
            tickPositions: [media],
          },
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMax: (
    optionsGaugeChart,
    setOptionsGaugeChart,
    max,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMaxNacional(filters)
      .then((data) => {
        const maxNacional = data[0].max_nacional;

        setOptionsGaugeChart({
          ...optionsGaugeChart,
          series: [
            {
              ...optionsGaugeChart.series[0],
              data: [{ y: maxNacional || 0, provincia: max || 0 }],
            },
          ],
          yAxis: {
            ...optionsGaugeChart.yAxis,
            max:
              !max && !maxNacional
                ? 100
                : max > maxNacional
                ? max + max * 0.3
                : maxNacional + maxNacional * 0.3,
            tickPositions: [max || 0],
          },
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getPerEvolutionYear: (
    optionsBubbleChartAsuntos,
    setOptionsBubbleChartAsuntos,
    asuntos,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerEvolutionYearDependencia(filters)
      .then((data) => {
        setOptionsBubbleChartAsuntos({
          ...optionsBubbleChartAsuntos,
          series: [
            {
              data: data.map((process) => {
                return {
                  x: process.year,
                  y: asuntos[process.Asunto],
                  z: process.cantidad,
                  asunto: process.Asunto,
                };
              }),
            },
          ],
        });
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getAsuntosFilter: (setAsuntosFilter, getErrorfromBack) => {
    DataService.getAsuntosFilter()
      .then((data) => {
        setAsuntosFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getMateriasFilter: (setMateriasFilter, getErrorfromBack) => {
    DataService.getMateriasFilter()
      .then((data) => {
        setMateriasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProcedimientosFilter: (setProcedimientosFilter, getErrorfromBack) => {
    DataService.getProcedimientosFilter()
      .then((data) => {
        setProcedimientosFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getProvinciasFilter: (setProvinciasFilter, getErrorfromBack) => {
    DataService.getProvinciasFilter()
      .then((data) => {
        setProvinciasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getCantonsFilter: (setCantonsFilter, provinciaFilter, getErrorfromBack) => {
    const filters = {};
    provinciaFilter && Object.assign(filters, { provincia: provinciaFilter });
    DataService.getCantonsFilter(filters)
      .then((data) => {
        setCantonsFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDependenciasFilter: (
    setDependenciasFilter,
    provincia,
    canton,
    getErrorfromBack
  ) => {
    const filters = {};
    provincia && Object.assign(filters, { provincia: provincia });
    canton && Object.assign(filters, { canton: canton });

    // TODO relacionar procedimiento y materia con dependencia.
    DataService.getDependenciasFilter(filters)
      .then((data) => {
        setDependenciasFilter([
          { id: "-1", label: "Seleccione una opción" },
          ...data,
        ]);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },

  getDurationNacionalMinFixed: (
    fixedChartDuracion,
    min,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMinNacional(filters)
      .then((data) => {
        const minNacional = data[0].min_nacional;
        fixedChartDuracion.setMin(min);
        fixedChartDuracion.setMinNacion(minNacional);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMediaFixed: (
    fixedChartDuracion,
    media,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMediaNacional(filters)
      .then((data) => {
        const mediaNacional = data[0].avg_nacional;
        fixedChartDuracion.setMed(media);
        fixedChartDuracion.setMedNacion(mediaNacional);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
  getDurationNacionalMaxFixed: (
    fixedChartDuracion,
    max,
    getErrorfromBack,
    filters
  ) => {
    DataService.getDurationMaxNacional(filters)
      .then((data) => {
        const maxNacional = data[0].max_nacional;
        fixedChartDuracion.setMax(max);
        fixedChartDuracion.setMaxNacion(maxNacional);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },

  getWordCloudAsuntosMasTratados: (
    wordCloudAsuntos,
    getErrorfromBack,
    filters
  ) => {
    DataService.getProcessPerEvolutionYearDependencia(filters)
      .then((data) => {
        const d = data.map((process) => ({
          name: `${process.Asunto} (${process.year})`,
          weight: process.cantidad,
        }));
        var top10 = d
          ?.sort(function (a, b) {
            return b.weight - a.weight;
          })
          .slice(0, 10);
        wordCloudAsuntos.setData(top10);
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },

  getStackedColumnSentidoDelFalloSexoMagistrado: (
    stackedColumnMagistrado,
    getErrorfromBack
  ) => {
    DataService.getProcessPerFailureMagis({
      fecha_desde: "2016-01-01",
      fecha_hasta: "2023-01-01",
      id_asunto: 1318,
    })
      .then((data) => {
        if (data?.length) {
          data.forEach((d) => {
            if (d.Sexo === "M") {
              stackedColumnMagistrado.setMinM(d.cantFavorable);
              stackedColumnMagistrado.setMedM(d.cantDesfavorable);
              stackedColumnMagistrado.setMaxM(d.cantAcuerdo);
            } else if (d.Sexo === "H") {
              stackedColumnMagistrado.setMinH(d.cantFavorable);
              stackedColumnMagistrado.setMedH(d.cantDesfavorable);
              stackedColumnMagistrado.setMaxH(d.cantAcuerdo);
            }
          });
        } else {
          stackedColumnMagistrado.resetValues();
        }
      })
      .catch((error) => {
        getErrorfromBack(error);
      });
  },
};
