import React from "react";
import Title from "components/atoms/Title";
import "./styles.scss";

function FilterContainer({ children, hiddenTitle = false, customClass = "" }) {
  return (
    <div className={"filter-container " + customClass}>
      {hiddenTitle ? null : <Title>Filtros</Title>}
      {children}
    </div>
  );
}

export default FilterContainer;
