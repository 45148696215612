export const reducer = (state, action) => {
  switch (action.type) {
    case "setUser":
      return { ...state, user: action.payload };
    case "setBanner":
      return { ...state, banner: action.payload };
    case "setLoadingSpinner":
      return { ...state, loadingSpinner: action.payload };
    default:
      return state;
  }
};

export const reducerPersist = (state, action) => {
  switch (action.type) {
    // case 'setUser':
    //   return { ...state, user: action.payload }
    case "setBanner":
      return { ...state, banner: action.payload };
    default:
      return state;
  }
};
