import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const PageTitle = ({ title, margin = 4 }) => {
  return (
    <Container fluid>
      <Row xs={12}>
        <div className={`page-title-container mt-${margin} mb-${margin}`}>
          <p className={"page-title"}>
            <span className={"title"}> {title}</span>
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default PageTitle;
