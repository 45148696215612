import { useState } from "react";

export const useBarChart = (title, yAxis) => {
  const [optionsBarChart, setOptionsBarChart] = useState({
    credits: false,
    chart: {
      type: "bar",
    },
    title: {
      text: title,
    },

    yAxis: {
      min: 0,
      title: {
        text: yAxis.title,
      },
      labels: {
        enabled: true,
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [],
  });

  return { optionsBarChart, setOptionsBarChart };
};
