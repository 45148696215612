import { DataService } from "../../../../../services/DataService";

export const getIndicadores = ({
  setOptionsTimeLine,
  optionsTimeLine,
  setOptionsPieChartMagistrado,
  optionsPieChartMagistrado,
  setIndicadores,
  dealWithError,
}) =>
  DataService.getIndicadores()
    .then((data) => {
      setOptionsTimeLine({
        ...optionsTimeLine,
        series: [{ ...optionsTimeLine.series[0], data: data.years }],
      });
      setOptionsPieChartMagistrado({
        ...optionsPieChartMagistrado,
        series: [
          {
            name: "Magistrados",
            data: [
              {
                name: "Hombres",
                y: data.MagistradosHombres,
              },
              {
                name: "Mujeres",
                y: data.MagistradosMujeres,
              },
            ],
          },
        ],
      });
      setIndicadores(data);
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });

export const getProcessPerProvinces = ({
  mapOptions,
  setMapOptions,
  dealWithError,
}) =>
  DataService.getProcessPerProvinces()
    .then((data) => {
      const array = mapOptions;
      data.forEach((dato) => {
        const provincia = dato.Provincia.toLowerCase();
        const provinciaFirstLower =
          provincia.charAt(0).toUpperCase() + provincia.slice(1);
        array.series[0].data.forEach((d) => {
          if (d.name === provinciaFirstLower) d.value = dato.cantidad;
        });
      });

      setMapOptions({
        ...mapOptions,
        series: [{ ...mapOptions.series[0], data: array.series[0].data }],
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });

export const getAsuntosOrdenados = ({ setAsuntos, dealWithError }) =>
  DataService.getAsuntosOrdenados()
    .then((data) => {
      setAsuntos(data);
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });

export const getProcessPerAverageDurationSubjectYear = ({
  setOptionsLineChart,
  optionsLineChart,
  dealWithError,
}) =>
  DataService.getProcessPerAverageDurationSubjectYear()
    .then((data) => {
      setOptionsLineChart({
        ...optionsLineChart,
        xAxis: {
          title: {
            text: "Materia",
          },
          categories: data.categories,
          labels: {
            enabled: false,
          },
        },
        series: data.years.map((year) => {
          return {
            name: year,
            data: data.medias_by_year[year],
          };
        }),
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });

export const getProcessPerSubject = ({
  setOptionsPieChartMateria,
  optionsPieChartMateria,
  dealWithError,
}) =>
  DataService.getProcessPerSubject()
    .then((data) => {
      setOptionsPieChartMateria({
        ...optionsPieChartMateria,
        series: [
          {
            name: "Cantidad",
            data: data.map((process) => {
              return {
                name: process.Materia,
                y: process.cantidad,
              };
            }),
          },
        ],
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });

export const getProcessPerEvolutionYearSentencias = ({
  setOptionsBubbleChartSentencias,
  optionsBubbleChartSentencias,
  dealWithError,
}) => {
  const sentencias = {
    OFENDIDO: 0,
    DEMANDADO: 1,
    "ACUERDO ENTRE PARTES": 2,
    "SIN SENTENCIA": 3,
  };

  return DataService.getProcessPerEvolutionYearSentencias()
    .then((data) => {
      //get every year without repeating and ignore null values or empty values
      const years = data?.map(d => d['YEAR(p.FechaIngreso)'])?.filter((value, index, self) => self?.indexOf(value) === index && value !== "" && value !== null)
      // get all types of judgment without repeating and ignore null values or empty values
      const types = data?.map(d => d['TipoSentencia'])?.filter((value, index, self) => self?.indexOf(value) === index && value !== "" && value !== null)

      let headmap = [];
      years.forEach((year, yearIndex) => {
        //filter by year
        const dataYear = data?.filter(d => d['YEAR(p.FechaIngreso)'] === year)
        types.forEach((type, typeIndex) => {
          //filter by type
          const dataType = dataYear?.filter(d => d['TipoSentencia'] === type)
          //count all the data by type
          const count = dataType?.map(d => d['COUNT(p.Proceso)'])?.reduce((a, b) => a + b, 0)
          /*
              debug 
              headmap.push(
              {
                  "year": year,
                  "type": type,
                  "count": count
              }
          )
              
          */
          headmap.push([yearIndex, typeIndex, count]) //[x,y,value]
        })

      })

      setOptionsBubbleChartSentencias({
        ...optionsBubbleChartSentencias,
        xAxis: {
          categories: years,
        },
        yAxis: {
          ...optionsBubbleChartSentencias.yAxis,
          categories: types,
        },
        series: {
          ...optionsBubbleChartSentencias.series,
          data: headmap
        }
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });
};

export const getProcessPerYear = ({
  setOptionsPackedBubble,
  optionsPackedBubble,
  dealWithError,
}) =>
  DataService.getProcessPerYear()
    .then((data) => {
      setOptionsPackedBubble({
        ...optionsPackedBubble,
        series: data.map((process) => {
          return {
            name: process.year,
            data: [
              {
                name: process.year,
                value: process.cantidad,
              },
            ],
          };
        }),
      });
    })
    .catch((error) => {
      dealWithError(error, "Error");
    });
