/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import useRequest from "hooks/useRequest";
import React, { useEffect, useState } from "react";
import "./styles.scss";

const RemotePagination = ({
  totalPages = 30,
  pageNeighbours = 2,
  onPageChanged,
}) => {
  const [pageCurret, setPageCurrent] = useState(1);
  const [pages, setPages] = useState([1]);

  const { afterSubmit, dealWithError } = useRequest();

  const getErrorfromBack = (error) => {
    dealWithError(error, "Error");
    afterSubmit();
  };

  useEffect(() => {
    setPages(fetchPageNumbers(1));
  }, [totalPages]);

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  const goToPage = async (page) => {
    try {
      await onPageChanged(page);
      setPageCurrent(page);
      setPages(fetchPageNumbers(page));
    } catch (error) {
      getErrorfromBack(error);
      afterSubmit();
    }
  };

  const handleMoveLeft = (e, page) => {
    e.preventDefault();
    page > 1 && goToPage(page - 1);
  };

  const handleMoveRight = (e, page) => {
    e.preventDefault();
    page < totalPages && goToPage(page + 1);
  };

  const handleMoveFirstPage = (e) => {
    e.preventDefault();
    goToPage(1);
  };

  const handleMoveLastPage = (e) => {
    e.preventDefault();
    goToPage(totalPages);
  };

  const fetchPageNumbers = (page) => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    // Si me paso de la cantidad de paginas mostramos directamente de 1 al total
    // si no vamos mostrando con pageNeighbours por ejemplo nos pasan 2 en pageNeighbours
    // entonces mostramos 2 a la derecha y 2 a la izquierda 1 2 |3| 4 5 -> 2 3 |4| 5 6
    if (totalPages > totalBlocks) {
      let pages = [];
      let leftBound = page - pageNeighbours;
      let rightBound = page + pageNeighbours;
      const beforeLastPage = totalPages;

      if (leftBound < 1) {
        leftBound = 1;
        rightBound = pageNeighbours * 2 + 1;
      }
      let startPage = leftBound;
      const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

      if (rightBound > beforeLastPage && rightBound !== beforeLastPage) {
        startPage = startPage - (rightBound - beforeLastPage);
      }
      pages = range(startPage, endPage);

      return pages;
    }

    return range(1, totalPages);
  };

  return (
    <div className="pagination-custom">
      <nav aria-label="Pagination">
        <ul className="pagination ">
          <li className={`page-item ${pageCurret === 1 && "disabled"}`}>
            <a
              className="page-link"
              href="#"
              aria-label="Previous"
              onClick={handleMoveFirstPage}
            >
              <span aria-hidden="true">{"<<"}</span>
            </a>
          </li>
          <li className={`page-item ${pageCurret === 1 && "disabled"}`}>
            <a
              className="page-link"
              href="#"
              aria-label="Previous"
              onClick={(e) => handleMoveLeft(e, pageCurret)}
            >
              <span aria-hidden="true">{"<"}</span>
            </a>
          </li>
          {pages.map((page, index) => {
            return (
              <li
                key={index}
                className={`page-item${pageCurret === page ? " active" : ""}`}
              >
                <a className="page-link" onClick={() => goToPage(page)}>
                  {page}
                </a>
              </li>
            );
          })}
          <li
            className={`page-item ${pageCurret === totalPages && "disabled"}`}
          >
            <a
              className="page-link"
              href="#"
              aria-label="Next"
              onClick={(e) => handleMoveRight(e, pageCurret)}
            >
              <span aria-hidden="true">{">"}</span>
            </a>
          </li>
          <li
            className={`page-item ${pageCurret === totalPages && "disabled"}`}
          >
            <a
              className="page-link"
              href="#"
              aria-label="Next"
              onClick={handleMoveLastPage}
            >
              <span aria-hidden="true">{">>"}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default RemotePagination;
