import React from "react";

import TimeLineChart from "components/molecules/TimeLineChart";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const TimeLineChartContainer = ({ options }) => {
  return (
    <Container fluid>
      <Row xs={12}>
        <div className="timeline-chart-container mt-4 mb-4">
          <div className={"timeline-chart-title-container"}>
            <div>
              <span className={"timeline-title"}>Período de tiempo</span>
            </div>
            <div>
              <span className={"timeline-title"}>
                <b>con información</b>
              </span>
            </div>
          </div>
          <div className={"timeline-chart"}>
            <TimeLineChart options={options} />
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default TimeLineChartContainer;
