const { apiCalls } = require("./apiCalls");

export const loadCharts = ({
  setAsuntosFilter,
  setMateriasFilter,
  setProcedimientosFilter,
  setProvinciasFilter,
  setCantonsFilter,
  setDependenciasFilter,
  setFavorableTop5,
  setDesFavorableTop5,
  setAcuerdoTop5,
  setFavorable,
  setDesFavorable,
  setAcuerdo,
  getErrorfromBack,
  setDurationMax,
  setDurationMedia,
  setDurationMin,
  optionsStackedColumnChart,
  setOptionsStackedColumnChart,
  optionsBubbleChartMagYear,
  setOptionsBubbleChartMagYear,
  optionsBarChartMag,
  setOptionsBarChartMag,
  setSentenciasRecurridas,
  setReucrsosEstimados,
  optionsColumnChart,
  setOptionsColumnChart,
  optionsBarChartDep,
  optionsPackedBubble,
  setOptionsPackedBubble,
  setOptionsBarChartDep,
  mapOptions,
  setMapOptions,
  afterSubmit,
  filters,
}) => {
  // /** Selects */
  // apiCalls.getAsuntosFilter(setAsuntosFilter, getErrorfromBack);
  // apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
  // apiCalls.getProcedimientosFilter(setProcedimientosFilter, getErrorfromBack);
  // apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
  // apiCalls.getCantonsFilter(setCantonsFilter, getErrorfromBack);
  // apiCalls.getDependenciasFilter(setDependenciasFilter, getErrorfromBack);

  /** Analitica del CASO: Sentencias recurridas y recursos estimados */
  apiCalls.getSentenciasRecurridas(
    setSentenciasRecurridas,
    getErrorfromBack,
    filters
  );
  apiCalls.getRecursosEstimados(
    setReucrsosEstimados,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Temas de fallos (Top 5) */
  apiCalls.getTemaFalloTop5(setFavorableTop5, getErrorfromBack, filters);
  apiCalls.getTemaFalloTop5(setDesFavorableTop5, getErrorfromBack, {
    ...filters,
    en_favor: "DEMANDADO",
  });
  apiCalls.getTemaFalloTop5(setAcuerdoTop5, getErrorfromBack, {
    ...filters,
    en_favor: "ACUERDO ENTRE PARTES",
  });

  /** Analitica del CASO: Sentido del Fallo */
  apiCalls.getSentidoFallo(setFavorable, getErrorfromBack, filters);
  apiCalls.getSentidoFallo(setDesFavorable, getErrorfromBack, {
    ...filters,
    en_favor: "DEMANDADO",
  });
  apiCalls.getSentidoFallo(setAcuerdo, getErrorfromBack, {
    ...filters,
    en_favor: "ACUERDO ENTRE PARTES",
  });

  /** Analitica del CASO; Duracion */
  apiCalls.getDurationMax(setDurationMax, getErrorfromBack, filters);
  apiCalls.getDurationMin(setDurationMin, getErrorfromBack, filters);
  apiCalls.getDurationMedia(setDurationMedia, getErrorfromBack, filters);

  /** Analitica del CASO: Evolucion de fallos por sexo de magistrado */
  apiCalls.getProcessPerFailureMagis(
    optionsStackedColumnChart,
    setOptionsStackedColumnChart,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Evolucion de procesos por año y sexo de magistrado */
  apiCalls.getProcessPerYearMagis(
    optionsBubbleChartMagYear,
    setOptionsBubbleChartMagYear,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Magistrados con mas resoluciones dictadas (Top 5) */
  apiCalls.getMagistradosTop5(
    optionsBarChartMag,
    setOptionsBarChartMag,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Dependencias con mas resoluciones dictadas (Top 5) */
  apiCalls.getDependenciasTop5(
    optionsBarChartDep,
    setOptionsBarChartDep,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Procesos por Provincia */
  mapOptions &&
    apiCalls.getProvincesCaso(
      mapOptions,
      setMapOptions,
      getErrorfromBack,
      filters
    );

  /** Analitica del CASO: Procesos por año y por estado (iniciado, resuelto) */
  apiCalls.getProcessPerYearState(
    optionsColumnChart,
    setOptionsColumnChart,
    getErrorfromBack,
    filters
  );

  /** Analitica del CASO: Procesos por año */
  apiCalls.getPorcessPerYearCaso(
    optionsPackedBubble,
    setOptionsPackedBubble,
    afterSubmit,
    getErrorfromBack,
    filters
  );
};
