import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LogoBlanco } from "assets/images/judit-blanco.svg";

import "./styles.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <React.Fragment>
        <div className="footer-logo">
          <Link to="/">
            <LogoBlanco width={92} />
          </Link>
        </div>
        <div className="footer-text">
          <div style={{ width: "353px" }}>
            <p style={{ fontSize: "15px" }}>
              Términos y Condiciones | Términos de Cancelación
            </p>
          </div>
          <span style={{ color: "#B897C4" }}>|</span>
          <div style={{ width: "353px", display: "flex" }}>
            <p style={{ fontSize: "15px" }}>
              ️ 2021, judIT. Todos los derechos reservados
            </p>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Footer;
