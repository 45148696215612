/** @function handleChangeDay
 * handleChange for day input */
export const handleChangeDay = (e) => {
  if (e.target.value >= 1 && e.target.value <= 31) {
    return e.target.value;
  }
  return "";
};

/** @function handleChangeMonth
 * handleChange for month input */
export const handleChangeMonth = (e) => {
  if (e.target.value >= 0 && e.target.value <= 12) {
    return e.target.value;
  }
  return "";
};

/** @function handleChangeYear
 * handleChange for year input */
export const handleChangeYear = (e) => {
  if (e.target.value >= 0 && e.target.value <= new Date().getFullYear()) {
    return e.target.value;
  }
  return "";
};
