import React from "react";
import { Card } from "react-bootstrap";
import "./styles.scss";
import ActionButton from "../../atoms/ActionButton";

const CardPlan = ({
  days = 0,
  description = `Accedé a todas las analíticas de JudIT por ${days} días.`,
  period = "DIAS",
  label = "COMPRAR PLAN",
  onClick,
  buttonClassName = "inverted-contained",
  active = false,
  name,
}) => {
  return (
    <Card className={`card-style ${active ? "card-active" : ""}`}>
      <div className="card-container">
        {name && (
          <p
            className={`card-plan-name ${
              active ? "card-plan-color-active" : ""
            }`}
          >
            {name}
          </p>
        )}
        <p
          className={`card-plan-number-days ${
            active ? "card-plan-color-active" : ""
          }`}
        >
          {days}
        </p>
        <p
          className={`card-plan-days ${active ? "card-plan-color-active" : ""}`}
        >
          {period}
        </p>
      </div>
      <div className="card-container-hr">
        <hr className="card-hr" />
      </div>
      <p
        className={`card-description ${active ? "card-plan-color-active" : ""}`}
      >
        {description}
      </p>
      <div className="card-container-button">
        <ActionButton
          handleClick={onClick}
          legend={label}
          className={buttonClassName}
        />
      </div>
    </Card>
  );
};

export default CardPlan;
