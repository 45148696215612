import React from "react";

import { Col, Container, Form, Row, InputGroup, Button } from "react-bootstrap";
import SelectSearchS from "components/atoms/SelectSearch";
import ActionButton from "components/atoms/ActionButton";
import Box from "components/organisms/Box";

import Input from "components/atoms/Input";
import DatePicker from "react-datepicker";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import Title from "components/atoms/Title";

const SearchInput = ({ searchValue, handleChange }) => {
  return (
    <Form>
      <Form.Group className="search-input-container">
        <InputGroup>
          <InputGroup.Text
            style={{ backgroundColor: "white" }}
            className="border-hidden"
          >
            <FontAwesomeIcon icon={faSearch} color="#a8a8a8" />
          </InputGroup.Text>
          <Form.Control
            type="text"
            className="border-hidden search-input-text"
            placeholder="Escribe el texto a buscar"
            onChange={(e) => handleChange(e)}
            value={searchValue}
          />
        </InputGroup>
      </Form.Group>
    </Form>
  );
};

const SearchAdvance = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,

  materiasFilter,
  materiaFilter,
  setMateriaFilter,

  provinciasFilter,
  setProvinciaFilter,
  provinciaFilter,

  cantonsFilter,
  cantonFilter,
  setCantonFilter,

  dependenciasFilter,
  setDependenciaFilter,
  dependenciaFilter,

  juezFilter,
  juecesFilter,
  setJuezFilter,

  searchButtonDisabled = false,
}) => {
  return (
    <Container className="search-filter">
      <Row className="container-inptus">
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Período desde</Form.Label>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className={"date-picker-class"}
              fixedHeight
              showYearDropdown
            />
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Período hasta</Form.Label>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="dd/MM/yyyy"
              className={"date-picker-class"}
              fixedHeight
              showYearDropdown
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="container-inptus">
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Provincia</Form.Label>
            <SelectSearchS
              options={provinciasFilter.map((item) => ({
                name: item.label,
                value: item.id,
              }))}
              onChange={(selectedValue) => setProvinciaFilter(selectedValue)}
              value={provinciaFilter}
            />
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Cantón</Form.Label>
            <SelectSearchS
              options={cantonsFilter.map((item) => ({
                name: item.label,
                value: item.id,
              }))}
              onChange={(selectedValue) => setCantonFilter(selectedValue)}
              value={cantonFilter}
              disabled={!provinciaFilter}
              placeholder={
                !provinciaFilter
                  ? "Seleccione una provincia"
                  : "Seleccione una opción"
              }
            />
          </Form.Group>
        </Col>
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Dependencia</Form.Label>
            <SelectSearchS
              options={dependenciasFilter.map((item) => ({
                name: item.label,
                value: item.id,
              }))}
              onChange={(selectedValue) => setDependenciaFilter(selectedValue)}
              value={dependenciaFilter}
              disabled={!cantonFilter}
              placeholder={
                !cantonFilter ? "Seleccione un cantón" : "Seleccione una opción"
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="container-inptus">
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Materia</Form.Label>
            <SelectSearchS
              options={materiasFilter.map((item) => ({
                name: item.label,
                value: item.id,
              }))}
              onChange={(selectedValue) => setMateriaFilter(selectedValue)}
              value={materiaFilter}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="container-inptus">
        <Col md={4} xs={12}>
          <Form.Group className="container-inputs">
            <Form.Label>Magistrado</Form.Label>
            <SelectSearchS
              options={juecesFilter.map((item) => ({
                name: item.label,
                value: item.id,
              }))}
              onChange={(selectedValue) => setJuezFilter(selectedValue)}
              value={juezFilter}
            />
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
};

const SearchAdvanceTitle = ({
  searchAdvanceIsVisible,
  changeSearchAdvanceVisibility,
  handleSubmit,
}) => {
  const text = "Búsqueda avanzada";
  const icon = searchAdvanceIsVisible ? faCaretUp : faCaretDown;
  return (
    <div className="search-advance-container">
      <div onClick={changeSearchAdvanceVisibility}>
        <Title>{text}</Title>
        <FontAwesomeIcon
          icon={icon}
          size={80}
          className="search-advance-icon"
        />
      </div>
    </div>
  );
};

const Filter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,

  materiasFilter,
  materiaFilter,
  setMateriaFilter,

  provinciasFilter,
  setProvinciaFilter,
  provinciaFilter,

  cantonsFilter,
  cantonFilter,
  setCantonFilter,

  dependenciasFilter,
  setDependenciaFilter,
  dependenciaFilter,

  handleChange,

  setFiltroAvanzado,
  filtroAvanzado,
  searchValue,

  juezFilter,
  juecesFilter,
  setJuezFilter,

  doFilter,

  searchInput,
  searchButtonDisabled,
}) => {
  React.useLayoutEffect(() => {
    if (!searchInput) return;
    doFilter();
  }, [doFilter, searchInput]);

  return (
    <Box customClass={"caso-filter-box"}>
      <Container fluid>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            doFilter();
          }}
        >
          <SearchInput searchValue={searchValue} handleChange={handleChange} />
          <SearchAdvanceTitle
            searchAdvanceIsVisible={filtroAvanzado}
            changeSearchAdvanceVisibility={() =>
              setFiltroAvanzado(!filtroAvanzado)
            }
            handleSubmit={doFilter}
          />
          {filtroAvanzado && (
            <SearchAdvance
              {...{
                startDate,
                setStartDate,
                endDate,
                setEndDate,

                materiasFilter,
                materiaFilter,
                setMateriaFilter,

                provinciasFilter,
                setProvinciaFilter,
                provinciaFilter,

                cantonsFilter,
                cantonFilter,
                setCantonFilter,

                dependenciasFilter,
                setDependenciaFilter,
                dependenciaFilter,

                juezFilter,
                juecesFilter,
                setJuezFilter,
              }}
            />
          )}
          <div className="search-footer-container">
            <ActionButton
              handleClick={doFilter}
              legend="Buscar"
              className={`search-footer-btn `}
            />
          </div>
        </form>
      </Container>
    </Box>
  );
};

export default Filter;
