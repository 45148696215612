import React from "react";

import { Col, Container, Form, Row } from "react-bootstrap";
import ActionButton from "components/atoms/ActionButton";
import Box from "components/organisms/Box";

import DatePicker from "react-datepicker";
import FilterContainer from "components/molecules/FilterContainer";
import { isMobile } from 'helpers/Mobile';

const Filter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  doFilterFull,
}) => {
  return (
      <FilterContainer>
        <Box customClass={isMobile ? "" : "mb-5 mt-5 pb-5 pt-5"}>
          <Container fluid>
            <Row className="container-inptus">
              <Col md={10} xs={12} >
                <div className="caso-box-container-range">
                  <div className="caso-container-range">
                    <Form.Group>
                      <Form.Label>*Período desde</Form.Label>
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        className={"date-picker-class"}
                        fixedHeight
                        showYearDropdown
                      />
                    </Form.Group>
                  </div>
                  <div className="caso-container-range">
                    <Form.Group>
                      <Form.Label>*Período hasta</Form.Label>
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="dd/MM/yyyy"
                        className={"date-picker-class"}
                        fixedHeight
                        showYearDropdown
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="caso-container-button">
                  <ActionButton handleClick={doFilterFull} legend="Filtrar" />
                </div>
              </Col>
            </Row>
          </Container>
        </Box>
    </FilterContainer>
  );
};

export default Filter;
