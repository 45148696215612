import { useState } from "react";

export const useStackedColumnChart = () => {
  const [minM, setMinM] = useState(0);
  const [medM, setMedM] = useState(0);
  const [maxM, setMaxM] = useState(0);

  const [minH, setMinH] = useState(0);
  const [medH, setMedH] = useState(0);
  const [maxH, setMaxH] = useState(0);

  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: [
        "A favor del Ofendido",
        "A favor del Demandado",
        "Acuerdo entre partes",
      ],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Evolucion de fallos",
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
        },
      },
    },
    legend: {
      align: "right",
      x: -30,
      verticalAlign: "top",
      y: 25,
      floating: true,
      backgroundColor: "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "Hombres",
        data: [minH, medH, maxH],
      },
      {
        name: "Mujeres",
        data: [minM, medM, maxM],
      },
    ],
  };

  const resetValues = () => {
    setMinM(0);
    setMedM(0);
    setMaxM(0);
    setMinH(0);
    setMedH(0);
    setMaxH(0);
  };

  return {
    options,

    minM,
    setMinM,
    medM,
    setMedM,
    maxM,
    setMaxM,
    minH,
    setMinH,
    medH,
    setMedH,
    maxH,
    setMaxH,

    resetValues,
  };
};
