import React from "react";
import { withTranslation } from "react-i18next";
import CardPlan from "components/molecules/CardPlan";

const BodySubscription = ({
  title = "Mi subscripción",
  activeSubscription,
  bodySubscription,
}) => {
  return (
    <>
      <div className={"profile-subscription-data"}>
        <h5 className={"profile-title mt-2 mb-3"}>{title}</h5>
        <p>
          <span>{activeSubscription?.legend}</span>
          <br />
          <span>Días restantes:</span>
          <span className={"days-number"}>
            {activeSubscription?.remaining_days}
          </span>
        </p>
      </div>
      <div className={"profile-subscription-cards"}>
        {bodySubscription.cards.map((cardPlan, index) => (
          <div className={"profile-subscription-card-plan"} key={index}>
            <CardPlan {...cardPlan} />
          </div>
        ))}
      </div>
    </>
  );
};

export default withTranslation()(BodySubscription);
