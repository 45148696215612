import React from "react";
import { withTranslation } from "react-i18next";

import SpinnerJudit from "components/atoms/SpinnerJudit";
import Footer from "components/molecules/Footer";

import "./styles.scss";

const LoginLayout = ({ children }) => {
  return (
    <div className={"login-layout-container"}>
      <div className={`main-container`}>{children}</div>
      <div className="footer-container">
        <Footer />
      </div>
      <SpinnerJudit />
    </div>
  );
};

export default withTranslation()(LoginLayout);
