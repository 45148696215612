import React from "react";
import { withTranslation } from "react-i18next";
import CardForm from "components/organisms/CardForm";

const BodyProfile = ({ title = "Mis datos", bodyProfile }) => {
  return (
    <>
      <h5 className={"profile-title mt-2 mb-3"}>{title}</h5>
      <CardForm {...bodyProfile?.cardForm} />
    </>
  );
};

export default withTranslation()(BodyProfile);
