import { useState } from "react";

function renderIcons() {
  // Move icon
  if (!this.series[0].icon) {
    this.series[0].icon = this.renderer
      .path(["M", -8, 0, "L", 8, 0, "M", 0, -8, "L", 8, 0, 0, 8])
      .attr({
        stroke: "#303030",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": 2,
        zIndex: 10,
      })
      .add(this.series[1].group);
  }
  this.series[0].icon.translate(
    this.chartWidth / 2 - 10,
    this.plotHeight / 2 -
      this.series[0].points[0].shapeArgs.innerR -
      (this.series[0].points[0].shapeArgs.r -
        this.series[0].points[0].shapeArgs.innerR) /
        2
  );

  // Exercise icon
  if (!this.series[1].icon) {
    this.series[1].icon = this.renderer
      .path([
        "M",
        -8,
        0,
        "L",
        8,
        0,
        "M",
        0,
        -8,
        "L",
        8,
        0,
        0,
        8,
        "M",
        8,
        -8,
        "L",
        16,
        0,
        8,
        8,
      ])
      .attr({
        stroke: "#ffffff",
        "stroke-linecap": "round",
        "stroke-linejoin": "round",
        "stroke-width": 2,
        zIndex: 10,
      })
      .add(this.series[1].group);
  }
  this.series[1].icon.translate(
    this.chartWidth / 2 - 10,
    this.plotHeight / 2 -
      this.series[1].points[0].shapeArgs.innerR -
      (this.series[1].points[0].shapeArgs.r -
        this.series[1].points[0].shapeArgs.innerR) /
        2
  );

  let chart = this,
    legendAttr = chart.legend.box.getBBox();

  const y = (chart.spacingBox.height * 90) / 100;

  const legend = (label, color, y) => {
    const padding = 5;
    const x = 10;
    const strong = 10;
    let plus = null;
    plus = chart.renderer
      .circle(x, y, strong)
      .attr({
        fill: color,
      })
      .css({
        fontSize: 20,
      })
      .add();

    plus = chart.renderer
      .text(label, x + padding + legendAttr.width + 0, y + 8)
      .attr({
        fill: color,
      })
      .css({
        fontSize: 20,
      })
      .add();

    return plus;
  };

  chart.plus = legend("Magistrado", "#7cb5ec",y);
  chart.plus = legend("Nación", "#434348",y+30);
}

export const useGaugeChart = (title, height = null, format = "") => {
  const [optionsGaugeChart, setOptionsGaugeChart] = useState({
    credits: false,
    chart: {
      plotBackgroundColor: null,
      height: height,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: `{point.percentage:.1f} %`,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: `<b>{point.name}</b><br /> {point.y} % `,
          connectorColor: "silver",
        },
      },
    },
    series: [],
  });

  return { optionsGaugeChart, setOptionsGaugeChart };
};
