import { useState } from "react";

export const useBubbleChartMagFailure = () => {
  const [optionsBubbleChartMagFailure, setOptionsBubbleChartMagFailure] =
    useState({
      chart: {
        type: "bubble",
        plotBorderWidth: 1,
        zoomType: "xy",
      },

      legend: {
        enabled: false,
      },

      title: {
        text: "", // Evolución de fallos por sexo magistrado
      },

      xAxis: {
        title: {
          text: "Año",
        },
        labels: {
          enabled: false,
        },
      },

      yAxis: {
        title: {
          text: "Asuntos",
        },
        labels: {
          enabled: false,
        },
      },

      tooltip: {
        useHTML: true,
        headerFormat: "<table>",
        pointFormat:
          '<tr><th colspan="2"><p>{point.y}</p></th></tr>' +
          "<tr><th>Año:</th><td>{point.x}</td></tr>" +
          "<tr><th>Cantidad:</th><td>{point.z}</td></tr>",
        footerFormat: "</table>",
      },

      series: [
        {
          data: [],
        },
      ],
      credits: false,
    });

  return { optionsBubbleChartMagFailure, setOptionsBubbleChartMagFailure };
};
