import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";

import useRequest from "hooks/useRequest";

import { useStorePersist } from "store/context";

import { DataService } from "services/DataService";
import { AuthService } from "services/AuthService";

import { useLineChart } from "./hooks/UseLineChart";
import { useTimeLineChart } from "./hooks/UseTimeLineChart";
import { useBubbleChartAsuntos } from "./hooks/UseBubbleChartAsuntos";
import { useBubbleChartSentencias } from "./hooks/UseBubbleChartSentencias";
import { useMapChart } from "./hooks/UseMapChart";
import { usePieChartMateria } from "./hooks/UsePieChartMateria";
import { usePieChartMagistrado } from "./hooks/UsePieChartMagistrado";
import { usePackedBubble } from "./hooks/UsePackedBubble";

import User from "models/User";

import MagistradosAnalyticContainer from "views/Analytics/components/organisms/MagistradosAnalyticContainer";
import MapChart from "components/molecules/MapChart";
import ChartWrapper from "components/organisms/ChartWrapper";

import "./styles.scss";

import {
  getAsuntosOrdenados,
  getIndicadores,
  getProcessPerAverageDurationSubjectYear,
  getProcessPerEvolutionYearSentencias,
  getProcessPerProvinces,
  getProcessPerSubject,
  getProcessPerYear,
} from "./utils/apiCalls";
import PageTitle from "../../../../components/atoms/PageTitle";
import TimeLineChartContainer from "../../components/molecules/TimeLineChartContainer";

import { useWordCloudChart } from "./hooks/UseWordCloudChart";

/** Dejo recursos estimados y sentencias recurridas ocultos pedido por german si se vuelve a pedir es solo descomentarlo*/
const AnalyticVew = (props) => {
  const history = useHistory();

  const [{}, dispatch] = useStorePersist();

  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();

  const { mapOptions, setMapOptions } = useMapChart();
  const { optionsLineChart, setOptionsLineChart } = useLineChart();
  const { optionsTimeLine, setOptionsTimeLine } = useTimeLineChart();
  const { optionsPackedBubble, setOptionsPackedBubble } = usePackedBubble();
  const { optionsPieChartMateria, setOptionsPieChartMateria } =
    usePieChartMateria();
  const { optionsPieChartMagistrado, setOptionsPieChartMagistrado } =
    usePieChartMagistrado();
  const { optionsBubbleChartAsuntos, setOptionsBubbleChartAsuntos } =
    useBubbleChartAsuntos();
  const { optionsBubbleChartSentencias, setOptionsBubbleChartSentencias } =
    useBubbleChartSentencias();

  const wordCloudAsuntos = useWordCloudChart();

  const [indicadores, setIndicadores] = useState();
  const [asuntos, setAsuntos] = useState();

  const bannerDispatcher = {
    type: "setBanner",
    payload: {
      title: "Analíticas",
    },
  };

  const validateUserPermissions = () => {
    const currentUser = new User(AuthService.currentUserValue());
    const activeSubscription = currentUser?.activeSubscription();

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return false;
    }
    return true;
  };

  const loadChartsData = async () => {
    beforeSubmit();
    mapOptions &&
      getProcessPerProvinces({ mapOptions, setMapOptions, dealWithError });
    getAsuntosOrdenados({ setAsuntos, dealWithError });
    getProcessPerAverageDurationSubjectYear({
      setOptionsLineChart,
      optionsLineChart,
      dealWithError,
    });
    getProcessPerSubject({
      setOptionsPieChartMateria,
      optionsPieChartMateria,
      dealWithError,
    });
    await getProcessPerEvolutionYearSentencias({
      setOptionsBubbleChartSentencias,
      optionsBubbleChartSentencias,
      dealWithError,
    });
    getProcessPerYear({
      setOptionsPackedBubble,
      optionsPackedBubble,
      dealWithError,
    });
    await getIndicadores({
      setIndicadores,
      setOptionsPieChartMagistrado,
      setOptionsTimeLine,
      optionsPieChartMagistrado,
      optionsTimeLine,
      dealWithError,
    });
    afterSubmit();
  };

  useEffect(() => {
    dispatch(bannerDispatcher);
    if (!validateUserPermissions) {
      return;
    }
    loadChartsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    asuntos &&
      DataService.getPerEvolutionYear()
        .then((data) => {
          var top10 = data.sort(function (a, b) {
            return b.weight - a.weight;
        }
        ).slice(0, 10);
          wordCloudAsuntos.setData(top10);
          afterSubmit();
        })
        .catch((error) => {
          afterSubmit();
          dealWithError(error, "Error");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asuntos]);

  const chartsIndicatorsAndProcessByYear = [
    {
      ChartCustomComponent: () => (
        <MagistradosAnalyticContainer
          indicadores={indicadores}
          options={optionsPieChartMagistrado}
        />
      ),
      xs: 8,
    },
    {
      title: "Procesos por año",
      options: optionsPackedBubble,
      backgroundTitle: "#18A48C",
      xs: 4,
    },
  ];

  const chartsDurationEvolution = [
    {
      title: "Duración media por materia/año",
      options: optionsLineChart,
      backgroundTitle: "#542F61",
      xs: 12,
    },
  ];

  const chartsProccessProvinceAndMaterial = [
    {
      title: "Procesos por Provincia",
      options: mapOptions,
      chart: MapChart,
      backgroundTitle: "#542F61",
      xs: 5,
    },
    {
      title: "Procesos por materia",
      options: optionsPieChartMateria,
      backgroundTitle: "#542F61",
      xs: 7,
    },
  ];

  const chartsEvolutionByYearsAndSentenceType = [
    {
      title: "Asuntos más tratados (top 10)",
      options: wordCloudAsuntos.optionsWordCloud,
      backgroundTitle: "#18A48C",
      xs: 6,
    },
    {
      title: "Evolución por tipo de sentencias",
      options: optionsBubbleChartSentencias,
      backgroundTitle: "#18A48C",
      xs: 6,
    },
  ];

  return (
    <div className={"analytic-graphics-container"}>
      <PageTitle title={"Indicadores"} />
      <TimeLineChartContainer options={optionsTimeLine} />
      <ChartWrapper charts={chartsIndicatorsAndProcessByYear} />
      <ChartWrapper charts={chartsProccessProvinceAndMaterial} />
      <ChartWrapper charts={chartsEvolutionByYearsAndSentenceType} />
      <ChartWrapper charts={chartsDurationEvolution} />
    </div>
  );
};

export default withTranslation()(AnalyticVew);
