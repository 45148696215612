import React from "react";
import { withTranslation } from "react-i18next";
import CardFormPassword from "components/organisms/CardFormPassword";

const BodySubscription = ({
  title = "Cambiar contraseña",
  bodyChangePassword,
}) => {
  return (
    <>
      <h5 className={"profile-title mt-2 mb-3"}>{title}</h5>
      <div className={"profile-change-password-container"}>
        <CardFormPassword {...bodyChangePassword?.cardForm} />
      </div>
    </>
  );
};

export default withTranslation()(BodySubscription);
