import React from "react";
import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal as ModalBS } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/images/judit.svg";
import ActionButton from "components/atoms/ActionButton";

const Modal = ({
  title,
  open,
  customClass,
  message,
  handleClose,
  handleAccept,
  body,
  handleCancel,
  adaptHeight = false,
}) => {
  return (
    <ModalBS
      className={customClass}
      dialogClassName={"modal"}
      contentClassName={`modal-container ${
        adaptHeight ? "modal-container-adapt-height" : ""
      }`}
      centered={true}
      show={open}
    >
      <div>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className={"modal-close-button"}
          onClick={handleClose}
        />
        <Logo className={"logo"} width={165} height={84} />
        <div>
          <p className={"my-modal-title"}>{title}</p>
          <p className={"my-modal-message"}>{message}</p>
        </div>
        {body}
        <div className={"my-modal-buttons"}>
          {handleCancel && (
            <ActionButton handleClick={handleCancel} legend={"CANCELAR"} />
          )}
          <ActionButton
            handleClick={handleAccept || handleClose}
            legend={"ACEPTAR"}
          />
        </div>
      </div>
    </ModalBS>
  );
};

export default Modal;
