import { useState } from "react";

export const usePieChartMateria = () => {
  const [optionsPieChartMateria, setOptionsPieChartMateria] = useState({
    credits: false,
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "", //"Procesos por materia",
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f}",
          connectorColor: "silver",
        },
      },
    },
    series: [],
  });

  return { optionsPieChartMateria, setOptionsPieChartMateria };
};
