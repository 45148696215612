import React from "react";
import Checkbox from "react-bootstrap/FormCheck";

import "./styles.scss";

const InputCheckbox = ({ legend, onChange, error, value, href, target }) => {
  return (
    <div className={"input-checkbox-container"}>
      <div className="input-checkbox">
        <Checkbox type="checkbox" onChange={onChange} checked={value} />
        {legend && href ? (
          <a href={href} target={target} className={"input-link"}>
            {legend}
          </a>
        ) : (
          legend && <span className={"input-label"}>{legend}</span>
        )}
      </div>
      <div className="input-error">{error}</div>
    </div>
  );
};

export default InputCheckbox;
