import React from "react";

import "./style.scss";
import SelectSearch, { fuzzySearch } from "react-select-search";

const SelectSearchS = ({
  id,
  options,
  placeholder = "Seleccione una opción",
  emptyMessage = "No se encontraron resultados",
  filterOptions = fuzzySearch,
  className,
  onChange,
  value,
  disabled=false,
}) => {
  return (
    <SelectSearch
      className={className}
      onChange={onChange}
      value={value}
      id={id}
      printOptions={"auto"}
      options={options}
      search={true}
      filterOptions={filterOptions}
      emptyMessage={emptyMessage}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};

export default SelectSearchS;
