import Environment from "../environment";

import Api from "./ApiService";

export const RecaptchaService = {
  validateRecaptcha: (token) => {
    return new Promise((resolve, reject) => {
      return Api.fetchNoToken(
        `${Environment.api}api/auth/validate-recaptcha/`,
        "POST",
        { token }
      )
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
