import React from "react";
import "./styles.scss";
import Input from "components/atoms/Input";
import ActionButton from "components/atoms/ActionButton";
import LoginHeader from "components/atoms/LoginHeader";
import InputCheckbox from "components/atoms/InputCheckbox";

import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = ({
  fields = [],
  forgotPasswordLink,
  loginButton,
  loginHeader = {},
  className,
  error,
}) => {
  return (
    <div className={"login-form-container " + className}>
      <div className={"login-form-header"}>
        <LoginHeader {...loginHeader} />
      </div>

      {fields.map((field, index) => {
        if (field.date) {
          return (
            <div>
              <h6 className={"birthday-title"}>Fecha de Nacimiento</h6>
              <div className={"login-form-date"}>
                {field.fields.map((field, index) => (
                  <Input {...field} key={index} />
                ))}
              </div>
              <div className={"login-form-error"}>
                {Boolean(field.error) && (
                  <p className={"input-helper"}>
                    {field.error.map((e, i) => (
                      <span className={" input-error"} key={i}>
                        {e}{" "}
                      </span>
                    ))}
                  </p>
                )}
              </div>
            </div>
          );
        } else if (field.type === "checkbox") {
          return <InputCheckbox {...field} key={index} />;
        } else {
          return <Input {...field} key={index} />;
        }
      })}
      <div className={"login-form-error margintop"}>
        {Boolean(error) && (
          <p className={"input-helper"}>
            <span className={" input-error"}>{error}</span>
          </p>
        )}
      </div>
      <div className={"login-form-buttons"}>
        {forgotPasswordLink && (
          // eslint-disable-next-line  jsx-a11y/anchor-is-valid
          <a
            className={"login-form-forgot-password"}
            onClick={forgotPasswordLink.handleClick}
          >
            {forgotPasswordLink.label}
          </a>
        )}
        {loginButton && (
          <div
            className={
              loginButton.reCaptcha ? "login-form-button-container" : ""
            }
          >
            {loginButton.reCaptcha && (
              <ReCAPTCHA
                sitekey={loginButton.reCaptcha.sitekey}
                onChange={loginButton.reCaptcha.onChange}
              />
            )}
            <ActionButton
              handleClick={loginButton.handleClick}
              legend={loginButton.label}
              disabled={loginButton.disabled}
              loading={loginButton.loading}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
