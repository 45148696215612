import React, { useEffect } from "react";

import BootstrapTable from "react-bootstrap-table-next";

const Table = ({
  data,
  columns,
  keyField,
  defaultSorted,
  pagination,
  striped = true,
  hover = true,
  bordered = true,
  remote = false,
  expandRow,
  extraProps,
  onTableChange,
}) => {
  return (
    <BootstrapTable
      bootstrap4
      remote={remote}
      keyField={keyField}
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      pagination={pagination}
      striped={striped}
      hover={hover}
      bordered={bordered}
      expandRow={expandRow}
      onTableChange={onTableChange}
      {...extraProps}
    />
  );
};

export default Table;
