import { useState } from "react";
import { useStore } from "store/context";

export const useRequest = (
  initialFilters,
  productFilters,
  filterTypeMap,
  isMobile,
  headers
) => {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(null);

  const [{ loadingSpinner }, dispatch] = useStore();

  function loading() {
    document.body.style.cursor = "wait";
    dispatch({ type: "setLoadingSpinner", payload: true });
  }

  function stopLoading() {
    document.body.style.cursor = "default";
    dispatch({ type: "setLoadingSpinner", payload: false });
  }

  function resetAlerts() {
    setErrors({});
    setMessage(null);
  }

  function beforeSubmit() {
    setSubmitted(true);
    setSuccess(null);
    resetAlerts();
    loading();
  }

  function afterSubmit() {
    setSubmitted(false);
    stopLoading();
  }

  function showSuccess(message) {
    afterSubmit();
    setSuccess(true);
    setMessage(message);
  }

  function showError(message, errors = {}) {
    afterSubmit();
    setSuccess(false);
    setMessage(message);
    setErrors(errors);
  }

  function dealWithError(response, codeMsgError = "Error", callback = null) {
    if ([400, 401, 404, 409, 422].includes(response.status)) {
      response.json().then((data) => {
        localStorage.setItem("error", "");
        localStorage.setItem("error", data);
        if (Array.isArray(data)) {
          showError(data.join(", "));
        } else if ("detail" in data) {
          showError(data["detail"]);
        } else if ("message" in data && "error" in data) {
          showError(data["message"], data["error"]);
        } else if ("error" in data) {
          showError(data["error"]);
        } else if ("non_field_errors" in data) {
          showError(data["non_field_errors"], data);
        } else {
          showError(codeMsgError, data);
        }
        if (callback) callback(data);
      });
    } else {
      showError(codeMsgError);
      if (callback) callback();
    }
  }

  return {
    submitted,
    setSubmitted,
    success,
    setSuccess,
    errors,
    setErrors,
    message,
    setMessage,
    loadingSpinner,

    beforeSubmit,
    afterSubmit,
    showSuccess,
    showError,
    dealWithError,
  };
};

export default useRequest;
