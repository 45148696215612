import React from "react";
import { Toast } from "react-bootstrap";
import ToastContainer from "react-bootstrap/ToastContainer";
const LoginHeader = ({
  title,
  message,
  variant = "light",
  animation,
  autohide,
  delay,
  onClose,
  position = "top-end",
  show,
}) => {
  return (
    <ToastContainer position={position} className="p-3">
      <Toast
        className="d-inline-block m-1"
        bg={variant}
        animation={animation}
        autohide={autohide}
        delay={delay}
        onClose={onClose}
        show={show}
      >
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default LoginHeader;
