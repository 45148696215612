import React from "react";
import "./styles.scss";
import { Spinner } from "react-bootstrap";

const ActionButton = ({
  legend,
  handleClick,
  className = "",
  type = "button",
  disabled,
  loading,
}) => {
  return (
    <>
      <input
        type={type}
        className={
          "action-button " + className + `${disabled ? " disabled " : ""}`
        }
        value={legend}
        onClick={handleClick}
        disabled={disabled}
      />
      {loading && (
        <Spinner
          variant="success"
          className={"action-button-spinner"}
          animation={"border"}
        />
      )}
    </>
  );
};

export default ActionButton;
