import React from "react";
import "./styles.scss";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal as ModalBS } from "react-bootstrap";
import { ReactComponent as Logo } from "assets/images/judit.svg";
import ActionButton from "components/atoms/ActionButton";
import Table from "components/organisms/Table";

const DetailModal = ({
  title,
  open,
  customClass,
  message,
  handleClose,
  data = [],
}) => {
  const columns = [
    { dataField: "proceso", text: "Proceso", sort: false },
    {
      dataField: "dependencia_juridiccional",
      text: "Dependencia",
      sort: false,
    },
    { dataField: "ciudad", text: "Ciudad", sort: false },
    { dataField: "no_ingreso", text: "No ingreso", sort: false },
    { dataField: "fecha", text: "Fecha", sort: false },
    { dataField: "actor_ofendido", text: "Actor ofendido", sort: false },
    {
      dataField: "demandado_procesado",
      text: "Demandado procesados",
      sort: false,
    },
    { dataField: "pdf", text: "Descargar PDF" },
  ];
  return (
    <ModalBS
      className={customClass}
      dialogClassName={"modal modal-detail"}
      contentClassName={"modal-container"}
      centered={true}
      show={open}
    >
      <div className={"modal-detail-content"}>
        <div className="modal-title-container">
          <div className="modal-title">
            <span>Detalle</span>
          </div>
        </div>
        <Table
          remote
          keyField="id"
          data={data}
          columns={columns}
          headerClasses={"table-header"}
        />
        <div className={"modal-buttons"}>
          {handleClose && (
            <ActionButton handleClick={handleClose} legend={"CERRAR"} />
          )}
        </div>
      </div>
    </ModalBS>
  );
};

export default DetailModal;
