import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { withTranslation } from "react-i18next";

import { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";

import { AuthService } from "services/AuthService";
import { useStorePersist } from "store/context";
import User from "models/User";

import { apiCalls } from "../Caso/utils/apiCalls";
import { fetchTableData, initialState } from "helpers/Table";
import useRequest from "hooks/useRequest";
import { useOpenDetail } from "hooks/UseOpenDetail";

import { formatDate } from "utils/formatDate";

import ProcessTable from "views/Analytics/components/organisms/ProcessTable";

import "./styles.scss";
import Filter from "./components/Filter";

const SearchVew = (props) => {
  registerLocale("es", es);
  const searchInput = props?.match?.params?.input;
  const [endDate, setEndDate] = useState(new Date("11/26/2021"));
  const [startDate, setStartDate] = useState(
    new Date(Date.parse("01/01/2016"))
  );

  const [materiaFilter, setMateriaFilter] = useState();
  const [materiasFilter, setMateriasFilter] = useState([]);
  const [dependenciaFilter, setDependenciaFilter] = useState();
  const [dependenciasFilter, setDependenciasFilter] = useState([]);
  const [provinciaFilter, setProvinciaFilter] = useState();
  const [provinciasFilter, setProvinciasFilter] = useState([]);
  const [cantonFilter, setCantonFilter] = useState();
  const [cantonsFilter, setCantonsFilter] = useState([]);
  const [juezFilter, setJuezFilter] = useState();
  const [juecesFilter, setJuecesFilter] = useState([]);

  const [processDetalle, setProcessDetalle] = useState([]);
  const [processActual, setProcessActual] = useState();
  const [cantProcess, setCantProcess] = useState(0);
  const [filtered, setFiltered] = useState(false);

  const [filtroAvanzado, setFiltroAvanzado] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setProcessDetalle([]);
  };
  const handleOpen = () => setShow(true);

  const [searchValue, setSearchValue] = useState(searchInput);

  const [dataTable, setDataTable] = useState([]);

  const { afterSubmit, beforeSubmit, dealWithError } = useRequest();
  const [{}, dispatch] = useStorePersist();
  const history = useHistory();

  const getErrorfromBack = useCallback((error) => {
    dealWithError(error, "Error");
    afterSubmit();
  });

  useEffect(() => {
    dispatch({
      type: "setBanner",
      payload: {
        title: "Buscar",
      },
    });
    /** Selects */
    apiCalls.getMateriasFilter(setMateriasFilter, getErrorfromBack);
    apiCalls.getProvinciasFilter(setProvinciasFilter, getErrorfromBack);
    apiCalls.getCantonsFilter(setCantonsFilter, 0, getErrorfromBack);
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      0,
      0,
      getErrorfromBack
    );
    apiCalls.getJuecesFilter(setJuecesFilter, getErrorfromBack);

    const currentUser = new User(AuthService.currentUserValue());
    const activeSubscription = currentUser?.activeSubscription();

    if (!Boolean(activeSubscription?.is_active)) {
      history.push("/profile");
      return;
    }
  }, [dispatch, getErrorfromBack, history]);

  useEffect(() => {
    setCantonFilter(); // reseteo filtro de asunto
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    apiCalls.getCantonsFilter(setCantonsFilter, prov, getErrorfromBack);
  }, [getErrorfromBack, provinciaFilter]);

  useEffect(() => {
    setDependenciaFilter("-1"); // reseteo filtro de asunto
    const prov = provinciaFilter === "-1" ? 0 : provinciaFilter;
    const canton = cantonFilter === "-1" ? 0 : cantonFilter;
    apiCalls.getDependenciasFilter(
      setDependenciasFilter,
      prov,
      canton,
      getErrorfromBack
    );
  }, [cantonFilter, getErrorfromBack, provinciaFilter]);

  const [state, setState] = useState(initialState);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      id_juez: juezFilter,
      q: searchValue?.toLowerCase() || "",
      page: page,
    };
    beforeSubmit();
    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });
    afterSubmit();
    setFiltered(true);
  };

  const { data, sizePerPage, page } = state;
  const { handleOpenDetail } = useOpenDetail();

  const doFilter = async (e) => {
    beforeSubmit();
    let newFilters = {
      fecha_desde: formatDate(startDate),
      fecha_hasta: formatDate(endDate),
      en_favor: "OFENDIDO",
      codigo_materia: materiaFilter,
      codigo_provincia: provinciaFilter,
      codigo_canton: cantonFilter,
      codigo_dependencia: dependenciaFilter,
      id_juez: juezFilter,
      q: searchValue?.toLowerCase() || "",
    };

    await fetchTableData({
      newFilters,
      setDataTable,
      state,
      setState,
      dealWithError,
      setCantProcess,
      setProcessActual,
      setProcessDetalle,
      getErrorfromBack,
      handleOpen,
      handleOpenDetail,
    });

    afterSubmit();
    setFiltered(true);
  };

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="search-body-container">
      <div>
        <Filter
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          materiasFilter={materiasFilter}
          materiaFilter={materiaFilter}
          setMateriaFilter={setMateriaFilter}
          provinciasFilter={provinciasFilter}
          setProvinciaFilter={setProvinciaFilter}
          provinciaFilter={provinciaFilter}
          cantonsFilter={cantonsFilter}
          cantonFilter={cantonFilter}
          setCantonFilter={setCantonFilter}
          dependenciasFilter={dependenciasFilter}
          setDependenciaFilter={setDependenciaFilter}
          dependenciaFilter={dependenciaFilter}
          handleChange={handleChange}
          setFiltroAvanzado={setFiltroAvanzado}
          filtroAvanzado={filtroAvanzado}
          searchValue={searchValue}
          juezFilter={juezFilter}
          juecesFilter={juecesFilter}
          setJuezFilter={setJuezFilter}
          doFilter={doFilter}
          searchInput={searchInput}
        />
        {filtered && (
          <ProcessTable
            data={data}
            page={page}
            cantProcess={cantProcess}
            handleCloseModal={handleClose}
            handleTableChange={handleTableChange}
            processActual={processActual}
            processDetalle={processDetalle}
            sizePerPage={sizePerPage}
            show={show}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslation()(SearchVew);
