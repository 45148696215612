import React from "react";
import "./styles.scss";

import ActionButton from "components/atoms/ActionButton";
import LoginForm from "components/molecules/LoginForm";
import Box from "../Box";

const Divider = () => (
  <p>
    <span className={"judit-divider"}>——— o ———</span>
  </p>
);

const LoginBox = ({
  loginForm,
  registerButton,

  googleAuth,
  facebookAuth,

  showSocial = true,
  className,
}) => {
  return (
    <Box maxWidth={true}>
      <div className={"login-box-container " + (className || "")}>
        <div className={"login-box-form"}>
          <LoginForm {...loginForm} />
        </div>
        {showSocial && (
          <>
            <Divider />
            <div
              className={`login-box-social-auth ${
                !registerButton ? "login-box-social-auth-padding" : ""
              }`}
            >
              <div className={"login-box-social-auth-icons"}>
                <span className={"login-box-social-text"}>
                  {registerButton ? "Ingresar con" : "Registrarme con"}
                </span>
                <form
                  ref={googleAuth?.formGoogleRef}
                  method={"post"}
                  action={googleAuth?.googleAuthUrl}
                >
                  {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={googleAuth?.handleClickGoogleAuth}
                    className={"login-box-social-image"}
                  >
                    <img
                      src={require("../../../assets/images/google.svg")}
                      alt={"Logo Google"}
                    />
                  </a>
                </form>
                <form
                  ref={facebookAuth?.formFacebookRef}
                  method={"post"}
                  action={facebookAuth?.facebookAuthUrl}
                >
                  {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                  <a
                    onClick={facebookAuth?.handleClickFacebookAuth}
                    className={"login-box-social-image"}
                  >
                    <img
                      src={require("../../../assets/images/facebook_icon_black.svg")}
                      alt={"Logo Facebook"}
                    />
                  </a>
                </form>
              </div>
            </div>
            {registerButton && (
              <div className={"login-box-social-footer"}>
                <span className={"login-box-social-text"}>
                  ¿Aún no tienes una cuenta?
                </span>
                <ActionButton {...registerButton} className={"inverted"} />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default LoginBox;
