class Plan {
  active = false;
  amount = null;
  detail = null;
  id = null;
  is_free = null;
  subscription_days = null;

  constructor(obj) {
    // IF AN OBJECT WAS PASSED THEN INITIALISE PROPERTIES FROM THAT OBJECT
    for (var prop in obj) this[prop] = obj[prop];
  }
}

export default Plan;
