import React from "react";

import { Col, Container, Form, Row } from "react-bootstrap";
import SelectSearchS from "components/atoms/SelectSearch";
import ActionButton from "components/atoms/ActionButton";
import Box from "components/organisms/Box";
import FilterContainer from "components/molecules/FilterContainer";

const Filter = ({ juecesFilter, setJuezFilter, juezFilter, doFilter }) => {
  return (
    <FilterContainer>
      <Box className={"pt-5 pb-5 mb-5 mt-5"}>
        <Container fluid>
          <Row className="container-inptus">
            <Col xs={10}>
              <Form.Group>
                <Form.Label>Magistrado</Form.Label>
                <SelectSearchS
                  options={juecesFilter.map((item) => ({
                    name: item.label,
                    value: item.id,
                  }))}
                  onChange={(selectedValue) => setJuezFilter(selectedValue)}
                  value={juezFilter}
                />
              </Form.Group>
              <div className="caso-container-button">
                <ActionButton
                  handleClick={doFilter}
                  legend="Filtrar"
                  className={"mt-4"}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Box>
    </FilterContainer>
  );
};

export default Filter;
