import React from "react";
import { Card } from "react-bootstrap";
import "./styles.scss";

const CardButton = ({ title, onClick, active }) => {
  return (
    <Card
      onClick={onClick}
      className={`card-button border-0 ${active && "card-button-active"}`}
    >
      <div className="card-content ">
        <span className="card-text-color">{`> ${title}`}</span>
      </div>
    </Card>
  );
};

export default CardButton;
