import { useState } from "react";

/**
 * Custom Hook para configurar mensajes de tipo modal
 * @function useModal
 */
export const useModal = () => {
  const initialState = {
    open: false,
    title: "",
    message: "",
    handleClose: () => {},
  };
  const [modal, setModal] = useState(initialState);
  const [open, setOpen] = useState(false);

  /**
   * [Utilizar esta función para cerrar las alertas.]
   * @function closeAlert
   * @param {object} oldState - Configuracion de la alerta.
   */
  const closeModal = (oldState) => {
    setModal(Object.assign(oldState, { open: false }));
    setOpen(false);
  };

  /**
   * Funcion base para configurar una alerta modal.
   * @function openModal
   * @param {function} handleClose - Funcion a ejecutar para cerrar el modal. Se bindea al botón de X y al de cerrar. (opcional)
   * @param {function} handleAccept - Funcion a ejecutar al hacer click sobre el botón el modal. Si no se recibe, se ejucta handleClose (opcional)
   * @param {string} message - Titulo a mostrar en el modal
   * @param {string} title - Mensaje a mostrar en el modal
   * @param {JSXElement} body - Componentes a renderizar en el modal
   */
  const openModal = ({
    title,
    message,
    handleClose,
    handleAccept,
    handleCancel,
    body,
  }) => {
    const config = {
      title,
      message,
      handleClose,
      handleAccept,
      handleCancel,
      open: true,
      body,
    };
    Object.assign(config, {
      handleClose: handleClose || (() => closeModal(config)),
    });
    setModal(config);
    setOpen(true);
  };

  return {
    openModal,
    initialState,
    closeModal,

    setOpen,
    open,
    setModal,
    modal,
  };
};

export default useModal;
