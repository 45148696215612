import { useState } from "react";

export const usePieChart = (title, height = null, format = "") => {
  const [optionsPieChart, setOptionsPieChart] = useState({
    credits: false,
    chart: {
      plotBackgroundColor: null,
      height: height,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: title,
    },
    tooltip: {
      pointFormat: `{point.percentage:.2f} %`,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: `<b>{point.name}</b><br /> {point.y} ${format} `,
          connectorColor: "silver",
        },
      },
    },
    series: [],
  });

  return { optionsPieChart, setOptionsPieChart };
};
