import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { AuthService } from "services/AuthService";
import { PlanService } from "services/PlanService";

import { useStorePersist } from "store/context";

import useModal from "hooks/UseModal";
import useRequest from "hooks/useRequest";

import {
  handleChangeDay,
  handleChangeMonth,
  handleChangeYear,
} from "helpers/Dates";

import Plan from "models/Plan";

import Profile from "components/templates/Profile";

import "./styles.scss";

const ProfileView = (props) => {
  const history = useHistory();
  const search = useLocation().search;
  const tab = new URLSearchParams(search).get("tab");

  // eslint-disable-next-line no-empty-pattern
  const [{}, dispatch] = useStorePersist();

  const {
    beforeSubmit,
    afterSubmit,
    errors,
    setErrors,
    dealWithError,
    message,
  } = useRequest();

  const currentUser = AuthService.currentUserValue();

  const [tabSubscription, tabProfile, tabChangePassword] = [
    "subscription",
    "profile",
    "change-password",
  ];
  const tabs = [tabSubscription, tabProfile, tabChangePassword];
  const activeTab = tabs.includes(tab) ? tab : tabProfile;

  const { modal, openModal, closeModal } = useModal();
  const [disabled, setDisabled] = useState(currentUser.mustSetPassword());

  const [cardActive, setCardActive] = useState(activeTab);
  const [plans, setPlans] = useState([]);

  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [passwordCreate, setPasswordCreate] = useState("");
  const [passwordCreateConfirm, setPasswordCreateConfirm] = useState("");

  const subscription = currentUser?.subscription;
  const activeSubscription = currentUser?.activeSubscription();

  useLayoutEffect(() => {
    let banner = {
      color: "warning",
      title: "No posees una subscripción activa",
    };

    if (Boolean(activeSubscription.is_active)) {
      banner = {
        color: Boolean(activeSubscription.is_active) ? "success" : "warning",
        title: `Te quedan ${
          activeSubscription?.remaining_days
        } días de subscripción ${
          activeSubscription?.plan?.is_free ? " gratuita" : ""
        }`,
      };
    }
    dispatch({ type: "setBanner", payload: banner });
  }, []);

  useEffect(() => {
    AuthService.loadUser().catch((error) => {
      AuthService.logout();
      history.push("/login");
    });

    PlanService.plans()
      .then((data) => setPlans(data?.results.map((plan) => new Plan(plan))))
      .catch((error) =>
        openModal({
          title: "Error",
          message: "Ha ocurrido un error al cargar los planes",
        })
      );
    if (!currentUser) {
      history.replace("/login");
      return;
    }
    setProfileData();
    setErrors({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setProfileData = () => {
    setFirstName(currentUser.first_name);
    setLastName(currentUser.last_name);
    setPhone(currentUser.phone_number);
    setEmail(currentUser.email);
    try {
      setYear(currentUser.parseBirthDate()[0]);
      setMonth(currentUser.parseBirthDate()[1]);
      setDay(currentUser.parseBirthDate()[2]);
    } catch (e) {
      setYear("");
      setMonth("");
      setDay("");
    }
  };

  const handleResendVerificationMail = () => {
    beforeSubmit();
    AuthService.resendVerificationMail()
      .then(() => {
        afterSubmit();
        openModal({
          title: "Correo enviado",
          message:
            "Se ha enviado un correo electrónico para confirmar su cuenta.",
        });
      })
      .catch((e) => {
        afterSubmit();
        dealWithError(e, "Error", callback);
      });
  };

  const buttonProfile = {
    title: "Mis datos",
    onClick: () => setCardActive(tabProfile),
    active: cardActive === tabProfile,
  };
  const buttonSubscription = {
    title: "Mi subscripción",
    onClick: () => setCardActive(tabSubscription),
    active: cardActive === tabSubscription,
  };

  const buttonChangePassword = {
    title: "Cambiar contraseña",
    onClick: () => setCardActive(tabChangePassword),
    active: cardActive === tabChangePassword,
  };

  const validateEmptyFields = () =>
    Boolean(email) &&
    Boolean(firstName) &&
    Boolean(lastName) &&
    Boolean(phone) &&
    Boolean(month) &&
    Boolean(year) &&
    Boolean(password) &&
    Boolean(day);

  const validateEmptyFieldsChangePassword = () =>
    Boolean(actualPassword) &&
    Boolean(newPassword) &&
    Boolean(newPasswordConfirm);

  const updateProfile = (data) => {
    beforeSubmit();

    AuthService.validateUserPassword({ password })
      .then((resp) => {
        AuthService.updateProfileData(data)
          .then((resp) => {
            afterSubmit();
            openModal({
              title: "Operación exitosa",
              message: "Tus datos fueron actualizados exitosamente",
              handleClose: (e) => history.go(0),
            });
          })
          .catch((error) => {
            afterSubmit();
            setDisabled(false);
            dealWithError(error, "Error");
          });
      })
      .catch(() => {
        afterSubmit();
        setErrors({ password: ["La contraseña ingresada es incorrecta."] });
        setDisabled(false);
      });
  };

  const handleSubmitProfile = (e) => {
    e.preventDefault();
    setErrors({});
    setDisabled(true);
    const birth_date = `${year}-${month}-${day}`;
    const data = {
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      birth_date,
    };

    if (validateEmptyFields()) {
      updateProfile(data);
    } else {
      setDisabled(false);
      setErrors({ non_field_errors: "Por favor complete todos los campos." });
    }
  };

  const bodyProfileFields = {
    firstName: {
      placeholder: "Nombre",
      value: firstName,
      onChange: (e) => setFirstName(e.target.value),
      type: "text",
      name: "firstname",
      error: errors.first_name,
      disabled: disabled,
    },
    lastName: {
      placeholder: "Apellido",
      value: lastName,
      onChange: (e) => setLastName(e.target.value),
      type: "text",
      name: "lastname",
      error: errors.last_name,
      disabled: disabled,
    },
    phone: {
      placeholder: "Teléfono",
      value: phone,
      onChange: (e) => setPhone(e.target.value),
      type: "number",
      name: "phone",
      error: errors.phone_number,
      disabled: disabled,
    },
    email: {
      placeholder: "nombre@correo.com.ar",
      value: email,
      onChange: (e) => setEmail(e.target.value),
      type: "email",
      name: "email",
      error: errors.email,
      disabled: disabled,
    },
    day: {
      placeholder: "DD",
      value: day,
      onChange: (e) => setDay(handleChangeDay(e)),
      type: "number",
      name: "day",
      error: errors.day,
      disabled: disabled,
    },
    month: {
      placeholder: "MM",
      value: month,
      onChange: (e) => setMonth(handleChangeMonth(e)),
      type: "number",
      name: "month",
      error: errors.month,
      disabled: disabled,
    },
    year: {
      placeholder: "YYYY",
      value: year,
      onChange: (e) => setYear(handleChangeYear(e)),
      type: "number",
      name: "year",
      error: errors.year,
      disabled: disabled,
    },
  };

  if (currentUser.mustSetPassword()) {
    Object.assign(bodyProfileFields, {
      passwordCreate: {
        label: "Genera una contaseña",
        onChange: (e) => setPasswordCreate(e.target.value),
        value: passwordCreate,
        name: "password_create",
        type: "password",
        fullWidth: true,
        error: errors.password_create,
        disabled: fieldsDisabled,
      },
      passwordCreateConfirm: {
        label: "Confirma tu contraseña",
        onChange: (e) => setPasswordCreateConfirm(e.target.value),
        value: passwordCreateConfirm,
        name: "password_create_confirm",
        type: "password",
        fullWidth: true,
        error: errors.password_create_confirm,
        disabled: fieldsDisabled,
      },
    });
  } else {
    Object.assign(bodyProfileFields, {
      password: {
        label: "Introduce tu contraseña para cambiar tus datos",
        onChange: (e) => setPassword(e.target.value),
        value: password,
        name: "password",
        type: "password",
        fullWidth: true,
        error: errors.password,
        disabled: fieldsDisabled,
      },
    });
  }

  const handlePasswordCreate = (e) => {
    setButtonDisabled(true);
    setFieldsDisabled(true);
    beforeSubmit();
    AuthService.socialAccountPasswordCreate({
      password_create: passwordCreate,
      password_create_confirm: passwordCreateConfirm,
    })
      .then(() => {
        afterSubmit();
        openModal({
          title: "Contraseña generada",
          message: "Su contraseña ha sido generada exitosamente",
          handleClose: () => {
            closeModal(modal);
            setButtonDisabled(false);
            setFieldsDisabled(false);
            history.go(0);
          },
        });
      })
      .catch((e) => {
        afterSubmit();
        setButtonDisabled(false);
        setFieldsDisabled(false);
        dealWithError(e, "Error");
      });
  };

  const bodyProfile = {
    name: tabProfile,
    cardForm: {
      // title: "Mis datos",
      fields: bodyProfileFields,
      updateButton: {
        legend: "Actualizar",
        handleClick: handleSubmitProfile,
        disabled: disabled,
      },
      passwordCreateButton: {
        legend: "Generar contraseña",
        handleClick: handlePasswordCreate,
        disabled: buttonDisabled,
      },
      errors,
    },
  };

  const callback = useCallback((msg) => {
    if (typeof msg === "object" && Object.keys(msg).includes("detail")) {
      openModal({
        title: "Error",
        message: msg["detail"],
      });
    } else {
      openModal({
        title: "Error",
        message: message,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bodySusbscription = {
    name: tabSubscription,
    cards: plans
      .filter((plan) => !plan.is_free)
      .map((plan) => {
        const subscriptionPlan = subscription?.plan;
        let label = "COMPRAR PLAN";

        let handleClick = () =>
          openModal({
            title: "Confirmar solicitud",
            message:
              "Haz click en aceptar para confirmar la solicitud de tu plan.",
            handleAccept: (e) =>
              PlanService.requestSubscription({ plan_id: plan.id })
                .then((data) =>
                  openModal({
                    title: "Solicitud exitosa",
                    message: "Tu plan se encuentra pendiente de activación",
                    handleClose: (e) => {
                      closeModal(modal);
                      history.push({
                        pathname: "/profile",
                        search: "?tab=subscription",
                      });
                      history.go(0);
                    },
                  })
                )
                .catch((error) => {
                  dealWithError(error, "Error", callback);
                }),
            handleCancel: () => closeModal(modal),
          });

        let disable = false;
        let active = false;
        let className = "inverted-outlined";

        if (
          subscriptionPlan.id === plan.id &&
          subscription?.status === "PEND"
        ) {
          label = "PENDIENTE DE ACTIVACIÓN";
          handleClick = () => {};
          disable = true;
          className = "inverted-contained";
        } else if (
          subscriptionPlan.id === plan.id &&
          subscription.status === "ACT" &&
          subscription.is_active
        ) {
          label = "SUBSCRIPCIÓN ACTUAL";
          handleClick = () => {};
          disable = true;
          className = "inverted-contained";
          active = true;
        }

        return {
          days: plan.subscription_days,
          label: label,
          description: plan.detail,
          onClick: handleClick,
          disabled: disable,
          buttonClassName: className,
          active,
          name: plan.name,
        };
      }),
  };

  const handleSubmitChangePassword = (e) => {
    e.preventDefault();
    setErrors({});
    setDisabled(true);
    const data = {
      actual_password: actualPassword,
      new_password: newPassword,
      new_password_confirm: newPasswordConfirm,
    };

    if (validateEmptyFieldsChangePassword()) {
      updatePassword(data);
    } else {
      setDisabled(false);
      setErrors({ non_field_errors: "Por favor complete todos los campos." });
    }
  };
  const updatePassword = (data) => {
    beforeSubmit();

    AuthService.updateUserPassword(data)
      .then((resp) => {
        afterSubmit();
        // localStorage.setItem("access", data.access);
        // localStorage.setItem("refresh", data.refresh);
        openModal({
          title: "Operación exitosa",
          message: "Tus contraseña fue actualizada exitosamente",
          handleClose: (e) => history.go(0),
        });
      })
      .catch((error) => {
        afterSubmit();
        setDisabled(false);
        dealWithError(error, "Error");
      });
  };

  const bodyChangePasswordFields = [
    {
      label: "Contraseña actual",
      value: actualPassword,
      onChange: (e) => setActualPassword(e.target.value),
      type: "password",
      name: "actual_password",
      placeholder: "********",
      error: errors.actual_password,
      disabled: disabled,
    },
    {
      label: "Contraseña nueva",
      value: newPassword,
      onChange: (e) => setNewPassword(e.target.value),
      type: "password",
      name: "new_password",
      placeholder: "********",
      error: errors.new_password,
      disabled: disabled,
    },
    {
      label: "Confirmar contraseña nueva",
      value: newPasswordConfirm,
      onChange: (e) => setNewPasswordConfirm(e.target.value),
      type: "password",
      name: "new_passsword_confirm",
      placeholder: "********",
      error: errors.new_password_confirm,
      disabled: disabled,
    },
  ];

  const bodyChangePassword = {
    name: tabChangePassword,
    cardForm: {
      title: "Cambiar contraseña",
      fields: bodyChangePasswordFields,
      changePasswordButton: {
        legend: "Cambiar contraseña",
        handleClick: handleSubmitChangePassword,
        disabled: disabled,
      },
      errors,
    },
  };

  return (
    <Profile
      bodyProfile={bodyProfile}
      buttonProfile={buttonProfile}
      bodySubscription={bodySusbscription}
      buttonSubscription={buttonSubscription}
      bodyChangePassword={bodyChangePassword}
      buttonChangePassword={buttonChangePassword}
      cardActive={cardActive}
      subscription={subscription}
      activeSubscription={activeSubscription}
      modal={modal}
      handleResendVerificationMail={handleResendVerificationMail}
    />
  );
};

export default withTranslation()(ProfileView);
