import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import commonEs from "translations/es/common.json";

const resources = {
    es: {
        translation: commonEs,
    },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "es",
        interpolation: { escapeValue: false },
        keySeparator: false, // With false use the json plain (no tree)
        react: { wait: true },
    });

export default i18n;

